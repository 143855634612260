import { gql } from "@apollo/client";

export const GET_USERS_PAGE_QUERY = gql`
  query userPageQuery {
    adminRoleCount: usersCount(where: { role: { name: { equals: "admin" } } })
    usersRoleCount: usersCount(where: { role: { name: { equals: "user" } } })
    rolesCount: rolesCount
    # users(where: { role: { name: { equals: "user" } } }) {
    users {
      id,
      name,
      createdAt,
      email,
      role {
        id,
        name
      }
    }
    usersCount
  }
`;

export const GET_USER_QUERY = gql`
query User($where: UserWhereUniqueInput!) {
  user(where: $where) {
      id
      name
      email
      role {
        id
        name
        canSeeOtherPeople
        canEditOtherPeople
        canManagePeople
        canManageEvents
        canManageMagazines
        canManageBusinessDirectory
        canManageRoles
        canUseAdminUI
        canManagePosts
        assignedTo {
          id
          name
        }
        assignedToCount
      }
    }
  }
`;

// Example of a query to check if a user with a specific email exists
export const CHECK_EXISTING_USER_QUERY = gql`
  query CheckExistingUser($email: String!) {
    user(where: { email: { equals: $email } }) {
      id
      name
      email
      # Other necessary fields
    }
  }
`;

const checkExistingUser = async (apolloClient, email) => {
  try {
    const existingUser = await apolloClient.query({
      query: CHECK_EXISTING_USER_QUERY,
      variables: { email },
    });

    return existingUser.data.userByEmail;
  } catch (error) {
    console.error('Error checking existing user:', error);
    return null;
  }
};

export default checkExistingUser;

// export const GET_USER_QUERY = gql`
//   query GetUser($userId: ID!) {
//     user(id: $userId) {
//       id
//       name
//       email
//     }
//   }
// `;