// src/components/Home.js
import React from 'react';
import '../../styles/Dashboard.css'
import '../../styles/Navbar.css'
import Navbar from '../Navbar';
import StatisticsCard from '../StatisticsCard';
// import { dashboard, recentUserSignup, mostReadMagazine } from '../../data/data';
// import Card from '../Card';
import { useQuery } from "@apollo/client";
import { GET_USERS_PAGE_QUERY } from "../../graphql/queries/users.query";
import { GET_TOTAL_COUNT_QUERY_WITHOUT_PAGINATION } from "../../graphql/queries/events.query";
import { GET_NEWS_PAGE_QUERY } from "../../graphql/queries/posts.query";
import { GET_MAGAZINES_QUERY } from "../../graphql/queries/magazine.query";
import { GET_BUSINESSES_COUNT } from '../../graphql/queries/businessDirectory.query';
// import Loader from '../Loader';

const styles = {
  container: {
    margin: '0px 50px 50px 50px',
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    marginBottom: 20,
  },
  titleContainer: {
    display: 'flex',
    gap: 5
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap'
  }
};

const Dashboard = () => {
  const { data: usersData, loading: userLoading } = useQuery( GET_USERS_PAGE_QUERY, {
    variables: { take: 10, skip: 0 }
  });
  const { data: eventsData, loading: eventsLoading } = useQuery( GET_TOTAL_COUNT_QUERY_WITHOUT_PAGINATION, {
    variables: { take: 10, skip: 0 }
  });
  const { data: businessDirectoryData, loading: businessDirectoryLoading } = useQuery( GET_BUSINESSES_COUNT, {
    variables: { take: 10, skip: 0 }
  });
  const { data: magazineData, loading: magazineLoading } = useQuery( GET_MAGAZINES_QUERY, {
    variables: { take: 10, skip: 0 }
  });
  const { loading: issuesLoading } = useQuery( GET_MAGAZINES_QUERY, {
    variables: { take: 10, skip: 0 }
  })
  const { data: postData, loading: postsLoading } = useQuery( GET_NEWS_PAGE_QUERY, {
    variables: { take: 10, skip: 0 }
  });

  // const userCount = usersData?.usersCount || 0;
  // const eventsCount = eventsData?.eventsCount || 0;
  // const businessdirectoryCount = businessDirectoryData?.businessesCount || 0;
  // const magazineCount = magazineData?.magazinesCount || 0;
  // const issuesCount = magazineData?.issuesCount || 0;
  // const newsCount = postData?.newsCount || 0;
  
  // const newsCount = newsData?.newsCount || 0;

  return (
    <>
    <Navbar/>
      <div style={styles.container}>
        <StatisticsCard 
          data={[
            { title: "Users", value: usersData?.usersCount, loading: userLoading },
            // { title: "Business Directories", value: businessDirectoryData?.businessesCount, loading: businessDirectoryLoading },
            { title: "Events", value: eventsData?.eventsCount, loading: eventsLoading },
            { title: "Magazine", value: magazineData?.magazinesCount, loading: magazineLoading },
            { title: "Issues", value: magazineData?.issuesCount, loading: issuesLoading },
            { title: "bizViews", value: postData?.postsCount, loading: postsLoading },
          ]}
        />
      
        <div style={{display: "flex", padding: '0px 20px'}}>
          <div style={{backgroundColor: "#F9F9F9", width: "100%", justifyContent: "center", borderRadius: 20, margin: 5, border: '1px solid #F2F2F2', padding: 20}}>
            <div style={{backgroundColor: "#FFFFFF", borderRadius: 20, padding: '0px 20px', marginBottomx: 20, display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <div><span style={{fontSize: 30, fontWeight: 600}}>Binge it with the App!</span></div>
                <div><span style={{color: "#7A7A7A"}}>Stay up to date on our events, magazines and bizViews with our easy-to-use app.</span></div>
                <br/>
                <div><span style={{color: "#151515", fontWeight: 600}}>Download the app for free</span></div>
              </div>
              <div>
                <img src='https://i.ibb.co/W3W12Kw/ddark.png' alt='mobile-app' style={{ width: '400px' }} />
              </div>
            </div>
          </div>
        </div>
      {/* <div style={{display: "flex", padding: '0px 20px'}}>
        <div style={{backgroundColor: "#F9F9F9", width: "100%", justifyContent: "center", borderRadius: 20, margin: 5, border: '1px solid #F2F2F2', padding: 20}}>
          <div style={{backgroundColor: "#FFFFFF", borderRadius: 20, padding: 20, marginBottom: 20}}>
            <div><span style={{fontSize: 40, fontWeight: 600}}>£12,345.90</span></div>
            <div><span style={{color: "#7A7A7A"}}>17th October, 23:39</span></div>
          </div>
          <div style={{backgroundColor: "#FFFFFF", border: '1px solid #F1F0F0', borderRadius: 20, padding: 20}}>
            <div style={styles.header}>
              <div>
                <span style={{ fontWeight: 600 }}>Connexions</span>
              </div>
              <div>
                <span style={{ color: "#259BD8", fontWeight: 600 }}>See all</span>
              </div>
            </div>
            
            <div><hr className='foo'/></div>
            
            <div>
              <Card/>
            </div>
          </div>
        </div>
        <div style={{backgroundColor: "#F9F9F9", width: "100%", justifyContentt: "center", borderRadius: 20, margin: 5, border: '1px solid #F2F2F2', padding: 20}}>
          <div style={{marginBottom: 10}}>
            <span style={{ fontWeight: 600 }}>Recent Activity Feed</span>
          </div>
          <div style={{backgroundColor: "#FFFFFF", border: '1px solid #F1F0F0', borderRadius: 20, padding: 20, marginBottom: 15}}>
            <div style={styles.header}>
              <div>
                <span style={{ fontWeight: 600 }}>Recent user sign-ups</span>
              </div>
              <div>
                <span style={{ color: "#259BD8", fontWeight: 600 }}>See all</span>
              </div>
            </div>
            
            <div><hr className='foo'/></div>
            
            <div>
              <div style={{ display: 'flex', paddingx: '16px 0px' }}>
                <div className='user-container' stylex={styles.card}>
                  {recentUserSignup.map((rus) => (
                    <div className='user-item' key={rus.id} stylex={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <img className='user-image' src={rus.imageSrc} alt="Placeholder" style={{ width: '50px', marginRight: '10px' }} />
                      <span className='user-text'>{rus.userName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div style={{backgroundColor: "#FFFFFF", border: '1px solid #F1F0F0', borderRadius: 20, padding: 20}}>
            <div style={styles.header}>
              <div>
                <span style={{ fontWeight: 600 }}>Most read magazine</span>
              </div>
              <div>
                <span style={{ color: "#259BD8", fontWeight: 600 }}>See all</span>
              </div>
            </div>
            
            <div><hr className='foo'/></div>
            
            <div>
              <div style={{ display: 'flex', paddingx: '16px 0px' }}>
                <div className='user-container' stylex={styles.card}>
                  {mostReadMagazine.map((mrm) => (
                    <div className='magazine-item' key={mrm.id} stylex={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <div className='magazine-group'>
                        <img className='user-image' src={mrm.imageSrc} alt="Placeholder" style={{ width: '50px', marginRight: '10px' }} />
                        <span className='magazine-name'>{mrm.magazineName}</span>
                      </div>
                      <div><span className='magazine-text'>{mrm.readCount}</span></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <p style={{fontSize: 13, fontWeight: 500, textAlign: 'center'}}>biz4Biz Control Panel © 2023 - 2024. <span style={{fontSize: 10}}>version 25(0.0.1)</span></p>
      </div>
    </div>
    </>
  );
};

export default Dashboard;