import React from 'react';
import '../../styles/Modal.css';
import { close } from '../../data/svg';
import { createPortal } from 'react-dom';

const Modal = ({ isOpen, onClose, header, footer, children }) => {
  if (!isOpen) return null;

  const modalRoot = document.getElementById('modal');
  return createPortal(
    <div className="modal-overlay">
        <div className='modal'>
            <div className="modal-header">
                <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                    {header}
                </span>
                <span className="modal-close" onClick={onClose}>
                    {close({ width: 10, height: 10, color: '#28303F' })}
                </span>
            </div>
            <div className='modal-body'>
                {children}
            </div>
            <div className='modal-footer'>
                {footer}
            </div>
        </div>
    </div>,
    modalRoot
  );
};

export default Modal;