import React from 'react';
import '../../../styles/Loader.css';

const Button = ({ showCaption = true, containerPadding }) => {
  const loaderContainerStyle = {
    padding: containerPadding !== undefined ? containerPadding : '0px',
  };

  return (
    <div className="loader-container" stylee={loaderContainerStyle}>
      <div className="loader-button"></div>
      {showCaption && (
        <div style={{ padding: 5 }}>
          <span style={{ fontSize: 14 }}>Loading, Please wait ...</span>
        </div>
      )}
    </div>
  );
};

export default Button;