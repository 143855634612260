import { gql } from "@apollo/client";

export const FETCH_ISSUE_PAGES = gql`
  query issuePages($issueId: ID!) {
    issuePages(
      where: { issue: { id: { equals: $issueId } } }
      orderBy: { page_no: asc }
    ) {
      id
      page_no
      image {
        url
      }
    }
  }
`;
