import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { actions } from "../../data/data";
import { GET_NEWS_PAGE_QUERY } from "../../graphql/queries/posts.query";
import { GET_AUTHORS_QUERY } from "../../graphql/queries/author.query";
import "../../styles/Navbar.css";
import Actions from "../Actions";
import Button from "../Button";
import Navbar from "../Navbar";
import ResponsiveTable from "../ResponsiveTable";
import DataStatsCard from "../StatisticsCard";
// import TextInput from "../TextInput";

import AddNews from "./AddNews";
import AddAuthor from "./Author/AddAuthor";

import ViewNews from "./ViewNews";
import ViewAuthor from "./Author/ViewAuthor";

import UpdateNews from './UpdateNews';
import UpdateAuthor from './Author/UpdateAuthor';

import TrashNews from './TrashNews';
import TrashAuthor from './Author/TrashAuthor';

import { collapseIcon, expandIcon } from "../../data/svg"

const styles = {
  container: {
    textAlign: "center",
    margin: "0px 50px 50px 50px",
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%",
  },
  connexionsHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 20,
    marginBottom: 20,
    alignItems: "center",
  },
};

const News = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; // this is defined as a constant

  const [currentAuthorPage, setCurrentAuthorPage] = useState(0);
  const pageAuthorSize = 10; // this is defined as a constant

  const { data: postData, loading: postLoading, refetch: refetchNews } = useQuery(GET_NEWS_PAGE_QUERY, {
    variables: {
      take: pageSize, // Number of items to fetch per page
      skip: currentPage * pageSize, // Number of items to skip
      orderBy: [{ publishedAt: "desc" }], // Your ordering preference
    },
  });
  const { data: authorData, loading: authorLoading, refetch: refetchAuthors } = useQuery(GET_AUTHORS_QUERY, {
    variables: {
      take: pageAuthorSize, // Number of items to fetch per page
      skip: currentAuthorPage * pageAuthorSize, // Number of items to skip
      orderBy: [{ name: "asc" }], // Your ordering preference
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // start of author modal logic
  const handleAuthorModalLogic = ({type, authorData}) => {
    console.log('this is author', authorData)
    console.log(type)
    switch (type) {
    
      case "view":
        setSelectedRowData(authorData);
        setShowViewAuthor(true);
        break;

        case "update":
          setSelectedRowData(authorData);
          setShowUpdateAuthor(true);
        break;

        case "trash":
          setSelectedRowData(authorData);
          setShowTrashAuthor(true);
        break;
    
      default:
        return null
        break;
    }
  }

  // start of news modal logic
  const handleNewsModalLogic = ({type, postData}) => {
    console.log(postData)
    console.log(type)
    switch (type) {
   
      case "view":
        setSelectedRowData(postData);
        setShowViewNews(true);
        break;

        case "update":
          setSelectedRowData(postData);
          setShowUpdateNews(true);
        break;

        case "trash":
          setSelectedRowData(postData);
          setShowTrashNews(true);
        break;
    
      default:
        return null
        break;
    }
  }

  // start author column
  const authorColumns = useMemo(
    () => [
      {
        Header: "ID",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Author Name",
        accessorKey: "name",
      },
      // {
      //   Header: "Author",
      //   accessorKey: "author",
      //   // render: ({ value }) => value?.name,
      //   render: ({ value }) => {
      //     if (value && value.image.url) {
      //       return value.image.url;
      //     } else {
      //       return "No Author";
      //     }
      //   },
      // },
      {
        Header: "Author Image",
        // accessorKey: "author",
        render: ({ value }) => {
          if (value && value.image && value.image.url) {
            return <img src={value.image.url} alt="Author" style={{ width: 50, height: 50, borderRadius: '50%' }} />;
          } else {
            return "No Author Image";
          }
        },
      },
      
      {
        Header: "Action",
        // render: ({ row, value }) => <Actions actions={actions} />,
        render: ({ row, value }) => {
          return <Actions actions={actions} openModal={handleAuthorModalLogic} rowData={value} />
        },
      },
    ],
    []
  );

  // start news column
  const newsColumns = useMemo(
    () => [
      {
        Header: "ID",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "bizViews Title",
        accessorKey: "title",
      },
      {
        Header: "bizViews Image",
        // accessorKey: "coverImage",
        render: ({ value }) => {
          if (value && value.coverImage && value.coverImage.url) {
            return <img src={value.coverImage.url} alt="bizViews Image" style={{ width: 50, height: 50, borderRadius: '50%', objectFit: 'cover' }} />;
          } else {
            return "No Image";
          }
        },
      },
      {
        Header: "Author",
        accessorKey: "author",
        // render: ({ value }) => value?.name,
        render: ({ value }) => {
          if (value && value.author && value.author.name) {
            return value.author.name;
          } else {
            return "No Author";
          }
        },
      },

      {
        Header: "Date Published",
        // accessorKey: "createdAt",
        render: ({ value }) => {
          if (value && value?.publishedAt) {
            const publishedDate = new Date(value?.publishedAt);
            if (!isNaN(publishedDate?.getTime())) {
              // Valid date, format it
              const formattedDate = publishedDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });
              return formattedDate;
            }
          }
          return "No Date";
        },
      },

      {
        Header: "Action",
        // render: ({ row, value }) => <Actions actions={actions} />,
        render: ({ row, value }) => {
          return <Actions actions={actions} openModal={handleNewsModalLogic} rowData={value} />
        },
      },
    ],
    []
  );

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const [showModal, setShowModal] = useState(false);
  const [showAddAuthor, setShowAddAuthor] = useState(false);

  const [showViewNews, setShowViewNews] = useState(false);
  const [showViewAuthor, setShowViewAuthor] = useState(false);
  
  const [showUpdateNews, setShowUpdateNews] = useState(false);
  const [showUpdateAuthor, setShowUpdateAuthor] = useState(false);

  const [showTrashNews, setShowTrashNews] = useState(false);
  const [showTrashAuthor, setShowTrashAuthor] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    setShowAddAuthor(false);
  };

  const [showAuthorTableData, setShowAuthorTableData] = useState(true);
  const [showNewsTableData, setShowNewsTableData] = useState(true);

  const toggleAuthorTableData = () => {
    setShowAuthorTableData(!showAuthorTableData);
  };

  const toggleNewsTableData = () => {
    setShowNewsTableData(!showNewsTableData);
  };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <DataStatsCard
          data={[
            { title: "bizViews", value: postData?.postsCount, loading: postLoading },
            { title: "Authors", value: postData?.authorsCount, loading: authorLoading },
          ]}
        />

        {/* start of author section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Authors</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add Author"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      onClick={() => setShowAddAuthor(true)}
                    />
                  </div>
                  {showAddAuthor &&
                    createPortal(
                      <AddAuthor onClose={closeModal} />,
                      document.body
                  )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={showAuthorTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleAuthorTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
                {showAuthorTableData && (
                  <ResponsiveTable
                    isLoading={authorLoading}
                    data={authorData?.authors || []}
                    columns={authorColumns}
                    currentPage={currentAuthorPage}
                    pageSize={pageAuthorSize}
                    onPageChange={handlePageChange}
                    totalItems={authorData?.authorsCount}
                    totalPages={authorData?.totalPages}
                  />
                )}
                {showViewAuthor && (
                  <ViewAuthor
                    onClose={() => setShowViewAuthor(false)}
                    authorData={selectedRowData}
                    authorId={selectedRowData?.id}
                  />
                )}
                {showUpdateAuthor && (
                  <UpdateAuthor
                    onClose={() => setShowUpdateAuthor(false)}
                    authorData={selectedRowData}
                    authorId={selectedRowData?.id}
                    // magazineId={selectedRowData?.id}
                    refetchAuthors={refetchAuthors}
                  />
                )}
                {showTrashAuthor && (
                  <TrashAuthor
                    onClose={() => setShowTrashAuthor(false)}
                    authorData={selectedRowData}
                    authorId={selectedRowData?.id}
                    authorName={selectedRowData?.name}
                    refetchAuthors={refetchAuthors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of author section */}

        {/* start of news section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>bizViews</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  {/* <TextInput
                    type="text"
                    placeholder="Filter by Author"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add bizViews"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      onClick={() => setShowModal(true)}
                      isFirstButton={true}
                      isLastButton={true}
                    />
                  </div>
                  {showModal &&
                    createPortal(
                      <AddNews onClose={closeModal} />,
                      document.body
                  )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={showNewsTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleNewsTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
              {showNewsTableData && (
                <ResponsiveTable
                  isLoading={postLoading}
                  data={postData?.news || []}
                  columns={newsColumns}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={handlePageChange}
                  totalItems={postData?.postsCount}
                  totalPages={postData?.totalPages}
                />
              )}
                {showViewNews && <ViewNews onClose={() => setShowViewNews(false)} postData={selectedRowData} postId={selectedRowData?.id} authorId={selectedRowData?.id} />}
                {showUpdateNews && <UpdateNews onClose={() => setShowUpdateNews(false)} postData={selectedRowData} postId={selectedRowData?.id} authorId={selectedRowData?.id} refetchNews={refetchNews} />}
                {showTrashNews && <TrashNews onClose={() => setShowTrashNews(false)} postData={selectedRowData} newsId={selectedRowData?.id} newsTitle={selectedRowData?.title} refetchNews={refetchNews} />}
              </div>
            </div>
          </div>
        </div>
        {/* end of news section */}

        <div>
          <p style={{fontSize: 13, fontWeight: 500, textAlign: 'center'}}>biz4Biz Control Panel © 2023 - 2024. <span style={{fontSize: 10}}>version 25(0.0.1)</span></p>
        </div>
      </div>
    </>
  );
};

export default News;