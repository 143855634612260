import React from 'react';
import TextInput from '../../../TextInput';
import Button from '../../../Button';

const PersonalDetails = () => {
  return (
    <div>
      <div className="modal-header">
        <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
          Personal Details
        </span>
      </div>
      <div className='modal-body'>
        <div style={{width: '100%', display: 'flex', gap: 10}}>
          <TextInput
            type="text"
            placeholder="Name"
            value=""
            onChange={() => {}}
            style={{width: '100%'}}
          />
          <TextInput
            type="text"
            placeholder="Email"
            value=""
            onChange={() => {}}
            style={{width: '100%'}}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <div style={{width: '50%'}}>
          <Button
            buttonText="Update"
            backgroundColor="#259BD8"
            borderColor="259BD8"
            textColor="#ffffff"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
