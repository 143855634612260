import React, { useRef } from "react";
import Button from "../Button"; // Import your Button component here

const ImageUploader = ({
  buttonText,
  backgroundColor,
  borderColor,
  textColor,
  file = null,
  onUpload,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Handle the selected file (e.g., perform operations, display preview, etc.)
    onUpload(file);
  };

  const handleButtonClick = () => {
    // Trigger the file input click when the button is clicked
    fileInputRef.current.click();
  };

  return (
    <div style={{ display: "flex", width: "100%", gap: 10 }}>
      {file && (
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            gap: 5,
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt="Uploaded"
            style={{ width: 26, height: 26, borderRadius: "50%", objectFit: 'cover' }}
          />
          <span style={{ fontSize: 8, fontWeight: 600 }}>
            {" "}
            {file.name} • {Math.round(file.size / 1024)} KB
          </span>
        </div>
      )}
      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }}
        />

        <label htmlFor="fileInput">
          <Button
            buttonText={buttonText}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            textColor={textColor}
            onClick={handleButtonClick}
          />
        </label>
      </div>
    </div>
  );
};

export default ImageUploader;
