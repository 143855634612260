import { view, update, trash, profile, password, notifications, roles } from './svg';

const dashboard = [
  { title: 'Users', value: '86,8m' },
  { title: 'Business Directories', value: '16,502' },
  { title: 'Connexions', value: '3' },
  { title: 'Webexions', value: '16,502' },
  { title: 'Awards', value: '502' },
  { title: 'Magazines', value: '62' },
  { title: 'News', value: '36' },
];

const events = [
  { title: 'Free', value: '16' },
  { title: 'Paid', value: '16' },
  { title: 'Category', value: '3' },
  { title: 'Webexions', value: '16,502' },
  { title: 'Connexions', value: '502' },
  { title: 'Attendees', value: '62' },
];

const eventsTable = [
  {
    id: '1', 
    eventTitle: 'Workshop on successio...', 
    eventDetails: 'We are delighted to ...', 
    eventCategory: 'Webexions', 
    eventType: 'Free',
    eventDate: '23-09-22'
  },
  {
    id: '2', 
    eventTitle: 'Workshop on successio...', 
    eventDetails: 'We are delighted to ...', 
    eventCategory: 'Connexions', 
    eventType: 'Paid',
    eventDate: '23-09-22'
  },
  {
    id: '3', 
    eventTitle: 'Workshop on successio...', 
    eventDetails: 'We are delighted to ...', 
    eventCategory: 'Webexions', 
    eventType: 'Paid',
    eventDate: '23-09-22'
  },
  {
    id: '4', 
    eventTitle: 'Workshop on successio...', 
    eventDetails: 'We are delighted to ...', 
    eventCategory: 'Connexions', 
    eventType: 'Free',
    eventDate: '23-09-22'
  },
  {
    id: '5', 
    eventTitle: 'Workshop on successio...', 
    eventDetails: 'We are delighted to ...', 
    eventCategory: 'Webexions', 
    eventType: 'Free',
    eventDate: '23-09-22'
  },
];

const openModal = ({modalContent, modalType, data}) => {
  // Logic to open modal with specified content
  // console.log(`Opening modal with content: ${modalContent}`);
  console.log(`Opening ${modalType} with data:`, data);
  document.body.style.overflow = 'hidden';
  
};

const actions = [
  {
    icon: view({ width: 14, height: 12 }),
    // onClick: () => openModal('View Modal'),
    title:"view",
    // onClick: (rowData) => openModal({modalType:'View Modal', data:rowData}),
  },
  {
    icon: update({ width: 10, height: 12 }),
    // onClick: () => openModal('View Modal'),
    onClick: (rowData) => openModal({modalType:'Update Modal', data:rowData}),
    title:"update",
  },
  {
    icon: trash({ width: 12, height: 14 }),
    onClick: () => openModal('View Modal'),
    title:"trash",
  },
];



const awards = [
  { title: 'Users', value: '26,8m' },
  { title: 'Business Directories', value: '16,502' },
  { title: 'Connexions', value: '3' },
  { title: 'Webexions', value: '16,502' },
  { title: 'Awards', value: '502' },
  { title: 'Magazines', value: '62' },
  { title: 'News', value: '36' },
];

const users = [
  { title: 'Users', value: '26,8m' },
  { title: 'Admin', value: '16,502' },
];

const usersTable = [
  {
    id: '1', 
    name: 'Ayodeji Moshood',
    email: 'ayo.i.moshood@gmail.com',
    dateJoined: '23-09-22',
  },
  {
    id: '2', 
    name: 'Ayodeji Moshood',
    email: 'ayo.i.moshood@gmail.com',
    dateJoined: '23-09-22',
  },
  {
    id: '3', 
    name: 'Ayodeji Moshood',
    email: 'ayo.i.moshood@gmail.com',
    dateJoined: '23-09-22',
  },
  {
    id: '4', 
    name: 'Ayodeji Moshood',
    email: 'ayo.i.moshood@gmail.com',
    dateJoined: '23-09-22',
  },
  {
    id: '5', 
    name: 'Ayodeji Moshood',
    email: 'ayo.i.moshood@gmail.com',
    dateJoined: '23-09-22',
  },
];

const magazine = [
  { title: 'Insights Magazine', value: '16' },
  { title: 'Sustainable biz', value: '16' },
  { title: 'Manufacturing biz', value: '16' },
  { title: 'Awards Magazine', value: '16' },
];

const magazineTable = [
  {
    id: '1', 
    magazineName: 'Workshop on successio...',
    magazineType: 'Insights Magazine',
    issueNo: 'We are delighted to ...', 
    datePublished: '23-09-22',
    pages: '20',
  },
  {
    id: '2', 
    magazineName: 'Workshop on successio...',
    magazineType: 'Sustainable biz',
    issueNo: 'We are delighted to ...', 
    datePublished: '23-09-22',
    pages: '20',
  },
  {
    id: '3', 
    magazineName: 'Workshop on successio...',
    magazineType: 'Manufacturing biz',
    issueNo: 'We are delighted to ...', 
    datePublished: '23-09-22',
    pages: '20',
  },
  {
    id: '4', 
    magazineName: 'Workshop on successio...',
    magazineType: 'Awards Magazine',
    issueNo: 'We are delighted to ...', 
    datePublished: '23-09-22',
    pages: '20',
  },
  {
    id: '5', 
    magazineName: 'Workshop on successio...',
    magazineType: 'Insights Magazine',
    issueNo: 'We are delighted to ...', 
    datePublished: '23-09-22',
    pages: '20',
  },
];

const news = [
  { title: 'News', value: '26' },
  { title: 'Authors', value: '26' }
];

const newsTable = [
  {
    id: '1', 
    newsTitle: 'Workshop on successio...',
    author: 'Insights Magazine',
    datePublished: '23-09-22',
  },
  {
    id: '2', 
    newsTitle: 'Workshop on successio...',
    author: 'Insights Magazine',
    datePublished: '23-09-22',
  },
  {
    id: '3', 
    newsTitle: 'Workshop on successio...',
    author: 'Insights Magazine',
    datePublished: '23-09-22',
  },
  {
    id: '4', 
    newsTitle: 'Workshop on successio...',
    author: 'Insights Magazine',
    datePublished: '23-09-22',
  },
  {
    id: '5', 
    newsTitle: 'Workshop on successio...',
    author: 'Insights Magazine',
    datePublished: '23-09-22',
  },
];

const accountSidebarLinks = [
  { id: 1, label: 'Profile', icon: profile({ width: 14, height: 12 }), },
  { id: 2, label: 'Password', icon: password({ width: 14, height: 12 }), },
  // { id: 3, label: 'Notifications', icon: notifications({ width: 14, height: 12 }) },
  // { id: 4, label: 'Roles', icon: roles({ width: 14, height: 12 }) }
];

const recentUserSignup = [
  {
    id: 1,
    imageSrc: "https://via.placeholder.com/50",
    userName: "Alice Smith",
  },
  {
    id: 2,
    imageSrc: "https://via.placeholder.com/50",
    userName: "Bob Johnson",
  },
  {
    id: 3,
    imageSrc: "https://via.placeholder.com/50",
    userName: "Charlie Williams",
  },
  {
    id: 4,
    imageSrc: "https://via.placeholder.com/50",
    userName: "David Jones",
  },
  {
    id: 5,
    imageSrc: "https://via.placeholder.com/50",
    userName: "Eva Brown",
  },
  {
    id: 6,
    userName: "Frank Davis",
    imageSrc: "https://via.placeholder.com/50",
  },
  {
    id: 7,
    userName: "Grace Miller",
    imageSrc: "https://via.placeholder.com/50",
  },
  {
    id: 8,
    userName: "Hannah Wilson",
    imageSrc: "https://via.placeholder.com/50",
  },
  {
    id: 9,
    userName: "Isaac Moore",
    imageSrc: "https://via.placeholder.com/50",
  },
];

const mostReadMagazine = [
  {
    id: 1,
    imageSrc: "https://via.placeholder.com/50",
    magazineName: "Awards Magazine",
    readCount: '3,498,855'
  },
  {
    id: 2,
    imageSrc: "https://via.placeholder.com/50",
    magazineName: "Insight Magazine",
    readCount: '3,498,855'
  },
  {
    id: 3,
    imageSrc: "https://via.placeholder.com/50",
    magazineName: "Sustainable Biz",
    readCount: '3,498,855'
  },
  {
    id: 4,
    imageSrc: "https://via.placeholder.com/50",
    magazineName: "Manufacturing Biz",
    readCount: '3,498,855'
  },
  {
    id: 5,
    imageSrc: "https://via.placeholder.com/50",
    magazineName: "Insight Magazine",
    readCount: '3,498,855'
  },
  {
    id: 6,
    magazineName: "Awards Magazine",
    imageSrc: "https://via.placeholder.com/50",
    readCount: '3,498,855'
  }
];

export { dashboard, events, eventsTable, actions, awards, users, usersTable, magazine, magazineTable, news, newsTable, accountSidebarLinks, recentUserSignup, mostReadMagazine };