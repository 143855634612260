import React, {useState} from 'react';
import Button from '../Button';
import '../../styles/Modal.css';
import { close, userHeaderIcon, showPasswordIcon, hidePasswordIcon } from '../../data/svg';
import TextInput from '../TextInput';
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { CREATE_USER_MUTATION } from '../../graphql/mutations/user.mutation';
import {GET_ROLES_QUERY} from '../../graphql/queries/roles.query';
import { GET_USERS_PAGE_QUERY, CHECK_EXISTING_USER_QUERY  } from '../../graphql/queries/users.query';
import Select from 'react-select';
import LoadingButton from '../Button/LoadingButton';
import { toast } from 'react-hot-toast';

const AddUser = ({ onClose }) => {
  const { refetch: refetchUser } = useQuery(GET_USERS_PAGE_QUERY, {
    variables: { take: 10, skip: 0, orderBy: [{ createdAt: 'asc' }] }
  });
  const [createUser, {loading}] = useMutation(CREATE_USER_MUTATION);
  const { data: roleListData } = useQuery(GET_ROLES_QUERY, {
    variables: { take: 10, skip: 0 }
  });
  
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [userData, setUserData] = useState({
    selectedRole: null,
    name: '',
    email: '',
    password: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSelectRole = (selectedOption) => {
    if (selectedOption) {
      setUserData({
        ...userData,
        selectedRole: selectedOption,
      });
    }
  };

  const checkExistingUser = async (email) => {
    try {
      const { data } = await checkExistingUser({
        query: CHECK_EXISTING_USER_QUERY,
        variables: { email },
      });

      return data.user;
    } catch (error) {
      console.error('Error checking existing user:', error);
      return null;
    }
  };

  const handleCreateUser = async () => {
    try {
      // Check if the email already exists
      const existingUser = await checkExistingUser( userData.email );

      if (existingUser) {
        console.error('User with this email already exists!');
        // Handle the case where the user already exists
        return;
      }

      const { data: createdUserData } = await createUser({
        variables: {
          input: {
            role: {
              connect: { id: userData.selectedRole.value }
            },
            name: userData.name,
            email: userData.email,
            password: userData.password
          },
        },
      });
  
      console.log('User created:', createdUserData.createUser);
      onClose(); // Close modal after successful addition
      refetchUser();
      toast.success('User created successfully!');
    } catch (error) {
      if (error.message.includes('Unique constraint failed on the fields: (`email`)')) {
        // console.error('Email already exists!');
        toast.error('Email already belongs to an existing user!');
        return;
      }
      console.error('Error creating user:', error);
      toast.error('Failed to create user.');
      // Handle error cases if needed
    }
  };
  

  const options = roleListData?.roles.map((role) => ({
    value: role.id,
    label: role.name,
  })) || [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: '#333',
      border: "1px solid #E1E6EF",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: '#333',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
      position: "relative"
    }),
  };

  return (
    <div className="modal-overlay">
      <div className='modal'>
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Add User</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Enter the user's data you’ll like to create.</span>
            </span>
          </div>
        </div>
        <div className='modal-body'>
          <div>
            <div style={{marginBottom: 5, width: '99.3%'}}>
              <Select
                value={userData.selectedRole}
                onChange={handleSelectRole}
                options={options}
                placeholder="Select Role"
                isClearable={true}
                isDisabled={false}
                styles={customStyles}
              />
            </div>
          </div>
          <div style={{width: '100%'}}>
            <TextInput
              type="text"
              placeholder="Name"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              style={{width: '95%'}}
            />
          </div>
          <div style={{width: '100%'}}>
            <TextInput
              type="text"
              placeholder="Email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              style={{width: '95%'}}
            />
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <TextInput
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              style={{width: '95%'}}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 560,
                cursor: 'pointer',
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                hidePasswordIcon({ })
              ) : (
                showPasswordIcon({ })
              )}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div style={{width: '100%'}}>
            <LoadingButton
              buttonText="Add User"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateUser}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;