import React, { useState, useEffect } from 'react';
import Button from '../../Button';
import '../../../styles/Modal.css';
import { close, userHeaderIcon } from '../../../data/svg';
import TextInput from '../../TextInput';
import ImageUploader from '../../ImageUploader';
import { CREATE_AUTHOR_MUTATION } from '../../../graphql/mutations/author.mutation';
import { GET_AUTHORS_QUERY } from "../../../graphql/queries/author.query"
import { useMutation, useQuery } from '@apollo/client';
import Select from 'react-select';
import LoadingButton from '../../Button/LoadingButton';
import { toast } from 'react-hot-toast';

const AddAuthor = ({ onClose }) => {
  const { refetch: refetchAuthors } = useQuery(GET_AUTHORS_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });
  const [createAuthor, { loading }] = useMutation(CREATE_AUTHOR_MUTATION);

  const [authorData, setAuthorData] = useState({
    name: '',
    image: null,
  });

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setAuthorData({ ...authorData, [name]: value });
  };

  const handleAuthorImageUpload = (image) => {
    setAuthorData({ ...authorData, image });
  };

  const handleCreateAuthor = async () => {
    try {
      const { data } = await createAuthor({
        variables: {
          data: {
            name: authorData.name,
            image: {
              upload: authorData.image
            },
          },
        },
      });

      onClose();
      console.log('Created Author:', data.createAuthor);
      refetchAuthors();
      toast.success('Author created successfully!');
    } catch (error) {
      console.error('Error Creating Author:', error);
      toast.error('Failed to create author.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className='modal-center'>
        {/* <div className="modal-h#eader">
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            Add Author
          </span>
          <span className="modal-close" onClick={onClose}>
            {close({ width: 10, height: 10, color: '#28303F' })}
          </span>
        </div> */}
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Add Author</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Enter the author's data you’ll like to create.</span>
            </span>
          </div>
        </div>
        <div className='modal-body'>
          <div style={{width: '100%'}}>
            <TextInput
              type="text"
              name="name"
              placeholder="Author Name"
              value={authorData.name}
              onChange={handleInputChange}
              style={{width: '93.5%'}}
            />
          </div>
          <div style={{width: '100%'}}>
              <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', padding: '0px 0px 0px 10px', justifyContent: 'space-between', margin: '10px 0px', border:'1px solid #E1E6EF', borderRadius: 30 }}>
                <span style={{fontSize: 11, color: '#A9A9A9'}}>Author Image</span>
                <div>
                  <ImageUploader
                    buttonText="Author Image"
                    backgroundColor="#151515"
                    borderColor="151515"
                    textColor="#ffffff"
                    file={authorData.image}
                    onUpload={handleAuthorImageUpload}
                  />
                </div>
              </div>
            </div>
        </div>
        <div className='modal-footer'>
          <div style={{width: '100%'}}>
            <LoadingButton
              buttonText="Add Author"
              backgroundColor="#259BD8"
              borderColor="259BD8"
              textColor="#ffffff"
              onClick={handleCreateAuthor}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAuthor;