import { gql } from "@apollo/client";

export const CREATE_NEWS_MUTATION = gql`
  mutation createPost($input: PostCreateInput!) {
    createPost(data: $input) {
      id
      title
      author {
        id
        name
        image {
          url
        }
        user {
          id
          name
        }
      }
      content {
        document
      }
      publishedAt
    }
  }
`;

export const UPDATE_POST_MUTATION = gql`
  mutation updatePost($postId: ID!, $input: PostUpdateInput!) {
    updatePost(where: { id: $postId }, data: $input) {
      id
      title
      coverImage {
        url
      }
      content {
        document
      }
      author {
        id
        name
      }
      publishedAt
    }
  }
`;

export const DELETE_NEWS_MUTATION = gql`
  mutation DeletePost($where: PostWhereUniqueInput!) {
    deletePost(where: $where) {
      id
      title
    }
  }
`;