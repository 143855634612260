import React, { useMemo, useState } from "react";
import "../../styles/Navbar.css";
import Navbar from "../Navbar";
import DataStatsCard from "../StatisticsCard";
import { actions } from "../../data/data";
import ResponsiveTable from "../ResponsiveTable";
import Button from "../Button";
import TextInput from "../TextInput";

import AddMagazine from "./AddMagazine";
import ViewMagazine from "./ViewMagazine";
import UpdateMagazine from "./UpdateMagazine";
import TrashMagazine from "./TrashMagazine";

import AddIssue from "./issue/AddIssue";
import ViewIssue from "./issue/ViewIssue";
import UpdateIssue from "./issue/UpdateIssue";
import TrashIssue from "./issue/TrashIssue";

import { createPortal } from "react-dom";
import Actions from "../Actions";
import { useQuery } from "@apollo/client";
import {
  GET_MAGAZINES_QUERY,
  GET_ISSUES_QUERY,
} from "../../graphql/queries/magazine.query";

import { collapseIcon, expandIcon } from "../../data/svg";

const styles = {
  container: {
    textAlign: "center",
    margin: "0px 50px 50px 50px",
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%",
  },
  connexionsHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 20,
    marginBottom: 20,
    alignItems: "center",
  },
};

const Magazine = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; // this is defined as a constant

  const [currentMagazinePage, setCurrentMagazinePage] = useState(0);
  const pageMagazineSize = 10; // this is defined as a constant

  const {
    data: allMagazinesData,
    loading: allMagazinesLoading,
    error: allMagazinesError,
    refetch: refetchMagazines,
  } = useQuery(GET_MAGAZINES_QUERY, {
    variables: {
      take: pageMagazineSize, // Number of items to fetch per page
      // skip: 0, // Number of items to skip
      skip: currentMagazinePage * pageMagazineSize,
      orderBy: [{ created_at: 'desc' }], // Your ordering preference
    },
  });

  const handleMagazinePageChange = (magazinepage) => {
    setCurrentMagazinePage(magazinepage);
  };
  // const { data: allMagazinesData, loading: allMagazinesLoading, error: allMagazinesError, refetch: refetchMagazines } = useQuery(GET_MAGAZINES_QUERY);

  const {
    data: issuesData,
    loading: issueLoading,
    error: issueError,
    refetch: refetchIssues,
  } = useQuery(GET_ISSUES_QUERY, {
    variables: {
      take: pageSize, // Number of items to fetch per page
      // skip: 0, // Number of items to skip
      skip: currentPage * pageSize,
      orderBy: [{ issueNumber: 'desc' }], // Your ordering preference
    },
  });
  console.log('Issues Data:', issuesData);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // start magazine column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        // accessorKey: "id",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Magazine Name",
        accessorKey: "name",
      },
      {
        Header: "Action",
        render: ({ row, value }) => {
          // console.log(value);
          return (
            <Actions
              actions={actions}
              openModal={handleModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );
  // end magazine column

  // start issue column
  const issueColumns = useMemo(
    () => [
      {
        Header: "ID",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Issue Number",
        accessorKey: "issueNumber",
        // accessor: "createdAt",
        render: ({ value }) => {
          if (value && value.issueNumber) {
            return `Issue #${value.issueNumber}`;
          } else {
            return "No Issue Number";
          }
        },
      },
      {
        Header: "Magazine Type",
        accessorKey: "magazine",
        render: ({ value }) => {
          if (value && value.magazine && value.magazine.name) {
            return value.magazine.name;
          } else {
            return "No Magazine";
          }
        },
      },
      {
        Header: "Issue Image",
        accessorKey: "coverImage",
        render: ({ value }) => {
          return (
            <img
              src={value && value.coverImage.url ? value.coverImage.url : 'https://i.ibb.co/bL4xyfh/no-image-placeholder.png'}
              alt="Issue Cover"
              style={{ width: '50px', height: 'auto' }}
            />
          );
        },
      },
      {
        Header: "Pages",
        accessorKey: "pagesCount",
      },
      {
        Header: "Action",
        render: ({ row, value }) => {
          return (
            <Actions
              actions={actions}
              openModal={handleIssueModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );
  // end issue column

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const [showModal, setShowModal] = useState(false);
  const [showIssueModal, setShowIssueModal] = useState(false);

  const [showViewMagazine, setShowViewMagazine] = useState(false);
  const [showViewIssue, setShowViewIssue] = useState(false);

  const [showUpdateMagazine, setShowUpdateMagazine] = useState(false);
  const [showUpdateIssue, setShowUpdateIssue] = useState(false);

  const [showTrashMagazine, setShowTrashMagazine] = useState(false);
  const [showTrashIssue, setShowTrashIssue] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    setShowIssueModal(false);
  };

  // start of magazine modal logic
  const handleModalLogic = ({ type, allMagazinesData }) => {
    // console.log("Selected Row Data:", allMagazinesData);
    // console.log("Selected Row Type:", type);
    switch (type) {
      case "view":
        setSelectedRowData(allMagazinesData);
        setShowViewMagazine(true);
        break;

      case "update":
        setSelectedRowData(allMagazinesData);
        setShowUpdateMagazine(true);
        break;

      case "trash":
        setSelectedRowData(allMagazinesData);
        setShowTrashMagazine(true);
        break;

      default:
        return null;
        break;
    }
  };
  // end of magazine modal logic

  // start of issue modal logic
  const handleIssueModalLogic = ({ type, issuesData }) => {
    // console.log(issuesData);
    // console.log(type);
    switch (type) {
      case "view":
        setSelectedRowData(issuesData);
        setShowViewIssue(true);
        break;

      case "update":
        setSelectedRowData(issuesData);
        setShowUpdateIssue(true);
        break;

      case "trash":
        setSelectedRowData(issuesData);
        setShowTrashIssue(true);
        break;

      default:
        return null;
        break;
    }
  };
  // end of issue modal logic

  const [showMagazineTableData, setShowMagazineTableData] = useState(true);
  const [showIssuesTableData, setShowIssuesTableData] = useState(true);

  const toggleMagazineTableData = () => {
    setShowMagazineTableData(!showMagazineTableData);
  };

  const toggleIssuesTableData = () => {
    setShowIssuesTableData(!showIssuesTableData);
  };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <DataStatsCard
          data={
            allMagazinesData?.magazines?.map((mag) => ({
              title: mag?.name,
              value: mag?.issuesCount,
              allMagazinesLoading
            })) || []
          }
        />
        {/* start of magazine section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Magazine</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <TextInput
                    type="text"
                    placeholder="Filter by Magazine Type"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add&nbsp;Magazine"
                      backgroundColor="#151515"
                      borderColor="151515"
                      textColor="#ffffff"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                  {showModal &&
                    createPortal(
                      <AddMagazine onClose={closeModal} />,
                      document.body
                  )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={showMagazineTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleMagazineTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
                {showMagazineTableData && (
                  <ResponsiveTable
                    isLoading={allMagazinesLoading}
                    data={allMagazinesData?.magazines || []}
                    columns={columns}
                    currentPage={currentMagazinePage}
                    pageSize={pageMagazineSize}
                    onPageChange={handleMagazinePageChange}
                    totalItems={allMagazinesData?.magazinesCount}
                    totalPages={allMagazinesData?.totalPages}
                  />
                )}
                
                {showViewMagazine && (
                  <ViewMagazine
                    onClose={() => setShowViewMagazine(false)}
                    data={selectedRowData}
                    magazineId={selectedRowData?.id}
                  />
                )}
                {showUpdateMagazine && (
                  <UpdateMagazine
                    onClose={() => setShowUpdateMagazine(false)}
                    allMagazinesData={selectedRowData}
                    magazineId={selectedRowData?.id}
                    magazineName={selectedRowData?.name}
                    refetchMagazines={refetchMagazines}
                  />
                )}
                {showTrashMagazine && (
                  <TrashMagazine
                    onClose={() => setShowTrashMagazine(false)}
                    data={selectedRowData}
                    magazineId={selectedRowData?.id}
                    magazineName={selectedRowData?.name}
                    refetchMagazines={refetchMagazines}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of magazine section */}

        {/* start of issue section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Issues</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <TextInput
                    type="text"
                    placeholder="Filter by Magazine Type"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add Issue"
                      backgroundColor="#151515"
                      borderColor="151515"
                      textColor="#ffffff"
                      onClick={() => setShowIssueModal(true)}
                    />
                  </div>
                  {showIssueModal &&
                    createPortal(
                      <AddIssue onClose={closeModal} />,
                      document.body
                  )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={showIssuesTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleIssuesTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
                {showIssuesTableData && (
                  <ResponsiveTable
                    isLoading={issueLoading}
                    data={issuesData?.issues || []}
                    columns={issueColumns}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    totalItems={issuesData?.issuesCount} // Pass totalItems to ResponsiveTable
                    totalPages={issuesData?.totalPages}
                  />
                )}
                
                {showViewIssue && (
                  <ViewIssue
                    onClose={() => setShowViewIssue(false)}
                    issuesData={selectedRowData}
                    issueId={selectedRowData?.id}
                  />
                )}
                {showUpdateIssue && (
                  <UpdateIssue
                    onClose={() => setShowUpdateIssue(false)}
                    issuesData={selectedRowData}
                    issueId={selectedRowData?.id}
                    issueNumber={selectedRowData?.issueNumber}
                    magazineId={selectedRowData?.id}
                    refetchIssues={refetchIssues}
                  />
                )}
                {showTrashIssue && (
                  <TrashIssue
                    onClose={() => setShowTrashIssue(false)}
                    issuesData={selectedRowData}
                    issueId={selectedRowData?.id}
                    issueNumber={selectedRowData?.issueNumber}
                    refetchIssues={refetchIssues}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of issue section */}

        <div>
          <p style={{fontSize: 13, fontWeight: 500, textAlign: 'center'}}>biz4Biz Control Panel © 2023 - 2024. <span style={{fontSize: 10}}>version 25(0.0.1)</span></p>
        </div>
      </div>
    </>
  );
};

export default Magazine;
