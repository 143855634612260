import React, { useMemo, useState } from "react";
import "../../styles/Navbar.css";
import Navbar from "../Navbar";
import DataStatsCard from "../StatisticsCard";
import { users, usersTable, actions } from "../../data/data";
import ResponsiveTable from "../ResponsiveTable";
import Button from "../Button";
import TextInput from "../TextInput";
import AddNews from "./AddUser";
import { createPortal } from "react-dom";
import Actions from "../Actions";
import { useQuery } from "@apollo/client";

import { collapseIcon, expandIcon } from "../../data/svg"

import { GET_USERS_PAGE_QUERY } from "../../graphql/queries/users.query";
import { GET_ROLES_QUERY } from "../../graphql/queries/roles.query";

import AddRole from "./role/AddRole";

import ViewUser from "./ViewUser";
import ViewRole from "./role/ViewRole";

import UpdateUser from "./UpdateUser";
import UpdateRole from "./role/UpdateRole";

import TrashUser from "./TrashUser";
import TrashRole from "./role/TrashRole";

const styles = {
  container: {
    textAlign: "center",
    margin: "0px 50px 50px 50px",
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%",
  },
  connexionsHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 20,
    marginBottom: 20,
    alignItems: "center",
  },
};

const Users = () => {
  // start roles pagination and data implementation
  const [currentRolePage, setCurrentRolePage] = useState(0);
  const pageRoleSize = 10; // this is defined as a constant

  const { data: rolesData, loading: roleLoading, error: roleError, refetch: refetchRoles } = useQuery(GET_ROLES_QUERY, {
    variables: {
      take: pageRoleSize,
      skip: currentRolePage * pageRoleSize,
      orderBy: [{ name: 'asc' }], // Your ordering preference
    },
  });

  const handleRolePageChange = (rolepage) => {
    setCurrentRolePage(rolepage);
  };
  // end roles pagination and data implementation

  const [currentUserPage, setCurrentUserPage] = useState(0);
  const pageUserSize = 10; // this is defined as a constant

  const { data: userData, loading: userLoading, error, refetch: refetchUsers, } = useQuery(GET_USERS_PAGE_QUERY, {
    variables: {
      take: pageUserSize,
      skip: currentUserPage * pageUserSize,
      orderBy: [{ createdAt: 'asc' }],
    },
  });

  const handleUserPageChange = (userpage) => {
    setCurrentUserPage(userpage);
  };

  console.log(userData);

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);

  const closeModal = () => {
    setShowAddUserModal(false);
    setShowRoleModal(false);
  };

  const [showViewUser, setShowViewUser] = useState(false);
  const [showViewRole, setShowViewRole] = useState(false);

  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [showUpdateRole, setShowUpdateRole] = useState(false);

  const [showTrashUser, setShowTrashUser] = useState(false);
  const [showTrashRole, setShowTrashRole] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState(null);

  // start role column
  const roleColumns = useMemo(
    () => [
      {
        Header: "ID",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Role",
        accessorKey: "name",
        // render: ({ value }) => {
        //   if (value && value.name) {
        //     return `Issue #${value.name}`;
        //   } else {
        //     return "No Role";
        //   }
        // },
      },
      {
        Header: "Action",
        render: ({ row, value }) => {
          return (
            <Actions
              actions={actions}
              openModal={handleRoleModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );
  // end role column

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Name",
        accessorKey: "name",
      },
      {
        Header: "Email Address",
        accessorKey: "email",
      },
      {
        Header: "Role",
        accessorKey: "role",
        render: ({ value }) => value?.role?.name,
      },
      {
        Header: "Date Joined",
        // accessorKey: "createdAt",
        render: ({ value }) => {
          if (value && value?.createdAt) {
            const publishedDate = new Date(value?.createdAt);
            if (!isNaN(publishedDate.getTime())) {
              // Valid date, format it
              const formattedDate = publishedDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });
              return formattedDate;
            }
          }
          return "No Date";
        },
      },

      // {
      //   Header: "Action",
      //   render: ({ row, value }) => <Actions actions={actions} />,
      // },
      {
        Header: "Action",
        render: ({ row, value }) => {
          return (
            <Actions
              actions={actions}
              openModal={handleUserModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );

  // start of role modal logic
  const handleRoleModalLogic = ({ type, rolesData }) => {
    // console.log(issuesData);
    // console.log(type);
    switch (type) {
      case "view":
        setSelectedRowData(rolesData);
        setShowViewRole(true);
        break;

      case "update":
        setSelectedRowData(rolesData);
        setShowUpdateRole(true);
        break;

      case "trash":
        setSelectedRowData(rolesData);
        setShowTrashRole(true);
        break;

      default:
        return null;
        break;
    }
  };
  // end of role modal logic

  // start of user modal logic
  const handleUserModalLogic = ({ type, data }) => {
    // console.log(issuesData);
    // console.log(type);
    switch (type) {
      case "view":
        setSelectedRowData(data);
        setShowViewUser(true);
        break;

      case "update":
        setSelectedRowData(data);
        setShowUpdateUser(true);
        break;

      case "trash":
        setSelectedRowData(data);
        setShowTrashUser(true);
        break;

      default:
        return null;
        break;
    }
  };
  // end of user modal logic

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const [showRolesTableData, setShowRolesTableData] = useState(true);
  const [showUsersTableData, setShowUsersTableData] = useState(true);

  const toggleRolesTableData = () => {
    setShowRolesTableData(!showRolesTableData);
  };

  const toggleUsersTableData = () => {
    setShowUsersTableData(!showUsersTableData);
  };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <DataStatsCard
          data={[
            { title: "Users", value: userData?.usersCount, loading: userLoading },
            // { title: "Admin", value: userData?.adminRoleCount },
            { title: "Role", value: rolesData?.rolesCount, loading: roleLoading },
          ]}
        />
        {/* start of role section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Roles</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add Role"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      onClick={() => setShowRoleModal(true)}
                    />
                  </div>
                  {showRoleModal &&
                    createPortal(
                      <AddRole onClose={closeModal} />,
                      document.body
                    )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      // buttonText={showRolesTableData ? 'Collapse' : 'Expand'}
                      icon={showRolesTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleRolesTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
              {showRolesTableData && (
                <ResponsiveTable
                  isLoading={roleLoading}
                  data={rolesData?.roles || []}
                  columns={roleColumns}
                  currentPage={currentRolePage}
                  pageSize={pageRoleSize}
                  onPageChange={handleRolePageChange}
                  totalItems={rolesData?.rolesCount}
                  totalPages={rolesData?.totalPages}
                />
              )}
                {showViewRole && (
                  <ViewRole
                    onClose={() => setShowViewRole(false)}
                    rolesData={selectedRowData}
                    roleId={selectedRowData?.id}
                  />
                )}
                {showUpdateRole && (
                  <UpdateRole
                    onClose={() => setShowUpdateRole(false)}
                    rolesData={selectedRowData}
                    roleId={selectedRowData?.id}
                    roleName={selectedRowData?.name}
                    refetchRoles={refetchRoles}
                  />
                )}
                {showTrashRole && (
                  <TrashRole
                    onClose={() => setShowTrashRole(false)}
                    rolesData={selectedRowData}
                    roleId={selectedRowData?.id}
                    roleName={selectedRowData?.name}
                    refetchRoles={refetchRoles}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of role section */}

        {/* start of users section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Users</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <TextInput
                    type="text"
                    placeholder="Filter by Role"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add&nbsp;User"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      onClick={() => setShowAddUserModal(true)}
                    />
                  </div>
                  {showAddUserModal &&
                    createPortal(
                      <AddNews onClose={closeModal} />,
                      document.body
                    )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      // buttonText={showUsersTableData ? 'Collapse' : 'Expand'}
                      icon={showUsersTableData ? collapseIcon({ }) : expandIcon({ })}
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#151515"
                      onClick={toggleUsersTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
              {showUsersTableData && (
                <ResponsiveTable
                  isLoading={userLoading}
                  data={userData?.users || []}
                  columns={columns}
                  currentPage={currentUserPage}
                  pageSize={pageUserSize}
                  onPageChange={handleUserPageChange}
                  totalItems={userData?.usersCount}
                  totalPages={userData?.totalPages}
                />
              )}
                {showViewUser && (
                  <ViewUser
                    onClose={() => setShowViewUser(false)}
                    data={selectedRowData}
                    userId={selectedRowData?.id}
                  />
                )}
                {showUpdateUser && (
                  <UpdateUser
                    onClose={() => setShowUpdateUser(false)}
                    data={selectedRowData}
                    userId={selectedRowData?.id}
                    userName={selectedRowData?.name}
                    refetchUsers={refetchUsers}
                  />
                )}
                {showTrashUser && (
                  <TrashUser
                    onClose={() => setShowTrashUser(false)}
                    data={selectedRowData}
                    userId={selectedRowData?.id}
                    userName={selectedRowData?.name}
                    refetchUsers={refetchUsers}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of users section */}

        <div>
          <p style={{fontSize: 13, fontWeight: 500, textAlign: 'center'}}>biz4Biz Control Panel © 2023 - 2024. <span style={{fontSize: 10}}>version 25(0.0.1)</span></p>
        </div>
      </div>
    </>
  );
};

export default Users;
