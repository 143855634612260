import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Loader from "../Loader";
import '../../styles/Button.css';

const Button = ({
  buttonText,
  icon,
  imageSrc,
  style,
  borderColor,
  backgroundColor,
  textColor,
  onClick,
  linkURL,
  dropdownItems,
  alignLeft,
  // showLoader = false,
  // showCustomLoader = false,
  // customLoader = <Loader showCaption={false} />
}) => {
  const buttonStyle = {
    borderColor: borderColor || "black",
    backgroundColor: backgroundColor || "transparent",
    color: textColor || "black",
    borderRadius: style?.borderRadius || 50,
    display: "flex",
    alignItems: "center",
    justifyContent: alignLeft ? "flex-start" : "center", // Conditionally set alignment
  };

  // const [isLoading, setIsLoading] = useState(false);

  // const handleButtonClick = async () => {
  //   if (showLoader) {
  //     setIsLoading(true); // Activate loader only if showLoader is true
  //   }
  //   try {
  //     // Simulate loading action (replace this with your actual action)
  //     await new Promise((resolve) => setTimeout(resolve, 20)); // Replace with actual action
  //     if (showLoader) {
  //       setIsLoading(false); // Deactivate loader after action completes if showLoader is true
  //     }
  //     onClick && onClick();
  //   } catch (error) {
  //     if (showLoader) {
  //       setIsLoading(false); // Deactivate loader in case of error if showLoader is true
  //     }
  //     console.error("Error:", error);
  //     // Handle errors appropriately
  //   }
  // };

  const isAvatarGroup =
    React.isValidElement(icon) && icon.type.displayName === "AvatarGroup";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <a rel="noreferrer" href={linkURL} style={{ textDecoration: "none" }}>
      <div className="button-container">
        <button
          onClick={() => {
            toggleDropdown();
            onClick && onClick();
          }}
          className="button"
          style={buttonStyle}
        >
          {isAvatarGroup ? (
            icon
          ) : (
            <>
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt="Button Icon"
                  className="nav-language-image"
                />
              ) : null}
              {icon ? <span style={{ margin: "0px 2px 0px 2px" }}>{icon}</span> : null}
              {/* {icon} */}
              {buttonText}
              {dropdownItems ? (
                isDropdownOpen ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )
              ) : null}
            </>
          )}
        </button>
        {dropdownItems && dropdownItems.length > 0 && isDropdownOpen && (
          <div className="dropdown">
            {dropdownItems.map((item, index) => (
              <button
                style={{ color: "#151515" }}
                className="dropdown-item"
                key={index}
                onClick={item.onClick}
              >
                {item.image ? (
                  <img
                    src={item.image}
                    alt=""
                    className="dropdown-item-image nav-language-image"
                  />
                ) : null}
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </a>
  );
};

export default Button;
