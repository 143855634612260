import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: UserCreateInput!) {
    createUser(data: $input) {
      id
      name
      email
      createdAt
      role {
        name
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
mutation updateUser($userId: ID!, $input: UserUpdateInput!) {
  updateUser(where: { id: $userId }, data: $input) {
      id
      name
      email
      role {
        name
        canSeeOtherPeople
        canEditOtherPeople
        canManagePeople
        canManageEvents
        canManageMagazines
        canManageBusinessDirectory
        canManageRoles
        canUseAdminUI
        canManagePosts
        assignedTo {
          id
          name
        }
        assignedToCount
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
      name
    }
  }
`;