import { component } from "keystone-react-editor";
import { image } from "./image";
import ImageUploadPreview from "./ImageUploadPreview";

export const componentBlocks = {
  image: component({
    preview: (props) => {
      return (
        <ImageUploadPreview
          defaultValue={props?.fields?.image?.value}
          onChange={props?.fields?.image?.onChange}
        />
      );
    },
    label: "Image",
    schema: {
      image: image({ listKey: "image" }),
    },
  }),
};
