
export const TOKEN_KEY = "access_token";

// Set access token in localStorage
export function setAccessToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

// Get access token from localStorage
export function getAccessToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY);
}
