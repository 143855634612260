import { useMutation, useQuery } from "@apollo/client";
import { DocumentEditor, defaultDocumentFeatures } from "keystone-react-editor";
import React, { useState, useRef } from "react";
import Select from "react-select";
import { newsHeaderIcon } from "../../data/svg";
import { CREATE_NEWS_MUTATION } from "../../graphql/mutations/news.mutation";
import { GET_AUTHORS_QUERY } from "../../graphql/queries/author.query";
import { GET_NEWS_PAGE_QUERY } from "../../graphql/queries/posts.query";
import "../../styles/Modal.css";
import Button from "../Button";
import LoadingButton from "../Button/LoadingButton";
import ImageUploader from "../ImageUploader";
import TextInput from "../TextInput";
import toast from "react-hot-toast";
import { componentBlocks } from "../ImageComponentBlock/ImageComponentBlock";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "This is editable" }],
  },
];

const AddNews = ({ onClose }) => {
  const { refetch: refetchNews } = useQuery(GET_NEWS_PAGE_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });
  const [createPost, { loading }] = useMutation(CREATE_NEWS_MUTATION);
  const { data: authorListData } = useQuery(GET_AUTHORS_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  const [newsData, setNewsData] = useState({
    title: "",
    coverImage: null,
    content: [],
    selectedAuthor: null,
    publishedAt: "",
  });
  const val = useRef(initialValue);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewsData({ ...newsData, [name]: value });
  };

  const handleNewsCoverImageUpload = (newsCoverImage) => {
    setNewsData({ ...newsData, coverImage: newsCoverImage });
  };

  const handleDateChange = (news) => {
    const selectedDate = news.target.value;

    // Check if the selected date is in the past
    // if (selectedDate < currentDate) {
    //   // Alert the user or handle the past date selection as needed
    //   alert("Please select a date in the present or future.");
    //   return;
    // }

    // Update the state only if it's not a past date
    setNewsData({
      ...newsData,
      publishedAt: selectedDate,
    });
  };

  const handleSelectAuthor = (selectedOption) => {
    if (selectedOption) {
      setNewsData({
        ...newsData,
        selectedAuthor: selectedOption,
        // name: selectedOption.label,
      });
    }
  };

  const handleCreateNews = async () => {
    try {
      const selectedDate = new Date(newsData.publishedAt);
      const isoDate = selectedDate.toISOString();

      const createdNews = await createPost({
        variables: {
          input: {
            title: newsData.title,
            coverImage: {
              upload: newsData.coverImage,
            },
            author: {
              connect: {
                id: newsData.selectedAuthor.value,
              },
            },
            publishedAt: isoDate,
            // image: newsData.image,
            // content: newsData.content,
            content: val.current,
          },
        },
      });

      onClose();
      refetchNews();
      toast.success("bizViews created successfully!");
      console.log("Created bizViews:", createdNews.createPost);
    } catch (error) {
      // setIsLoading(false);
      console.error("Error Creating bizViews:", error);
    }
  };

  const options =
    authorListData?.authors.map((author) => ({
      value: author.id,
      label: author.name,
    })) || [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
      border: "1px solid #E1E6EF",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
      position: "relative",
    }),
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Add bizViews</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                Enter the bizViews data you’ll like to create.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%" }}>
            <TextInput
              type="text"
              name="title"
              placeholder="bizViews Title"
              value={newsData.title}
              onChange={handleInputChange}
              style={{ width: "93.5%" }}
            />
          </div>

          <div style={{ width: "99.5%" }}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "10px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                bizViews Cover Image
              </span>
              <div>
                <ImageUploader
                  buttonText="Upload Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={newsData.coverImage}
                  onUpload={handleNewsCoverImageUpload}
                />
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <TextInput
              type="date"
              placeholder="Published Date"
              value={newsData.publishedAt}
              onChange={handleDateChange}
              style={{ width: "95%" }}
            />
          </div>

          <div style={{ width: "99%" }}>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                border: "1px solid #E1E6EF",
                margin: "15px 0px",
              }}
            >
              <DocumentEditor
                key="edito"
                initialValue={val.current}
                autoFocus={false}
                onChange={(document) => {
                  // console.log(document);
                  // handleInputChange("content", document);
                  val.current = document;
                }}
                componentBlocks={componentBlocks}
                documentFeatures={defaultDocumentFeatures}
              />
            </div>
          </div>
          <div style={{ width: "99%", marginTop: 5 }}>
            <Select
              value={newsData.selectedAuthor}
              onChange={handleSelectAuthor}
              options={options}
              placeholder="Select Author"
              isClearable={true}
              isSearchable={true}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Add bizViews"
              backgroundColor="#259BD8"
              borderColor="259BD8"
              textColor="#ffffff"
              onClick={handleCreateNews}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
