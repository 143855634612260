import React, { useState } from "react";
import Button from "../../Button";
import "../../../styles/Modal.css";
import { eventHeaderIcon } from "../../../data/svg";
import TextInput from "../../TextInput";
import { useQuery } from "@apollo/client";
import { GET_AUTHOR_QUERY } from "../../../graphql/queries/author.query";
import Loader from "../../Loader";

const ViewAuthor = ({ onClose, authorId }) => {
  console.log("check author id:", authorId)
  const { loading, error, data } = useQuery(GET_AUTHOR_QUERY, {
    variables: {
      where: { id: authorId },
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  if (loading) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Author</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only author data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <Loader showCaption={true}/>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>;
  }

  if (error || !data || !data.author) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Author</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only author data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <span>Error fetching data</span>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>
  }

  const authorData = data.author;

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>View Author</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only author data.</span>
            </span>
          </div>
        </div>
        
        <div className="modal-body">
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div>
              <img
                src={authorData?.image?.url}
                alt="Author Image"
                style={{ maxWidth: "100px", maxHeight: "100px", borderRadius: '50%' }}
              />
            </div>
          </div>
          
          <div style={{ width: "100%", textAlign: 'left' }}>
            <span style={{fontSize: 12}}>Author Name</span>
            <TextInput
              type="text"
              placeholder="Author Name"
              value={authorData.name}
              style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>

          <div style={{ width: "98%", display: "flex", gap: 10 }}></div>
          
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAuthor;