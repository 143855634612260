import React, { useState, useEffect } from "react";
import { eventHeaderIcon } from "../../../data/svg";
import "../../../styles/Modal.css";
import Button from "../../Button";

import TextInput from "../../TextInput";

import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ROLE_MUTATION } from "../../../graphql/mutations/role.mutation";
import { GET_ROLE_QUERY } from "../../../graphql/queries/roles.query";

import { toast } from 'react-hot-toast';
import LoadingButton from "../../Button/LoadingButton";
import Loader from "../../Loader";

const UpdateRole = ({ onClose, roleId }) => {
  // console.log("this is user id:", userId)

  const [updateRole, { loading }] = useMutation(UPDATE_ROLE_MUTATION);
  const { data: roleData, loading: fetchLoading, error } = useQuery(GET_ROLE_QUERY, {
    variables: {
      where: { id: roleId },
      take: 10,
      skip: 0
    },
  });

  const [roleInfo, setRoleInfo] = useState({
    name: "",
  });

  useEffect(() => {
    if (!fetchLoading && roleData) {
      setRoleInfo({
        ...roleInfo,
        name: roleData.role.name,
      });
    }
  }, [fetchLoading, roleData]);

  const handleInputChange = (fieldName, value) => {
    setRoleInfo({
      ...roleInfo,
      [fieldName]: value,
    });
  };

  const handleUpdateRole = async () => {
    try {
      const updatedRole = await updateRole({
        variables: {
          roleId,
          input: {
            name: roleInfo.name,
          },
        },
      });

      if (updatedRole?.data?.updateRole) {
        // Perform actions after successful update
        onClose();
        toast.success("Role updated successfully!");
      }
    } catch (error) {
      // console.error("Error updating role:", error);
      toast.success("Failed to update role");
      // Handle error cases if needed
    }
  };

  if (fetchLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Role</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit role data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              <Loader showCaption={true}/>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Role</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit role data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              Error fetching data: {error.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Update Role</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit role data.</span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "98%", textAlign: 'left' }}>
            <label style={{fontSize: 12}}>Role</label>
            <TextInput
              type="text"
              name="name"
              placeholder="Role"
              value={roleInfo.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              style={{ width: "95%" }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update Role"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleUpdateRole}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateRole;