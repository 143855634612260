import { gql } from "@apollo/client";

export const LOGIN_MUTAION = gql`
  mutation login($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          email
          name
          id
          role {
            id
            name
            canManageEvents
            canManageMagazines
            canSeeOtherPeople
            canEditOtherPeople
            canManagePeople
            canManageRoles
            canUseAdminUI
            canManageBusinessDirectory
            canManagePosts
          }
        }
        sessionToken
      }

      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

// Mutation for redeeming the password reset token
export const REDEEM_PASSWORD_MUTATION = gql`
  mutation RedeemUserPasswordResetToken($email: String!, $token: String!, $password: String!) {
    redeemUserPasswordResetToken(email: $email, token: $token, password: $password) {
      code
      message
    }
  }
`;

// Mutation for sending password reset link to email
export const SEND_PASSWORD_RESET_LINK_MUTATION = gql`
  mutation SendPasswordResetLink($email: String!) {
    sendUserPasswordResetLink(email: $email)
  }
`;