import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
// import '../styles/Button.css';

const SidebarButton = ({
  buttonText,
  icon,
  imageSrc,
  style,
  borderColor,
  backgroundColor,
  textColor,
  onClick,
  linkURL,
  dropdownItems,
  alignLeft,
  isFirstButton,
  isLastButton,
}) => {
  // const hasBorderRadius = isFirstButton || isLastButton;

  const buttonStyle = {
    borderColor: borderColor || "black",
    backgroundColor: backgroundColor || "transparent",
    color: textColor || "black",
    // borderRadius: style?.borderRadius || 50,
    // borderRadius: hasBorderRadius ? style?.borderRadius || 50 : 50,
    display: "flex",
    alignItems: "center",
    justifyContent: alignLeft ? "flex-start" : "center", // Conditionally set alignment
    borderTopLeftRadius: isFirstButton ? style?.borderRadius || 15 : 0,
    borderTopRightRadius: isFirstButton ? style?.borderRadius || 15 : 0,
    borderBottomLeftRadius: isLastButton ? style?.borderRadius || 15 : 0,
    borderBottomRightRadius: isLastButton ? style?.borderRadius || 15 : 0,
    // marginBottom: 10
    // padding: '8px 16px',
  };

  const isAvatarGroup =
    React.isValidElement(icon) && icon.type.displayName === "AvatarGroup";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <a rel="noreferrer" href={linkURL} style={{ textDecoration: "none" }}>
      <div className="button-container">
        <button
          onClick={() => {
            toggleDropdown();
            onClick && onClick();
          }}
          className="button"
          style={buttonStyle}
        >
          {isAvatarGroup ? (
            icon
          ) : (
            <>
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt="Button Icon"
                  className="nav-language-image"
                />
              ) : null}
              {icon ? <span style={{ marginRight: "8px" }}>{icon}</span> : null}
              {buttonText}
              {dropdownItems ? (
                isDropdownOpen ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )
              ) : null}
            </>
          )}
        </button>
        {dropdownItems && dropdownItems.length > 0 && isDropdownOpen && (
          <div className="dropdown">
            {dropdownItems.map((item, index) => (
              <button
                style={{ color: "#151515" }}
                className="dropdown-item"
                key={index}
                onClick={item.onClick}
              >
                {item.image ? (
                  <img
                    src={item.image}
                    alt=""
                    className="dropdown-item-image nav-language-image"
                  />
                ) : null}
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </a>
  );
};

export default SidebarButton;