import { gql } from "@apollo/client";

// export const CREATE_ISSUE_MUTATION = gql`
//   mutation createIssueWithPages($input: IssueCreateInput!) {
//     createIssue(data: $input) {
//       id
//       theme
//       issueNumber
//       coverImage {
//         id
//         url
//       }
//       publicationDate
//       draft
//       publishedAt
//       createdAt
//       updated_at
//       pages {
//         image {
//           url
//         }
//         issue {
//           id
//         }
//         page_no
//       }
//       pagesCount
//       magazine {
//         id
//         name
//       }
//       label
//       description
//     }
//   }
// `;

export const CREATE_ISSUE_MUTATION = gql`
  mutation createIssue($input: IssueCreateInput!) {
    createIssue(data: $input) {
      id
      issueNumber
      coverImage {
        id
        url
        height
        width
      }
      magazine {
        id
        name
      }
      pagesCount
      publicationDate
      publishedAt
      theme
      updated_at

      # issuesCount
    }
  }
`;

export const CREATE_ISSUE_PAGE_MUTATION = gql`
  mutation createIssuePage($data: IssuePageCreateInput!) {
    createIssuePage(data: $data) {
      id
      page_no
      image {
        url
      }
      alt
      issue {
        pages(orderBy: { page_no: asc }) {
          page_no
        }
      }
      label
      created_at
    }
  }
`;
export const CREATE_ISSUE_PAGES_MUTATION = gql`
  mutation createIssuePages($data: [IssuePageCreateInput!]!) {
    createIssuePages(data: $data) {
      id
      page_no
      image {
        url
      }
      alt
      issue {
        pages {
          page_no
        }
      }
      label
      created_at
    }
  }
`;

export const UPDATE_ISSUE_MUTATION = gql`
  mutation updateIssue($issueId: ID!, $input: IssueUpdateInput!) {
    updateIssue(where: { id: $issueId }, data: $input) {
      id
      issueNumber
      coverImage {
        id
        url
        height
        width
      }
      magazine {
        id
        name
      }
      pages {
        image {
          url
        }
      }
      pagesCount
      theme
    }
  }
`;

export const UPDATE_ISSUE_PAGE_MUTATION = gql`
  mutation UpdateIssuePage($pageId: ID!, $data: IssuePageUpdateInput!) {
    updateIssuePage(where: { id: $pageId }, data: $data) {
      page_no
      image {
        url
      }
      issue {
        pages(orderBy: { page_no: asc }) {
          id
          page_no
          image {
            url
          }
        }
      }
    }
  }
`;

export const DELETE_ISSUE_PAGE_MUTATION = gql`
  mutation deleteIssuePage($pageId: ID!) {
    deleteIssuePage(where: { id: $pageId }) {
      id
      page_no
      image {
        url
      }
      issue {
        pages(orderBy: { page_no: asc }) {
          id
          page_no
          image {
            url
          }
        }
      }
    }
  }
`;

export const DELETE_ISSUE_MUTATION = gql`
  mutation DeleteIssue($where: IssueWhereUniqueInput!) {
    deleteIssue(where: $where) {
      id
      issueNumber
    }
  }
`;
