import React from "react";
import LoginComponent from "../components/Login";
import '../styles/Login.css'

const Login = () => {
  return (
    <main className="background">
      <LoginComponent />
    </main>
  );
};

export default Login;
