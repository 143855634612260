// ActionIcon.js
import React from 'react';
import '../../styles/Actions.css'

const ActionsIcon = ({ icon, onClick }) => (
  <div className="action-icon" onClick={onClick}>
    {icon}
  </div>
);

export default ActionsIcon;