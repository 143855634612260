import { gql } from "@apollo/client";

export const GET_SPEAKERS_QUERY = gql`
  query GetSpeakers($take: Int!, $skip: Int!, $orderBy: [SpeakerOrderByInput!]) {
    speakersCount
    speakers(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
      image {
        id
        url
      }
      about
    }
  }
`;

export const GET_SPEAKER_QUERY = gql`
  query Speaker($where: SpeakerWhereUniqueInput!) {
    speaker(where: $where) {
      id
      name
      image {
        url
      }
      about
    }
  }
`;