import React, { useState } from "react";
import Button from "../../Button";
import "../../../styles/Modal.css";
import { eventHeaderIcon } from "../../../data/svg";
import TextInput from "../../TextInput";
import { useQuery } from "@apollo/client";
import { GET_ROLE_QUERY } from "../../../graphql/queries/roles.query";
import Loader from "../../Loader";

const ViewUser = ({ onClose, roleId }) => {
  console.log("check user id:", roleId)
  
  const { loading, error, data } = useQuery(GET_ROLE_QUERY, {
    variables: {
      where: { id: roleId }, // Pass the USER ID to the query variables
      take: 10,
      skip: 0
    },
  });

  if (loading) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Role</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only role data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <Loader showCaption={true}/>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>;
  }

  if (error || !data || !data.role) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Role</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only role data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <span>Error fetching data</span>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>
  }

  const roleData = data.role;

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>View Role</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only role data.</span>
            </span>
          </div>
        </div>
        
        <div className="modal-body">
          <div style={{ width: "98%", marginTop: -10, textAlign: 'left' }}>
            <label style={{ fontSize: 12 }}>Role</label>
            <TextInput
              type="text"
              placeholder="Role Name"
              value={roleData.name}
              style={{ width: "97%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>
          
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;