import React, { useEffect, useState } from "react";
import { magazineHeaderIcon } from "../../../data/svg";
import "../../../styles/Modal.css";
import Button from "../../Button";

import TextInput from "../../../components/TextInput";

import "../../../../node_modules/react-calendar-datetime-picker/dist/style.css";
import ImageUploader from "../../../components/ImageUploader";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-hot-toast";
import Select from "react-select";
import {
  UPDATE_ISSUE_MUTATION,
  UPDATE_ISSUE_PAGE_MUTATION,
} from "../../../graphql/mutations/issues.mutation";
import {
  GET_ISSUE_QUERY,
  GET_MAGAZINES_BASIC_INFO_QUERY,
} from "../../../graphql/queries/magazine.query";
import LoadingButton from "../../Button/LoadingButton";
import Loader from "../../Loader";
import IssuePages from "./IssuePages/IssuePages";

const UpdateIssue = ({
  onClose,
  issueId,
  pageId,
  imageSrc,
  onEdit,
  onDelete,
}) => {
  const { refetch: refetchIssues, data: magazineData } = useQuery(
    GET_MAGAZINES_BASIC_INFO_QUERY
  );
  const [updateIssue, { loading }] = useMutation(UPDATE_ISSUE_MUTATION);
  const [updateIssuePage] = useMutation(UPDATE_ISSUE_PAGE_MUTATION);
  const {
    data: issueQueryData,
    loading: issueLoading,
    error: issueError,
  } = useQuery(GET_ISSUE_QUERY, {
    variables: {
      where: { id: issueId },
      orderBy: { page_no: "asc" },
    },
  });

  const [issueData, setIssueData] = useState({
    selectedMagazine: null,
    issueNumber: "",
    description: "",
    coverImage: null,
    magazineImages: [],
    theme: "",
    pages: [
      {
        id: pageId,
        image: {
          upload: null,
        },
      },
    ],
  });

  useEffect(() => {
    if (
      !issueLoading &&
      !issueError &&
      issueQueryData &&
      issueQueryData.issue
    ) {
      const existingIssue = issueQueryData.issue;
      setIssueData((prev) => ({
        ...prev,
        ...existingIssue,
        selectedMagazine: {
          id: existingIssue.magazine.id,
          value: existingIssue.magazine.name,
          label: existingIssue.magazine.name,
        },
        coverImage: null,
        issueNumber: existingIssue.issueNumber,
        description: existingIssue.description,
        // pages: null
        // ... other data population
      }));
    }
  }, [issueQueryData, issueLoading, issueError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIssueData({ ...issueData, [name]: value });
  };

  const handleIssueCoverImageUpload = (coverImage) => {
    setIssueData({ ...issueData, coverImage });
  };

  const handleSelectMagazine = (selectedOption) => {
    if (selectedOption) {
      setIssueData((prevIssueData) => ({
        ...prevIssueData,
        selectedMagazine: {
          id: selectedOption.id,
          value: selectedOption.value,
          label: selectedOption.label,
        },
      }));
    } else {
      setIssueData((prevIssueData) => ({
        ...prevIssueData,
        selectedMagazine: null,
      }));
    }
  };

  const handleUpdateIssue = async () => {
    console.log(issueData.pages);
    try {
      const issueNumber = parseInt(issueData.issueNumber, 10);

      const optional = {};
      if (issueData.coverImage instanceof File) {
        optional.coverImage = {
          upload: issueData.coverImage,
        };
      }
      if (
        issueData?.selectedMagazine?.id !== issueQueryData?.issue?.magazine?.id
      ) {
        optional.magazine = {
          connect: {
            id: issueData.selectedMagazine?.id,
          },
        };
      }
      const updatedIssue = await updateIssue({
        variables: {
          issueId: issueId,
          // pageId: pageId,
          input: {
            // issueNumber: issueData.issueNumber,
            issueNumber: parseInt(issueNumber),
            description: issueData.description,
            theme: issueData.theme,

            ...optional,
          },
        },
      });

      for (let index = 0; index < issueData.pages.length; index++) {
        const file = issueData?.pages[index]?.image?.upload;
        const pageId = issueData?.pages[index]?.id;

        if (pageId) {
          // Mutation call for updating issue pages
          const result = await updateIssuePage({
            variables: {
              pageId: pageId,
              issueId: issueId,
              image: { upload: file },
              alt: file.name,
              page_no: index + 1,
            },
          });

          console.log("Result from updateIssuePage:", result); // Log the result for debugging
        } else {
          console.error("PageId is undefined for page at index:", index);
          // Handle or log this scenario as needed
        }
      }

      if (updatedIssue?.data?.updateIssue) {
        // Perform actions after successful update
        onClose();
        refetchIssues();
        toast.success("Issue updated successfully!");
      }
    } catch (error) {
      console.error("Error updating issue:", error);
      toast.error("Failed to update issue");
      // Handle error cases if needed
    }
  };

  const options =
    magazineData?.magazines?.map((magazine) => ({
      id: magazine.id,
      value: magazine.name,
      label: magazine.name,
    })) || [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
      textAlign: "left !important",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
    }),
  };

  if (issueLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {magazineHeaderIcon({
                  width: 10,
                  height: 10,
                  color: "#28303F",
                })}
                <br />
                <span>Update Issue</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only issue data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (issueError || !issueQueryData || !issueQueryData.issue) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {magazineHeaderIcon({
                  width: 10,
                  height: 10,
                  color: "#28303F",
                })}
                <br />
                <span>Update Issue</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only issue data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <span>Error fetching data</span>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {magazineHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Update Issue</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                Enter the magazine data you’ll like to create.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ textAlign: "left" }}>
            <label style={{ fontSize: 12 }}> Magazine Type </label>
            <div style={{ margin: "5px 0px 5px 0px", width: "98%" }}>
              <Select
                value={issueData.selectedMagazine}
                onChange={handleSelectMagazine}
                options={options}
                placeholder="Select Magazine"
                styles={customStyles}
              />
            </div>
          </div>
          <div style={{ width: "98%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}> Issue Number </label>
            <TextInput
              type="text"
              name="issueNumber"
              placeholder="Issue Number"
              value={issueData.issueNumber}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "98%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}> Theme </label>
            <TextInput
              type="text"
              name="theme"
              placeholder="Theme"
              value={issueData.theme}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "98%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}> Description </label>
            <TextInput
              type="text"
              name="description"
              placeholder="Description"
              value={issueData.description}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>

          <div style={{ width: "98%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}>Cover Image</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 5px 15px",
                justifyContent: "space-between",
                margin: "5px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                {!issueData?.coverImage &&
                  issueQueryData?.issue?.coverImage?.url && (
                    <div>
                      <img
                        style={{
                          width: 26,
                          height: "auto",
                          objectFit: "fill",
                          borderRadius: 50,
                          marginTop: 7,
                        }}
                        src={issueQueryData?.issue?.coverImage?.url}
                        alt="issue cover "
                      />
                    </div>
                  )}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  textAlign: "center",
                  padding: "5px 7px 0px 0px",
                }}
              >
                <ImageUploader
                  buttonText="Upload Issue Cover Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={issueData.coverImage}
                  onUpload={handleIssueCoverImageUpload}
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ width: "100%" }}>
              <LoadingButton
                buttonText="Update Issue"
                backgroundColor="#259BD8"
                borderColor="#259BD8"
                textColor="#ffffff"
                onClick={handleUpdateIssue}
                showLoader={true}
                isLoading={loading}
              />
            </div>
          </div>
          <IssuePages issueId={issueId} />
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateIssue;
