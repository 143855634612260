import React from 'react';
import '../../styles/TextInput.css'

const TextInput = ({ type, name, placeholder, value, onFocus, onBlur, onChange, onClick, style, textarea, readOnly }) => {
  if (textarea) {
    return (
      <textarea
        className='input textarea'
        placeholder={placeholder || ''}
        value={value || ''}
        onChange={onChange}
        style={style}
        // readOnly={readOnly}
      />
    );
  }

  return (
    <input 
      className='input'
      type={type || 'text'}
      name={name}
      placeholder={placeholder || ''}
      value={value || ''}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      style={style}
      readOnly={readOnly}
    />
  );
};

export default TextInput;