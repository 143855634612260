import React from "react";
import "../styles/Login.css";
import Simulator from "./Simulator";

const Login = () => {

  return (
    <div className="container">
      <Simulator/>
    </div>
  );
};

export default Login;