import React from 'react';
import Button from './SidebarButton';
import { accountSidebarLinks } from '../../../data/data';
import { deleteAccount } from '../../../data/svg';

const SidebarLinks = ({ setActiveLink, activeLink }) => {
    const totalLinks = accountSidebarLinks.length;

    const handleSetActiveLink = (label) => {
        setActiveLink(label);
    };

    return (
        <div className='sidebarlinks'>
            <div style={{marginBottom: 15}}>
                {accountSidebarLinks.map((link, index) => (
                    <Button
                        key={link.id}
                        buttonText={link.label}
                        textColor={activeLink === link.label ? '#259BD8' : '#606C82'}
                        backgroundColor={activeLink === link.label ? '#F7F8FA' : '#ffffff'} // Change background color when active
                        onClick={() => handleSetActiveLink(link.label)}
                        borderColor="#F1F2F4"
                        // icon={link.icon}
                        icon={
                            <span className={`icon ${activeLink === link.label ? 'active' : ''}`}>
                                {link.icon}
                            </span>
                        }
                        alignLeft={true}
                        isFirstButton={index === 0}
                        isLastButton={index === totalLinks - 1}
                    />
                ))}
            </div>
            {/* <div>
                <Button
                    buttonText='Delete Account'
                    textColor='#ffffff'
                    backgroundColor='#FE435A'
                    borderColor="#F2F2F2"
                    isFirstButton={true}
                    isLastButton={true}
                    alignLeft={true}
                    icon={deleteAccount({})}
                />
            </div> */}
        </div>
    );
};

export default SidebarLinks;