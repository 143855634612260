import { gql } from "@apollo/client";

export const GET_AUTHENTICATED_USER_MUTATION = gql`
  query authenticatedItem {
    authenticatedItem {
      ... on User {
        name
        email
        id
        role {
          id
          name
          canManageEvents
          canManageMagazines
          canSeeOtherPeople
          canEditOtherPeople
          canManagePeople
          canManageRoles
          canUseAdminUI
          canManageBusinessDirectory
          canManagePosts
        }
      }
    }
  }
`;