import React, { useRef, useState } from "react";
import { eventHeaderIcon } from "../../data/svg";
import "../../styles/Modal.css";
import Button from "../Button";

import TextInput from "../TextInput";

import "../../../node_modules/react-calendar-datetime-picker/dist/style.css";
import ImageUploader from "../ImageUploader";

import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EVENT_MUTATION } from "../../graphql/mutations/events.mutation";

import { DocumentEditor, defaultDocumentFeatures } from "keystone-react-editor";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { GET_SPEAKERS_QUERY } from "../../graphql/queries/speakers.query";
import LoadingButton from "../Button/LoadingButton";
import { componentBlocks } from "../ImageComponentBlock/ImageComponentBlock";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "This is a placeholder text " }],
  },
];

const AddEvent = ({ onClose, onSuccess = () => {} }) => {
  const { data: speakerData } = useQuery(GET_SPEAKERS_QUERY, {
    variables: {
      take: 100, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  const [createEvent, { loading }] = useMutation(CREATE_EVENT_MUTATION);
  const [eventData, setEventData] = useState({
    title: "",
    image: null,
    description: [],
    location: "",
    date: "",
    category: "",
    payment_type: "",
    selectedSpeaker: null,
    amount: "",
  });
  const val = useRef(initialValue);

  const handleInputChange = (fieldName, value) => {
    setEventData({
      ...eventData,
      [fieldName]: value,
    });
  };

  const handleImageUpload = (imageData) => {
    setEventData({
      ...eventData,
      image: imageData,
    });
  };

  // const handleFieldChange = (fieldName, value) => {
  //   if (fieldName === "category") {
  //     setEventData({
  //       ...eventData,
  //       [fieldName]: value,
  //     });
  //   } else if (fieldName === "payment_type") {
  //     const defaultAmount = value === "free" ? 0 : parseFloat(eventData.amount);
  //     setEventData({
  //       ...eventData,
  //       [fieldName]: value,
  //       amount: defaultAmount,
  //     });
  //   } else {
  //     setEventData({
  //       ...eventData,
  //       [fieldName]: value,
  //     });
  //   }
  // };

  const handleFieldChange = (fieldName, value) => {
    if (
      fieldName === "category" ||
      fieldName === "payment_type" ||
      fieldName === "speaker"
    ) {
      setEventData({
        ...eventData,
        [fieldName]: value,
      });
    } else {
      setEventData({
        ...eventData,
        [fieldName]: value,
      });
    }
  };

  const handleSelectSpeaker = (selectedOption) => {
    if (selectedOption) {
      setEventData({
        ...eventData,
        selectedSpeaker: selectedOption,
      });
    }
  };

  const handleCreateEvent = async () => {
    try {
      // this is logic is form field validation
      if (!eventData.title || eventData.title.trim() === "") {
        toast.error("Please provide an event title.");
        return;
      }

      if (!eventData.image) {
        toast.error("Please upload an event image.");
        return;
      }

      if (!eventData.location || eventData.location.trim() === "") {
        toast.error("Please provide an event location.");
        return;
      }

      if (!eventData.date) {
        toast.error("Please select an event date.");
        return;
      }

      if (!eventData.category) {
        toast.error("Please select an event category.");
        return;
      }

      if (!eventData.payment_type) {
        toast.error("Please select an event type.");
        return;
      }

      if (
        eventData.payment_type === "paid" &&
        (!eventData.amount || isNaN(parseFloat(eventData.amount)))
      ) {
        toast.error("Please provide a valid event amount for paid events.");
        return;
      }

      if (!eventData.selectedSpeaker) {
        toast.error("Please select an event speaker.");
        return;
      }
      // this is logic is form field validation

      const selectedDate = new Date(eventData.date);
      const isoDate = selectedDate.toISOString();

      // const amountValue = parseFloat(eventData.amount);
      const amountValue =
        eventData.payment_type === "paid" ? parseFloat(eventData.amount) : 0;
      const selectedSpeakerIds = eventData.selectedSpeaker.map((speaker) => ({
        id: speaker.value,
      }));
      console.log({ uploadedDescription: val.current });
      await createEvent({
        variables: {
          input: {
            title: eventData.title,
            image: {
              upload: eventData.image,
            },
            description: val.current,
            location: eventData.location,
            // date: eventData.date,
            date: isoDate,
            category: eventData.category,
            payment_type: eventData.payment_type,
            speakers: {
              // connect: { id: eventData.selectedSpeaker.value },
              connect: selectedSpeakerIds,
            },
            amount: amountValue,
          },
        },
      });
      onSuccess();
      onClose();
      toast.success("Event created successfully!");
    } catch (error) {
      // setIsLoading(false);
      console.error("Error creating event:", error);
      toast.error("Failed to create event.");
    }
  };

  const options =
    speakerData?.speakers.map((speaker) => ({
      value: speaker.id,
      label: speaker.name,
    })) || [];

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    // Check if the selected date is in the past
    // if (selectedDate < currentDate) {
    //   // Alert the user or handle the past date selection as needed
    //   alert("Please select a date in the present or future.");
    //   return;
    // }

    // Update the state only if it's not a past date
    setEventData({
      ...eventData,
      date: selectedDate,
    });
  };

  const eventCategory = [
    { label: "Webexions", value: "webexions" },
    { label: "Connexions", value: "connexions" },
    { label: "Awards", value: "awards" },
  ];
  const eventType = [
    { label: "Paid", value: "paid" },
    { label: "Free", value: "free" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
      border: "1px solid #E1E6EF",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
      position: "relative",
    }),
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        {/* <div style={{}}>
          <span className="modal-close" onClick={onClose}>
            {close({ width: 10, height: 10, color: "#28303F" })}
          </span>
        </div> */}
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Add Event</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                Enter the event data you’ll like to create.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%" }}>
            <TextInput
              type="text"
              placeholder="Event Title"
              value={eventData.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              style={{ width: "93.5%" }}
            />
          </div>
          <div style={{ width: "100%", marginTop: 5 }}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "10px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                width: "96%",
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                Event Image
              </span>
              <div>
                <ImageUploader
                  buttonText="Upload Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={eventData.image}
                  onUpload={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", maxWidthx: "500px", marginTop: 15 }}>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                border: "1px solid #E1E6EF",
                margin: "10px 0px",
                width: "91%",
              }}
            >
              <DocumentEditor
                key="edito"
                onChange={(document) => {
                  console.log({ updatedDoc: document });
                  val.current = document;
                }}
                initialValue={val.current}
                componentBlocks={componentBlocks}
                documentFeatures={defaultDocumentFeatures}
              />
            </div>
          </div>
          <div style={{ width: "99%" }}>
            <TextInput
              type="text"
              placeholder="Event Location"
              value={eventData.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
              style={{ width: "95%" }}
            />
          </div>
          <div
            style={{
              width: "98%",
              display: "flex",
              gap: 10,
              height: 50,
              marginTop: 5,
            }}
          >
            {/* <div style={{marginBottom: 0, width: '100%'}}> */}
            <TextInput
              type="date"
              placeholder="Event Date"
              value={eventData.date}
              onChange={handleDateChange}
              style={{ width: "95%" }}
            />
            {/* </div> */}
            <div style={{ marginTop: 5, width: "98%" }}>
              <Select
                options={eventCategory}
                value={eventCategory.find(
                  (cat) => cat.value === eventData.category
                )}
                placeholder="Event Category"
                onChange={(selectedOption) =>
                  handleFieldChange("category", selectedOption.value)
                }
                styles={customStyles}
              />
            </div>
          </div>

          <div
            style={{ width: "100%", display: "flex", gap: 10, marginTop: 5 }}
          >
            <div style={{ width: "98.5%", marginTop: 5 }}>
              <Select
                options={eventType}
                value={eventType.find(
                  (type) => type.value === eventData.payment_type
                )}
                placeholder="Event Type"
                onChange={(selectedOption) =>
                  handleFieldChange("payment_type", selectedOption.value)
                }
                styles={customStyles}
              />
            </div>
            {eventData.payment_type === "paid" && (
              <TextInput
                type="text"
                placeholder="Amount"
                value={eventData.amount}
                onChange={(e) => handleInputChange("amount", e.target.value)}
                style={{ width: "100%" }}
              />
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div style={{ width: "98.5%", marginTop: 5 }}>
              <Select
                options={options}
                value={options.find(
                  (opt) => opt.value === eventData.selectedSpeaker?.value
                )}
                placeholder="Event Speaker"
                onChange={handleSelectSpeaker}
                isMulti
                // onChange={(selectedOption) =>
                //   handleFieldChange('speaker', selectedOption.speaker)
                // }
                styles={customStyles}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Add Event"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateEvent}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
