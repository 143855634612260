import React from "react";
import Button from "../../../Button";
import { update, trash } from "../../../../data/svg";

function IssuePageBox({ id, url, page_no, onDelete, onUpdate, isLoading }) {
  const fileInputref = React.useRef(null);

  const handleUpdate = (file) => {
    onUpdate(id, file);
  };

  const handleDelete = () => {
    onDelete(id, page_no);
  };

  return (
    <div className="image-card">
      <div className="left">
        <img src={url} alt={`page-${page_no}`} className="uploaded-image" />
        <input
          className="sr-only"
          type="file"
          accept="image/png, image/jpeg"
          ref={fileInputref}
          onChange={(e) => handleUpdate(e.target.files[0])}
        />
      </div>
      <div className="right">
        <span className="top">{`Page ${page_no}`}</span>
        <div className="bottom">
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <>
              <Button
                icon={update({})}
                backgroundColor="#ffffff"
                borderColor="#ffffff"
                onClick={() => fileInputref.current?.click()}
              />
              <Button
                icon={trash({})}
                backgroundColor="#ffffff"
                borderColor="#ffffff"
                onClick={handleDelete}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

IssuePageBox.propTypes = {};

export default IssuePageBox;
