import React from "react";
import ImageUploadPreview from "./ImageUploadPreview";

export const image = ({ listKey }) => {
  return {
    kind: "form",
    Input({ value, onChange }) {
      return (
        <ImageUploadPreview
          listKey={listKey}
          defaultValue={value}
          mode="edit"
          onChange={onChange}
        />
      );
    },
    options: { listKey },
    defaultValue: null,
    validate(value) {
      return typeof value === "object";
    },
  };
};
