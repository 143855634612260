import React from "react";
import NewsComponent from "../components/News";

const News = () => {
  return (
    <main className="screen">
      <NewsComponent />
    </main>
  );
};

export default News;
