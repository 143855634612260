import { gql } from "@apollo/client";

export const GET_EVENTS_PAGE_QUERY = gql`
  query eventsPage($take: Int!, $skip: Int!, $orderBy: [EventOrderByInput!]) {
    eventsCount: eventsCount
    freeEventsCount: eventsCount(where: { payment_type: { equals: free } })
    paidEventsCount: eventsCount(where: { payment_type: { equals: paid } })
    webexionsCount: eventsCount(where: { category: { equals: webexions } })
    connexionsCount: eventsCount(where: { category: { equals: connexions } })
    speakersCount
    events(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      amount
      category
      created_at
      location
      date
      payment_type
      description{
        document
      }
      title
      image {
        url
      }
      speakers {
        id
        name
        image {
          url
        }
      }
    }
  }
`;

export const GET_TOTAL_COUNT_QUERY_WITHOUT_PAGINATION = gql`
  query eventsPage {
    eventsCount: eventsCount
    freeEventsCount: eventsCount(where: { payment_type: { equals: free } })
    paidEventsCount: eventsCount(where: { payment_type: { equals: paid } })
    webexionsCount: eventsCount(where: { category: { equals: webexions } })
    connexionsCount: eventsCount(where: { category: { equals: connexions } })
    speakersCount
  }
`;

export const GET_EVENT_QUERY = gql`
  query GetEvent($eventId: ID!) {
    event(where: { id: $eventId }) {
      id
      title
      image {
        url
        # Other image properties if available
      }
      category
      payment_type
      description {
        document
      }
      speakers {
        id
        name
        image {
          url
        }
      }
      location
      date
      amount
    }
  }
`;