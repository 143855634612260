import React from "react";
import Button from "../../../components/Button";
import "../../../styles/Modal.css";
import { trashHeaderIcon } from "../../../data/svg";
import { useMutation } from "@apollo/client";
import { DELETE_ISSUE_MUTATION } from "../../../graphql/mutations/issues.mutation";
import LoadingButton from "../../Button/LoadingButton";
import { toast } from 'react-hot-toast';

const TrashIssue = ({ onClose, issueId, issueNumber, refetchIssues }) => {
  const [deleteIssue, {loading}] = useMutation(DELETE_ISSUE_MUTATION);

  const handleDelete = async () => {
    try {
      const { data } = await deleteIssue({
        variables: {
          where: { id: issueId },
        },
      });

      if (data && data.deleteIssue) {
        toast.success(`Issue "${issueNumber}" has been deleted successfully!`);

        onClose();

        // Reload issues table to fetch updated data
        refetchIssues();

        console.log(`Issue with ID ${data.deleteIssue.id} has been deleted.`);
      }
    } catch (error) {
      console.error('Error deleting issue:', error);
      toast.error('An error occurred while deleting the issue.')
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header" style={{border: 'none'}}>
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {trashHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Trash Issue</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Are you sure you want to delete this <b><i> Issue #{issueNumber}</i></b></span>
              <br/>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>⚠️ P.S: This action is irreversible ⚠️</span>
            </span>
          </div>
        </div>
        
        <div className="modal-footer" stylee={{border: 'none'}}>
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Delete Issue"
              backgroundColor="#FE435A"
              borderColor="#FE435A"
              textColor="#ffffff"
              onClick={handleDelete}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Cancel"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrashIssue;