import { gql } from "@apollo/client";

export const GET_MAGAZINES_QUERY = gql`
  query magazinePageData {
    magazinesCount: magazinesCount
    magazinesCount
    magazines {
      id
      name
      issuesCount
      created_at
    }
    issues {
      id
      theme
      label
      publishedAt
      issueNumber
      coverImage {
        url
      }
      magazine {
        name
      }
      pagesCount
    }
    
    issuesCount
  }
`;

export const GET_MAGAZINE_QUERY = gql`
query Magazine($where: MagazineWhereUniqueInput!) {
  magazine(where: $where) {
      id
      name
      logo {
        url
      }
    }
  }
`;

export const GET_MAGAZINES_BASIC_INFO_QUERY = gql`
  query magazines {
    magazines {
      id
      name
    }
  }
`;

export const GET_ISSUES_QUERY = gql`
  query issuePageData($take: Int!, $skip: Int!, $orderBy: [IssueOrderByInput!]) {
    issuesCount: issuesCount
    issues(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      theme
      label
      publishedAt
      createdAt
      issueNumber
      coverImage {
        url
      }
      magazine {
        name
      }
      pagesCount
    }
  }
`;

export const GET_ISSUE_QUERY = gql`
  query Issue($where: IssueWhereUniqueInput!, $orderBy: [IssuePageOrderByInput!]!) {
    issue(where: $where) {
      id
      issueNumber
      coverImage {
        url
      }
      description
      draft
      label
      magazine {
        id
        name
      }
      pages(orderBy: $orderBy) {
        id
        image {
          url
        }
        issue {
          id
        }
      }
      pagesCount
      createdAt
      publicationDate
      publishedAt
      theme
      updated_at
    }
  }
`;