import React, { useState } from "react";
import Button from "../../Button";
import "../../../styles/Modal.css";
import { userHeaderIcon } from "../../../data/svg";
import TextInput from "../../TextInput";
import { useQuery } from "@apollo/client";
import { GET_SPEAKER_QUERY } from "../../../graphql/queries/speakers.query";
import Loader from "../../Loader";

const ViewSpeaker = ({ onClose, speakerId }) => {
  
  const { loading, error, data } = useQuery(GET_SPEAKER_QUERY, {
    variables: { 
      where: { id: speakerId}
     },
  });

  if (loading) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View Speaker</span>
                <br />
                <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                  This is a read-only speaker data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !data || !data.speaker) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View Speaker</span>
                <br />
                <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                  This is a read-only speaker data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <span>Error fetching data</span>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const speakerData = data.speaker;

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>View Speaker</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-only speaker data.
              </span>
            </span>
          </div>
        </div>

        <div className="modal-body">
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div>
              <img
                src={speakerData?.image?.url}
                alt="Speaker Image"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Speaker Name</label>
            <TextInput
              type="text"
              placeholder="Speaker Name"
              value={speakerData.name}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Speaker Bio</label>
            <TextInput
              textarea
              // type="text"
              placeholder="About Speaker"
              value={speakerData.about}
              style={{
                width: "95%",
                height: "100% !important",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
          <div style={{ width: "98%", display: "flex", gap: 10 }}></div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSpeaker;