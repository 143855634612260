import React from "react";
import "../../../styles/Login.css";
import Simulator from "./ForgotPassword";

const ForgotPassword = () => {

  return (
    <div className="container">
      <Simulator/>
    </div>
  );
};

export default ForgotPassword;