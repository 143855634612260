import React from "react";
import ForgotPasswordComponent from "../components/Auth/ForgotPassword";
import '../styles/Login.css'

const ForgotPassword = () => {
  return (
    <main className="background">
      <ForgotPasswordComponent />
    </main>
  );
};

export default ForgotPassword;