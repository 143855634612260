import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  REDEEM_PASSWORD_MUTATION,
  SEND_PASSWORD_RESET_LINK_MUTATION,
} from "../../../graphql/mutations/auth.mutation";
import "../../../styles/Button.css";
import "../../../styles/Simulator.css";
import Button from "../../Button/LoadingButton";
import Wrapper from "./Wrapper";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [sendPasswordResetLink, { loading }] = useMutation(
    SEND_PASSWORD_RESET_LINK_MUTATION
  );

  const handleEmailSubmit = async () => {
    try {
      // Call the mutation to send the password reset link
      await sendPasswordResetLink({ variables: { email } });
      setActiveStep(1); // Move to the next step after sending the email
      toast.success("Password reset email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div style={{ marginTop: 30 }}>
            <div style={{ textAlign: "left", padding: "30px 0px" }}>
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                Enter your email
              </span>
              <br />
              <span style={{ fontSize: 12 }}>
                A reset link will be sent here.
              </span>
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <Button
              onClick={handleEmailSubmit}
              buttonText="Submit"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              isFirstButton={true}
              isLastButton={true}
              isLoading={loading}
            />
          </div>
        );
      case 1:
        return (
          <div style={{ marginTop: 30 }}>
            <div style={{ textAlign: "center", padding: "30px 0px" }}>
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                Reset Link Sent
              </span>
              <br />
              <span style={{ fontSize: 12 }}>
                A reset link will has been sent to <b>{email}</b>, if you
                account exists,you shoiuld receive an email shortly.
              </span>
            </div>
            <Button
              onClick={() => navigate("/")}
              buttonText="Back To Login"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              isFirstButton={true}
              isLastButton={true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return <Wrapper>{renderStepContent()}</Wrapper>;
};

export const RedeemPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [redeemPasswordResetToken, { loading, error }] = useMutation(
    REDEEM_PASSWORD_MUTATION
  );
  const [searchParams] = useSearchParams();
  const [email] = useState(decodeURIComponent(searchParams.get("email")));
  const [token] = useState(searchParams.get("code"));

  const handlePasswordSubmit = async () => {
    // Logic to submit new password and complete the process
    try {
      await redeemPasswordResetToken({
        variables: {
          email,
          token,
          password,
        },
      });
      setActiveStep(1);
      toast.success("Password reset email sent successful");
    } catch (error) {
      // Handle error (display error message to user)
      console.error("Password reset error:", error);
      toast.error("Password reset failed");
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div style={{ marginTop: 30 }}>
            <div style={{ textAlign: "left", padding: "30px 0px" }}>
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                Enter your new password
              </span>
              <br />
              <span style={{ fontSize: 12 }}>
                password must be 8 digits or more.
              </span>
            </div>
            <div className="input-container">
              <input
                type="password"
                placeholder="New password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button
              onClick={handlePasswordSubmit}
              buttonText="Reset Password"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              isFirstButton={true}
              isLastButton={true}
              isLoading={loading}
            />
            {error && <p>Error: {error.message}</p>}
          </div>
        );
      case 1:
        return (
          <div style={{ marginTop: 30 }}>
            <div style={{ textAlign: "center", padding: "30px 0px" }}>
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                Password Reset Successful
              </span>
              <br />
              <span style={{ fontSize: 12 }}>
                You can now login with your new password.
              </span>
            </div>
            <Button
              onClick={() => navigate("/")}
              buttonText="Proceed to Login"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              isFirstButton={true}
              isLastButton={true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return <Wrapper>{renderStepContent()}</Wrapper>;
};
export default ForgotPassword;
