import React, { useState, useEffect } from 'react';
import Button from '../../Button';
import '../../../styles/Modal.css';
import { close, userHeaderIcon } from '../../../data/svg';
import TextInput from '../../TextInput';
import ImageUploader from '../../ImageUploader';
import { UPDATE_AUTHOR_MUTATION } from '../../../graphql/mutations/author.mutation';
import { GET_AUTHOR_QUERY } from "../../../graphql/queries/author.query";
import { useMutation, useQuery } from '@apollo/client';
import LoadingButton from '../../Button/LoadingButton';
import { toast } from 'react-hot-toast';
import Loader from '../../Loader';

const UpdateAuthor = ({ onClose, authorId }) => {
  
  const [updateAuthor, { loading }] = useMutation(UPDATE_AUTHOR_MUTATION);
  
  const { data: authorData, loading: fetchLoading, error } = useQuery(GET_AUTHOR_QUERY, {
    variables: {
      where: { id: authorId },
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });
  
  const [authorDetails, setAuthorDetails] = useState({
    name: '',
    image: null,
  });

  useEffect(() => {
    if (!fetchLoading && authorData) {
      setAuthorDetails({
        ...authorDetails,
        name: authorData.author.name,
        image: null,
      });
    }
  }, [fetchLoading, authorData]);

  const handleInputChange = (fieldName, value) => {
    setAuthorDetails({
      ...authorDetails,
      [fieldName]: value,
    });
  };

  const handleAuthorImageUpload = (image) => {
    setAuthorDetails({ 
      ...authorDetails, 
      image 
    });
  };

  const handleUpdateAuthor = async () => {
    try {
      const updatedAuthor = await updateAuthor({
        variables: {
          authorId,
          input: {
            name: authorDetails.name,
            image: authorDetails.image ? { upload: authorDetails.image } : null
          }
        },
      });

      if (updatedAuthor?.data?.updateAuthor) {
        onClose();
        toast.success("Author updated successfully!");
      }
    } catch (error) {
      console.error('Error Creating Author:', error);
      toast.error("Failed to update author");
    }
  };

  if (fetchLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Author</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit author data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              <Loader showCaption={true}/>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Author</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit author data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              Error fetching data: {error.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className='modal-center'>
        {/* <div className="modal-h#eader">
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            Add Author
          </span>
          <span className="modal-close" onClick={onClose}>
            {close({ width: 10, height: 10, color: '#28303F' })}
          </span>
        </div> */}
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Update Author</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Enter the author's data you’ll like to create.</span>
            </span>
          </div>
        </div>
        <div className='modal-body'>
          <div style={{width: '100%', textAlign: 'left'}}>
            <label style={{fontSize: 12}}>Author Name</label>
            <TextInput
              type="text"
              name="name"
              placeholder="Author Name"
              value={authorDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              style={{width: '95%'}}
            />
          </div>
          <div style={{ width: "99%", marginTop: 10, textAlign: 'left', }}>
            <label style={{fontSize: 12}}>Author Image</label>
            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', padding: '5px 0px 5px 5px', justifyContent: 'space-between', margin: '10px 0px', border:'1px solid #E1E6EF', borderRadius: 30 }}>
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                {authorData.author?.image && (
                  <img
                    src={authorData?.author?.image?.url}
                    style={{ width: 36, height: 36, borderRadius: 50, marginTop: 2 }}
                  />
                )}
              </span>
              <div style={{display: "flex", alignItems: "center", gap: 10, textAlign: "center", padding: '0px 6px 0px 0px'}}>
                <ImageUploader
                  buttonText="Author Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={authorDetails?.image}
                  onUpload={handleAuthorImageUpload}
                />
              </div>
            </div>
          </div>

          {/* <div style={{ width: "99%", marginTop: 10, textAlign: 'left', }}>
            <label style={{fontSize: 12}}>Author Image</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "5px 0px 5px 5px",
                justifyContent: "space-between",
                margin: "5px 0",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                {authorData.author?.image && (
                  <img
                    src={authorData?.author?.image?.url}
                    style={{ width: 36, height: 36, borderRadius: 50, marginTop: 2 }}
                  />
                )}
              </span>
              <div style={{display: "flex", alignItems: "center", gap: 10, textAlign: "center", padding: '0px 6px 0px 0px'}}>
                
                <ImageUploader
                  buttonText="Upload Author Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  file={authorDetails?.image}
                  textColor="#ffffff"
                  onUpload={handleAuthorImageUpload}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className='modal-footer'>
          <div style={{width: '100%'}}>
            <LoadingButton
              buttonText="Update Author"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleUpdateAuthor}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAuthor;