import { gql } from '@apollo/client';

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($input: RoleCreateInput!) {
    createRole(data: $input) {
      id
      name
      # Include other fields you want to retrieve after creation
      # Example: canManagePosts, canManageEvents, etc.
    }
  }
`;

export const UPDATE_ROLE_MUTATION = gql`
mutation updateRole($roleId: ID!, $input: RoleUpdateInput!) {
  updateRole(where: { id: $roleId }, data: $input) {
      id
      name
    }
  }
`;

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($where: RoleWhereUniqueInput!) {
    deleteRole(where: $where) {
      id
      name
    }
  }
`;