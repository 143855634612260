import { gql } from "@apollo/client";

export const GET_AUTHORS_QUERY = gql`
  query authorList($take: Int, $skip: Int, $orderBy: [AuthorOrderByInput!]) {
    authorsCount: authorsCount
    authors(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
      image {
        url
      }
    }
  }
`;

export const GET_AUTHOR_QUERY = gql`
  query Author($where: AuthorWhereUniqueInput!) {
    author(where: $where) {
      id
      name
      image {
        url
      }
    }
  }
`;