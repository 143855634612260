import React from "react";
import Loader from "../../Loader/Button";
import Button from "../index";

const LoadingButton = ({
  buttonText,
  onClick,
  isLoading,
  loaderPadding,
  ...otherProps
}) => {
  const loaderStyle = {
    padding: loaderPadding || 0, // Override the padding for the Loader
    width: "10px",
    height: "10px",
  };

  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      buttonText={
        isLoading ? (
          <Loader style={loaderStyle} showCaption={false} />
        ) : (
          buttonText
        )
      }
      loaderPadding="0"
      {...otherProps}
    />
  );
};

export default LoadingButton;
