import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { imageUpload, trash, update } from "../../../data/svg";
import "../../../styles/ImageCard.css";
import Button from "../../Button";

const ImageCard = ({ images = [], setImages }) => {
  const previews = useMemo(
    () =>
      images.map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
      })),
    [images]
  );
  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages([...images, ...acceptedFiles]);
    },
    [images, setImages]
  );

  // logic for deleting an image
  const handleDelete = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  // logic for editing an image, e.g., opening a modal to edit
  const handleEdit = (index) => {
    // console.log(`Editing image at index ${index}`);
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png";
    fileInput.onchange = (e) => {
      const newImageFile = e.target.files[0];
      if (newImageFile) {
        const updatedImages = images.map((image, i) => {
          if (i === index) {
            return {
              url: URL.createObjectURL(newImageFile),
              name: newImageFile.name,
            };
          }
          return image;
        });
        setImages(updatedImages);
      }
    };
    fileInput.click();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/png",
  });

  return (
    <div
      style={{
        width: "91%",
        border: "1px solid #dedddd",
        padding: 20,
        borderRadius: 20,
        marginTop: 10
      }}
    >
      <div
        className="image-grid"
        stylex={{ display: "flex", justifyContent: "space-between" }}
      >
        {previews.map((image, index) => (
          <div key={index} className="image-card">
            <div className="left">
              <img
                src={image.url}
                alt={image.name}
                className="uploaded-image"
              />
            </div>
            <div className="right">
              <span className="top">{`Page ${index + 1}`}</span>
              <div className="bottom">
                <Button
                  icon={update({})}
                  backgroundColor="#ffffff"
                  borderColor="#ffffff"
                  onClick={() => handleEdit(index)}
                />
                <Button
                  icon={trash({})}
                  backgroundColor="#ffffff"
                  borderColor="#ffffff"
                  onClick={() => handleDelete(index)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div {...getRootProps()} style={dropzoneStyles} className="upload-image">
        <input {...getInputProps()} />
        {imageUpload({})}
        {isDragActive ? (
          <p className="caption">Drop the files here...</p>
        ) : (
          <p className="caption">
            Drag & drop magazine images here, or click to select files
            <br />
            <b>supported format *png*</b>
          </p>
        )}
      </div>
    </div>
  );
};

const dropzoneStyles = {
  // border: '2px dashed #ccc',
  borderRadius: "20px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default ImageCard;
