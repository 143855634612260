import { useQuery } from "@apollo/client";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { format } from "date-fns";
import React from "react";
import { eventHeaderIcon } from "../../data/svg";
import { GET_EVENT_QUERY } from "../../graphql/queries/events.query";
import "../../styles/Modal.css";
import Button from "../Button";
import Loader from "../Loader";
import TextInput from "../TextInput";

const ViewEvent = ({ onClose, eventId, descriptionData }) => {
  // console.log("check events id:", eventId)
  const { loading, error, data } = useQuery(GET_EVENT_QUERY, {
    variables: { eventId },
  });

  if (loading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View Event</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only event data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !data || !data.event) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View Event</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only event data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const eventData = data.event;
  console.log(eventData);

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>View Event</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-only event data.
              </span>
            </span>
          </div>
        </div>

        <div className="modal-body" keyxx={eventData.eventId}>
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div>
              <img
                src={eventData?.image?.url}
                alt="Event Banner"
                style={{
                  width: "100%",
                  height: 150,
                  objectFit: "cover",
                  // borderRadius: "50%",
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Title</label>
            <TextInput
              type="text"
              placeholder="Event Title"
              value={eventData.title}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
          {/* <div style={{ width: "100%", marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "5px 0",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                backgroundColor: '#F5F5F5'
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                Event Image
              </span>
              <div>
                <img
                  src={eventData?.image?.url}
                  alt="Event Image"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </div>
            </div>
          </div> */}
          <div
            style={{
              width: "100%",
              textAlign: "left",
              maxWidthx: "500px",
              marginBottom: 10,
            }}
          >
            <label style={{ fontSize: 12 }}>Event Description</label>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
                margin: "5px 0px",
              }}
            >
              <DocumentRenderer
                componentBlocks={{
                  image: (props) => {
                    return (
                      <div>
                        {" "}
                        <img
                          src={props?.image?.url}
                          alt={props?.alt}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                    );
                  },
                }}
                document={eventData?.description?.document || []}
              />
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Location</label>
            <TextInput
              type="text"
              placeholder="Event Location"
              value={eventData.location}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
          {/* <div style={{ width: "100%", display: "flex", gap: 10, height: 50 }}> */}
          <div
            style={{
              textAlign: "left",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <label style={{ fontSize: 12 }}>Event Date</label>
            <TextInput
              type="date"
              placeholder="Event Date"
              // value={eventData.date}
              value={format(new Date(eventData.date), "yyyy-MM-dd")}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
          {/* </div> */}
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Category</label>
            <TextInput
              type="text"
              placeholder="Event Category"
              value={eventData.category}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              gap: 10,
              marginBottom: 10,
            }}
          >
            <div style={{ textAlign: "left", marginRight: 22, width: "45%" }}>
              <label style={{ fontSize: 12 }}>Event Type</label>
              <TextInput
                type="text"
                placeholder="Event Type"
                value={eventData.payment_type}
                style={{
                  width: "100%",
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #E1E6EF",
                }}
              />
            </div>
            {eventData.payment_type === "paid" && (
              <div style={{ textAlign: "left", width: "45%" }}>
                <label style={{ fontSize: 12 }}>Amount</label>
                <TextInput
                  type="text"
                  placeholder="Amount"
                  value={eventData.amount}
                  style={{
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #E1E6EF",
                  }}
                />
              </div>
            )}
          </div>
          <div style={{ width: "100%", textAlign: "left", marginTop: 5 }}>
            <label style={{ fontSize: 12 }}>Speaker(s)</label>
            <TextInput
              type="text"
              placeholder="Event Speaker(s)"
              value={eventData.speakers
                .map((speaker) => speaker.name)
                .join(", ")}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>

          <div style={{ width: "98%", display: "flex", gap: 10 }}></div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
