import React from "react";
import EventComponent from "../components/Events";

const Events = () => {
  return (
    <main className="screen">
      <EventComponent />
    </main>
  );
};

export default Events;
