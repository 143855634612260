import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";

const styles = {
  container: {
    display: "flex",
    position: "absolute",
    margin: "auto",
    top: 10,
    left: 0,
    right: 0,
    height: 30,
    width: 100,
    backgroundColor: "#151515",
    borderRadius: 30,
  },
  containerr: {
    display: "flex",
    position: "absolute",
    margin: "auto",
    top: 170,
    left: 320,
    right: 0,
    height: 90,
    width: 10,
    backgroundColor: "#151515",
    borderRadius: 30,
  },
  containerrr: {
    display: "flex",
    position: "absolute",
    margin: "auto",
    top: 90,
    left: -340,
    right: 0,
    height: 30,
    width: 10,
    backgroundColor: "#151515",
    borderRadius: 30,
  },
  containerrrr: {
    display: "flex",
    position: "absolute",
    margin: "auto",
    top: 150,
    left: -340,
    right: 0,
    height: 60,
    width: 10,
    backgroundColor: "#151515",
    borderRadius: 30,
  },
  containerrrrr: {
    display: "flex",
    position: "absolute",
    margin: "auto",
    top: 220,
    left: -340,
    right: 0,
    height: 60,
    width: 10,
    backgroundColor: "#151515",
    borderRadius: 30,
  },
};

const Wrapper = ({ children }) => {
  return (
    <div className="login-simulator">
      <div style={styles.container} />
      <div style={styles.containerr} />
      <div style={styles.containerrr} />
      <div style={styles.containerrrr} />
      <div style={styles.containerrrrr} />
      <img style={{ marginTop: 10 }} width="60" src={logo} alt="" />
      <div>{children}</div>
      <div>
        <Link to="/" style={{ textDecoration: "none" }}>
          <span style={{ fontSize: 10, color: "#151515" }}>
            Remember your Password? Go back to Login
          </span>
        </Link>
      </div>
      {/* </div> */}

      <div
        className="version"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <span style={{ fontSize: 10, fontWeight: 500 }}>version 25(0.0.1)</span>
      </div>
    </div>
  );
};

export default Wrapper;
