import React, { useState } from "react";
import Button from "../../Button";
import "../../../styles/Modal.css";
import { userHeaderIcon } from "../../../data/svg";

import TextInput from "../../TextInput";
// import "../../../node_modules/react-calendar-datetime-picker/dist/style.css";
import ImageUploader from "../../ImageUploader";

import { useMutation, useQuery } from "@apollo/client";

import LoadingButton from "../../Button/LoadingButton";
import { toast } from "react-hot-toast";
import { CREATE_SPEAKER_MUTATION } from "../../../graphql/mutations/speakers.mutation";
import { GET_EVENTS_PAGE_QUERY } from "../../../graphql/queries/events.query";

const AddSpeaker = ({ onClose }) => {
  const { refetch: refetchEvents } = useQuery(GET_EVENTS_PAGE_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  const [createSpeaker, { loading }] = useMutation(CREATE_SPEAKER_MUTATION);
  const [speakerData, setSpeakerData] = useState({
    name: "",
    image: null,
    about: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpeakerData({
      ...speakerData,
      [name]: value,
    });
  };

  const handleImageUpload = (image) => {
    setSpeakerData({
      ...speakerData,
      image,
    });
  };

  const handleCreateSpeaker = async () => {
    try {
      const { data } = await createSpeaker({
        variables: {
          data: {
            name: speakerData.name,
            image: {
              upload: speakerData.image,
            },
            about: speakerData.about
          },
        },
      });

      onClose();
      refetchEvents();
      toast.success("Speaker created successfully!");
      console.log("Created speaker:", data.createSpeaker);
    } catch (error) {
      // setIsLoading(false);
      console.error("Error creating speaker:", error);
      toast.error("Failed to create speaker.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Add Speaker</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                Enter the speaker data you’ll like to create.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%" }}>
            <TextInput
              type="text"
              placeholder="Speaker Name"
              name="name"
              value={speakerData.name}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "100%", marginTop: 5 }}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "10px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                width: "98%",
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                Speaker Image
              </span>
              <div>
                <ImageUploader
                  buttonText="Upload Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={speakerData.image}
                  onUpload={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <TextInput
              // textarea
              type="text"
              placeholder="Speaker Bio"
              name="about"
              value={speakerData.about}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Add Speaker"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateSpeaker}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpeaker;
