import React from 'react';
import Loader from '../Loader';

const DataStatsCard = ({ title, value, loading }) => {
  return (
    <div style={styles.container}>
      <div style={{ fontSize: 14 }}>
        <span>{title}</span>
      </div>
      <div style={{ fontWeight: 600, fontSize: 24, marginTop: 10 }}>
      {loading ? (
        <Loader showCaption={false}/>
      ) : (
        <span>{value}</span>
      )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#F9F9F9',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 20,
    margin: 5,
    border: '1px solid #F2F2F2',
    padding: 20,
  },
};

export default DataStatsCard;