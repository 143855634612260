import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/Dropdown.css";
import "../../styles/Navbar.css";

import jaulilogo from "../../assets/logo.png";
import {
  arrowDown,
  dashboard,
  event,
  magazine,
  news,
  user,
} from "../../data/svg";
import { useStore } from "../AppContext/AppContext";
import Button from "../Button";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [userInfo] = useStore((store) => store.user);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };

  // const handleDownloadApp = () => {
  //   navigate('https://apps.apple.com/gb/app/biz4biz/id6451197256')
  // }

  return (
    <>
      <div className="navbar">
        <div className="navbar-logo">
          <Link to="/" className="nav-logo">
            <img src={jaulilogo} alt="Logo" />
          </Link>
        </div>
        <div className="navbar-links">
          <NavLink
            className="navbar-links"
            to="/dashboard"
            activeClassName="active"
          >
            {dashboard({ width: 10, height: 10, color: "#28303F" })}
            {/* <div className="navbar-content">
              {dashboard({ width: 10, height: 10, color: "#28303F" })}
              <span className="nav-text">Dashboard</span>
            </div> */}
          </NavLink>
          <NavLink
            className="navbar-links"
            to="/events"
            activeClassName="active"
          >
            {event({ width: 10, height: 10, color: "#28303F" })}
            {/* <div className="navbar-content">
              {event({ width: 10, height: 10, color: "#28303F" })}
              <span className="nav-text">Events</span>
            </div> */}
          </NavLink>
          {/* <NavLink className="navbar-links" to="/awards" activeClassName="active">
            {award({ width: 10, height: 10, color: '#28303F' })}
          </NavLink> */}
          <NavLink
            className="navbar-links"
            to="/magazine"
            activeClassName="active"
          >
            {magazine({ width: 10, height: 10, color: "#28303F" })}
          </NavLink>
          <NavLink className="navbar-links" to="/news" activeClassName="active">
            {news({ width: 10, height: 10, color: "#28303F" })}
          </NavLink>
          <NavLink
            className="navbar-links"
            to="/users"
            activeClassName="active"
          >
            {user({ width: 10, height: 10, color: "#28303F" })}
          </NavLink>
        </div>
        <div style={{ position: "relative" }}>
          <div className="navbar-user" onClick={toggleDropdown}>
            <div
              style={{
                backgroundColor: "#151515",
                width: 50,
                height: 50,
                borderRadius: 50,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 14 18"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8ZM7 18C10.866 18 14 16.2091 14 14C14 11.7909 10.866 10 7 10C3.13401 10 0 11.7909 0 14C0 16.2091 3.13401 18 7 18Z"
                  fill="white"
                />
              </svg>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{userInfo?.role?.name}</span>
              <span style={{ fontSize: 12 }}>{userInfo?.email}</span>
            </div>
            {arrowDown({})}
          </div>
          {isDropdownOpen && (
            <div className="dropdown">
              <div>
                {/* <div className="dropdown-list">
                <Button
                  buttonText="Download App"
                  backgroundColor="#F9F9F9"
                  borderColor="#F2F2F2"
                  textColor="#151515"
                  onClick={handleDownloadApp}
                  isFirstButton={true}
                  isLastButton={true}
                />
              </div> */}
                {/* <div className="dropdown-list">
                <Button
                  buttonText="Account Settings"
                  backgroundColor="#F9F9F9"
                  borderColor="#F2F2F2"
                  textColor="#151515"
                  onClick={handleAccount}
                  isFirstButton={true}
                  isLastButton={true}
                />
              </div> */}
                <div className="dropdown-list">
                  <Button
                    buttonText="Log Out"
                    backgroundColor="#F9F9F9"
                    borderColor="#F2F2F2"
                    textColor="#151515"
                    onClick={handleLogout}
                    isFirstButton={true}
                    isLastButton={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
