import React from 'react';
import Profile from './content/Profile';
import Password from './content/Password';
import Notifications from './content/Notifications';
import Roles from './content/Roles';
import { macintosh } from '../../../data/svg';

const SidebarContent = ({ selectedLink }) => {
  const contentMap = {
    Profile: <Profile/>,
    Password: <Password/>,
    // Notifications: <Notifications/>,
    // Roles: <Roles/>
  };

  return (
    <div className="content" style={{justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
      {
        contentMap[selectedLink] || 
        <div style={{padding: '90px'}}>
          <div style={{marginBottom: 10}}>{macintosh({})}</div>
          <div><span style={{fontSize: 12}}>Select a setting from the sidebar</span></div>
        </div>
      }
    </div>
  );
};

export default SidebarContent;