import React, { useState, useEffect } from "react";
import { eventHeaderIcon } from "../../data/svg";
import "../../styles/Modal.css";
import Button from "../Button";

import ImageUploader from "../ImageUploader";
import TextInput from "../TextInput";

import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_MAGAZINE_MUTATION } from "../../graphql/mutations/magazine.mutation";
import { GET_MAGAZINE_QUERY } from "../../graphql/queries/magazine.query";

import { toast } from 'react-hot-toast';
import LoadingButton from "../Button/LoadingButton";
import Loader from "../Loader";

const UpdateMagazine = ({ onClose, magazineId }) => {
  console.log("this is magazine id:", magazineId)

  const [updateMagazine, { loading }] = useMutation(UPDATE_MAGAZINE_MUTATION);
  const { data: magazineData, loading: fetchLoading, error } = useQuery(GET_MAGAZINE_QUERY, {
    variables: {
      where: { id: magazineId },
    },
  });

  const [magazineInfo, setMagazineInfo] = useState({
    name: "",
    logo: null,
  });

  useEffect(() => {
    if (!fetchLoading && magazineData) {
      setMagazineInfo({
        ...magazineInfo,
        name: magazineData.magazine.name,
        logo: null, // Assuming the logo is fetched separately
      });
    }
  }, [fetchLoading, magazineData]);

  const handleInputChange = (fieldName, value) => {
    setMagazineInfo({
      ...magazineInfo,
      [fieldName]: value,
    });
  };

  const handleImageUpload = (logo) => {
    setMagazineInfo({
      ...magazineInfo,
      logo,
    });
  };

  const handleUpdateMagazine = async () => {
    try {
      const updatedMagazine = await updateMagazine({
        variables: {
          magazineId,
          input: {
            name: magazineInfo.name,
            logo: magazineInfo.logo ? { upload: magazineInfo.logo } : null,
          },
        },
      });

      if (updatedMagazine?.data?.updateMagazine) {
        // Perform actions after successful update
        onClose();
        toast.success("Magazine updated successfully!");
      }
    } catch (error) {
      console.error("Error updating magazine:", error);
      toast.error("Failed to update magazine");
      // Handle error cases if needed
    }
  };

  if (fetchLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Magazine</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit magazine data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              <Loader showCaption={true}/>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update Magazine</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit event data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              Error fetching data: {error.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Update Magazine</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit magazine data.</span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "98%", textAlign: 'left' }}>
            <label style={{fontSize: 12}}>Magazine Name</label>
            <TextInput
              type="text"
              name="name"
              placeholder="Magazine Name"
              value={magazineInfo.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "99%", marginTop: 10, textAlign: 'left', }}>
            <label style={{fontSize: 12}}>Magazine Logo</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "5px 0px 5px 5px",
                justifyContent: "space-between",
                margin: "5px 0",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                {magazineData.magazine?.logo && (
                  <img
                    src={magazineData?.magazine?.logo?.url}
                    // alt="Event"
                    style={{ width: 36, height: 36, borderRadius: 50, marginTop: 2 }}
                  />
                )}
              </span>
              <div style={{display: "flex", alignItems: "center", gap: 10, textAlign: "center", padding: '0px 6px 0px 0px'}}>
                
                <ImageUploader
                  buttonText="Upload Magazine Logo"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  file={magazineInfo?.logo}
                  textColor="#ffffff"
                  onUpload={handleImageUpload}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update Magazine"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleUpdateMagazine}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateMagazine;