import { gql } from "@apollo/client";

export const CREATE_MAGAZINE_MUTATION = gql`
  mutation createMagazine($input: MagazineCreateInput!) {
    createMagazine(data: $input) {
      id
      name
      logo {
        height
        width
        url
      }
    }
  }
`;

export const UPDATE_MAGAZINE_MUTATION = gql`
mutation updateMagazine($magazineId: ID!, $input: MagazineUpdateInput!) {
  updateMagazine(where: { id: $magazineId }, data: $input) {
      id
      name
      logo {
        url
      }
    }
  }
`;

export const DELETE_MAGAZINE_MUTATION = gql`
  mutation DeleteMagazine($where: MagazineWhereUniqueInput!) {
    deleteMagazine(where: $where) {
      id
      name
    }
  }
`;