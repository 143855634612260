import { useMemo } from "react";
import useBase from "./useBase";
import { getUniqueCode } from "../../common/utils/code.utils";

function ImageUploadPreview({ defaultValue, onChange }) {
  const { imageSrc, handleUploadChange, isShowImage, isShowLabel, loading } =
    useBase({ defaultValue, onChange });
  const inputId = useMemo(() => getUniqueCode(), []);
  return (
    <label htmlFor={inputId}>
      {loading && <span>Loading...</span>}
      {isShowLabel && (
        <p style={{ cursor: "pointer" }}>click here to select image</p>
      )}
      <input
        id={inputId}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={handleUploadChange}
      />
      <img
        src={imageSrc}
        alt="preview"
        style={{
          width: "100%",
          height: "auto",
          display: isShowImage ? "block" : "none",
        }}
      />
    </label>
  );
}

export default ImageUploadPreview;
