import { useQuery } from "@apollo/client";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import React from "react";
import { newsHeaderIcon } from "../../data/svg";
import { GET_NEWS_QUERY } from "../../graphql/queries/posts.query";
import "../../styles/Modal.css";
import Button from "../Button";
import Loader from "../Loader";
import TextInput from "../TextInput";

const ViewNews = ({ onClose, postId }) => {
  const { loading, error, data } = useQuery(GET_NEWS_QUERY, {
    variables: { postId },
  });

  if (loading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View bizViews</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only bizViews data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !data || !data.post) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>View bizViews</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-only bizViews data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <span style={{ fontSize: 13 }}>error displaying bizViews</span>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const newsData = data.post;
  const author = newsData.author;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>View bizViews</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-only bizViews data.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div>
              <img
                src={newsData?.coverImage?.url}
                alt="bizViews Banner"
                style={{
                  width: "100%",
                  height: 150,
                  objectFit: "cover",
                  // maxHeight: "100px",
                  // borderRadius: "50%",
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}>bizViews Title</label>
            <TextInput
              type="text"
              name="title"
              placeholder="bizViews Title"
              value={newsData.title}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>

          <div style={{ width: "99.5%", textAlign: "left" }}>
            <label style={{ fontSize: 12 }}>bizViews Content</label>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                border: "1px solid #E1E6EF",
                margin: "5px 0px",
              }}
            >
              <DocumentRenderer
                componentBlocks={{
                  image: (props) => {
                    return (
                      <div>
                        {" "}
                        <img
                          src={props?.image?.url}
                          alt={props?.alt}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                    );
                  },
                }}
                document={newsData?.content?.document || []}
              />
            </div>
          </div>

          <div style={{ width: "100%", textAlign: "left", marginTop: 5 }}>
            <label style={{ fontSize: 12 }}>Author</label>
            <TextInput
              type="text"
              placeholder="Author"
              value={author.name}
              // value={newsData.authors.map((author) => author.name).join(",")}
              style={{
                width: "95%",
                backgroundColor: "#F5F5F5",
                border: "1px solid #E1E6EF",
              }}
              readOnly={true}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNews;
