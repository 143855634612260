import { gql } from '@apollo/client';

export const CREATE_SPEAKER_MUTATION = gql`
  mutation CreateSpeaker($data: SpeakerCreateInput!) {
    createSpeaker(data: $data) {
      id
      name
      image {
        id
        url
      }
      about
      # Include any other fields you want to retrieve after creating an author
    }
  }
`;

export const UPDATE_SPEAKER_MUTATION = gql`
  mutation UpdateSpeaker($speakerId: ID!, $input: SpeakerUpdateInput!) {
    updateSpeaker(where: { id: $speakerId }, data: $input) {
      id
      name
      about
      image {
        id
        url
        width
      }
    }
  }
`;

export const DELETE_SPEAKER_MUTATION = gql`
  mutation DeleteSpeaker($where: SpeakerWhereUniqueInput!) {
    deleteSpeaker(where: $where) {
      id
      name
      # Include any other fields you want to retrieve upon deletion
    }
  }
`;