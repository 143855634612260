import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import routes, { protectedRoutes } from "./routes";
import Navbar from "../components/Navbar";
import ProtectedLayout from "../components/ProtectedLayout";
import { AppContext, useStoreData } from "../components/AppContext/AppContext";

// import Layout from '../components/Layout';

const authPages = ["/login", "/register"]; // List of auth pages

const AppRouter = () => {
  return (
    <AppContext.Provider value={useStoreData()}>
      <Router>
        <Routes>
          <Route path="/*" element={<Navbar />} />
          <Route element={<ProtectedLayout />}>
            {protectedRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>
          {routes?.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {authPages.map((authPage) => (
            <Route
              key={authPage}
              path={authPage}
              element={<Navigate to="/" />}
            />
          ))}
        </Routes>
      </Router>
    </AppContext.Provider>
  );
};

export default AppRouter;
