import React, {useState} from 'react';
import LoadingButton from '../../Button/LoadingButton';
import Button from '../../Button';
import '../../../styles/Modal.css';
import { userHeaderIcon } from '../../../data/svg';
import TextInput from '../../TextInput';
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ROLE_MUTATION } from "../../../graphql/mutations/role.mutation";
import { GET_ROLES_QUERY } from "../../../graphql/queries/roles.query";
import { toast } from 'react-hot-toast';

const AddRole = ({ onClose }) => {
  const { refetch: refetchRoles } = useQuery(GET_ROLES_QUERY, {
    variables: { take: 10, skip: 0 }
  });
  const [createRole, {loading}] = useMutation(CREATE_ROLE_MUTATION);

  const [roleData, setRoleData] = useState({
    name: '',
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handleCreateRole = async () => {
    try {
      const { data } = await createRole({
        variables: {
          input: {
            name: roleData.name,
          }
        },
      });

      // toaster.success('Magazine added successfully!');
      console.log('Role added:', data.createRole);

      onClose(); // Close modal after successful addition
      refetchRoles();
      toast.success('Role created successfully!');
    } catch (error) {
      console.error('Error adding role:', error);
      toast.error('Failed to create role');
      // Handle error cases if needed
    }
  };

  return (
    <div className="modal-overlay">
      <div className='modal-center'>
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {userHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Add Role</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Enter the role you’ll like to create.</span>
            </span>
          </div>
        </div>
        <div className='modal-body'>
          <div style={{width: '100%', margin: '5px 0px 15px 0px'}}>
            <TextInput
              type="text"
              placeholder="Name"
              name="name"
              value={roleData.name}
              onChange={handleInputChange}
              style={{width: '95%'}}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div style={{width: '100%'}}>
            <LoadingButton
              buttonText="Add Role"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateRole}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRole;