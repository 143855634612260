import React from "react";
import NewsComponent from "../components/Users";

const User = () => {
  return (
    <main className="screen">
      <NewsComponent />
    </main>
  );
};

export default User;