import React, { useState } from 'react';
import Button from '../Button';
import '../../styles/Modal.css';
import { close, magazineHeaderIcon } from '../../data/svg';
import TextInput from '../TextInput';
import ImageUploader from '../ImageUploader';
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_MAGAZINE_MUTATION } from "../../graphql/mutations/magazine.mutation";
import { GET_MAGAZINES_QUERY } from "../../graphql/queries/magazine.query"
import LoadingButton from '../Button/LoadingButton';
import { toast } from 'react-hot-toast';

const AddMagazine = ({ onClose }) => {
  const { refetch: refetchMagazines } = useQuery(GET_MAGAZINES_QUERY);
  const [createMagazine, {loading}] = useMutation(CREATE_MAGAZINE_MUTATION);

  const [magazineData, setMagazineData] = useState({
    name: '',
    logo: null,
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMagazineData({ ...magazineData, [name]: value });
  };

  const handleLogoUpload = (logo) => {
    setMagazineData({ ...magazineData, logo });
  };

  const handleCreateMagazine = async () => {
    try {
      const { data } = await createMagazine({
        variables: {
          input: {
            name: magazineData.name,
            logo: {
              upload: magazineData.logo
            },
          }
        },
      });

      console.log('Magazine added:', data.createMagazine);
      onClose(); // Close modal after successful addition
      refetchMagazines();
      toast.success('Magazine created successfully!');
    } catch (error) {
      console.error('Error adding magazine:', error);
      toast.error('Failed to create magazine.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {magazineHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Add Magazine</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Enter the magazine data you’ll like to create.</span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          {/* {renderStep()} */}
          <div>
            <div style={{width: '98%'}}>
              <TextInput
                type="text"
                name="name"
                placeholder="Magazine Name"
                value={magazineData.name}
                onChange={handleInputChange}
                style={{width: '95%'}}
              />
            </div>

            <div style={{width: '98.5%'}}>
              <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', padding: '0px 0px 0px 10px', justifyContent: 'space-between', margin: '10px 0px', border:'1px solid #E1E6EF', borderRadius: 30 }}>
                <span style={{fontSize: 11, color: '#A9A9A9'}}>Magazine Logo</span>
                <div>
                  <ImageUploader
                    buttonText="Upload Magazine Logo"
                    backgroundColor="#151515"
                    borderColor="#151515"
                    textColor="#ffffff"
                    file={magazineData.logo}
                    onUpload={handleLogoUpload}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div style={{width: '100%'}}>
            <LoadingButton
              buttonText="Create Magazine"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateMagazine}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMagazine;