import React from "react";
import AccountComponent from "../components/Account";

const Account = () => {
  return (
    <main className="screen">
      <AccountComponent />
    </main>
  );
};

export default Account;
