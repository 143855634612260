import React from "react";
import MagazineComponent from "../components/Magazine";

const Magazine = () => {
  return (
    <main className="screen">
      <MagazineComponent />
    </main>
  );
};

export default Magazine;