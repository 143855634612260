import { gql } from "@apollo/client";

export const UPLOAD_MUTATION = gql`
  mutation uploadImage($name: String!, $altText: String!, $image: Upload!) {
    createAsset(
      data: { name: $name, altText: $altText, image: { upload: $image } }
    ) {
      id
      image {
        url
      }
    }
  }
`;
