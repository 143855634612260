import { gql } from "@apollo/client";

export const CREATE_EVENT_MUTATION = gql`
  mutation createEvent($input: EventCreateInput!) {
    createEvent(data: $input) {
      id
      title
      image {
        extension
        filesize
        height
        id
        url
        width
      }
      description {
        document
      }
      location
      date
      category
      payment_type
      amount
      speakers {
        id
        name
        image {
          url
        }
      }
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($eventId: ID!, $input: EventUpdateInput!) {
    updateEvent(where: { id: $eventId }, data: $input) {
      id
      title
      image {
        extension
        filesize
        height
        id
        url
        width
      }
      description {
        document
      }
      location
      date
      category
      payment_type
      amount
      speakers {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($where: EventWhereUniqueInput!) {
    deleteEvent(where: $where) {
      id
      title
      # Include any other fields you want to retrieve upon deletion
    }
  }
`;