import React, { useEffect, useRef, useState } from "react";
import { userHeaderIcon } from "../../../data/svg";
import "../../../styles/Modal.css";
import Button from "../../Button";

import TextInput from "../../TextInput";

// import "../../../node_modules/react-calendar-datetime-picker/dist/style.css";
import ImageUploader from "../../ImageUploader";

import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_SPEAKER_MUTATION } from "../../../graphql/mutations/speakers.mutation";
import { GET_SPEAKER_QUERY } from "../../../graphql/queries/speakers.query";

import { DocumentEditor, defaultDocumentFeatures } from "keystone-react-editor";
import { toast } from "react-hot-toast";
import Select from "react-select";

import LoadingButton from "../../Button/LoadingButton";
import Loader from "../../Loader";

const UpdateSpeaker = ({ onClose, speakerId }) => {
  // const { data: allSpeakers } = useQuery(GET_SPEAKERS_QUERY);

  const [updateSpeaker, { loading }] = useMutation(UPDATE_SPEAKER_MUTATION);
  const { data: speakerData, loading: fetchLoading, error } = useQuery(GET_SPEAKER_QUERY, {
    variables: { 
      where: {id: speakerId },
      take: 10,
      skip: 0,
    },
  });

  const [speakerInfo, setSpeakerInfo] = useState({
    name: "",
    about: "",
    image: null,
  });

    useEffect(() => {
    if (!fetchLoading && speakerData) {
      setSpeakerInfo({
        ...speakerInfo,
        name: speakerData.speaker.name,
        about: speakerData.speaker.about,
        logo: null, // Assuming the logo is fetched separately
      });
    }
  }, [fetchLoading, speakerData]);

  const handleInputChange = (fieldName, value) => {
    setSpeakerInfo({
      ...speakerInfo,
      [fieldName]: value,
    });
  };

  const handleImageUpload = (image) => {
    setSpeakerInfo({
      ...speakerInfo,
      image
    });
  };

  const handleUpdateSpeaker = async () => {
    try {
      const updatedSpeaker = await updateSpeaker({
        variables: {
          speakerId,
          input: {
            name: speakerInfo.name,
            about: speakerInfo.about,
            image: speakerInfo.image ? { upload: speakerInfo.image } : null,
          },
        },
      });

      if (updatedSpeaker?.data?.updateSpeaker) {
        onClose();
        toast.success("Speaker updated successfully!"); 
      }
    } catch (error) {
      console.error("Error updating speaker:", error);
      toast.error("Failed to update speaker.");
    }
  };

  if (fetchLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update Speaker</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit speaker data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show a loading indicator while fetching data
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-center">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update Speaker</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit speaker data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              Error fetching data: {error.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show error message if there's an error
  }

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {userHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Update Speaker</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-and-edit speaker data.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "99%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Speaker Name</label>
            <TextInput
              type="text"
              placeholder="Speaker Name"
              value={speakerInfo.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "99%", textAlign: "left", marginBottom: 15 }}>
            <label style={{ fontSize: 12 }}>Speaker Image</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "5px 0",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                width: "97.8%",
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                {speakerData.speaker?.image && (
                  <img
                    src={speakerData?.speaker?.image?.url}
                    // alt="Event"
                    style={{ width: 36, height: 36, borderRadius: 50, marginTop: 2 }}
                  />
                )}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  textAlign: "center",
                  paddingx: "0px 6px 0px 0px",
                }}
              >
                <ImageUploader
                  buttonText="Upload Speaker Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={speakerInfo?.image}
                  onUpload={handleImageUpload}
                />
              </div>
            </div>
          </div>

          <div style={{ width: "99%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Speaker Bio</label>
            <TextInput
              textarea={true}
              type="text"
              placeholder="Speaker Bio"
              value={speakerInfo.about}
              onChange={(e) => handleInputChange("about", e.target.value)}
              style={{ width: "94.5%" }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update Speaker"
              backgroundColor="#259BD8"
              borderColor="259BD8"
              textColor="#ffffff"
              onClick={handleUpdateSpeaker}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSpeaker;