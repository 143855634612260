import { gql } from "@apollo/client";

export const GET_ROLES_QUERY = gql`
  query GetRoles($take: Int!, $skip: Int!, $orderBy: [RoleOrderByInput!]) {
    rolesCount
    roles(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
    }
  }
`;

// export const GET_ROLES_BASIC_INFO_QUERY = gql`
//   query magazines {
//     roles {
//       id
//       name
//     }
//   }
// `;

export const GET_ROLE_QUERY = gql`
query Role($where: RoleWhereUniqueInput!) {
  role(where: $where) {
      id
      name
    }
  }
`;