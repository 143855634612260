// src/routes.js
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Dashboard from "../pages/Dashboard";
import Events from "../pages/Events";
import Awards from "../pages/Awards";
import Magazines from "../pages/Magazine";
import News from "../pages/News";
import User from "../pages/User";
import Account from "../pages/Account";
import { RedeemPassword } from "../components/Auth/ForgotPassword/ForgotPassword";

const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <RedeemPassword />,
  },
];

export const protectedRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/awards",
    element: <Awards />,
  },
  {
    path: "/magazine",
    element: <Magazines />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/users",
    element: <User />,
  },
  {
    path: "/account",
    element: <Account />,
  },
];

export default routes;
