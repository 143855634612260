import React from "react";
import "../../styles/Table.css";
import Loader from "../Loader";
import Button from "../Button";

const ResponsiveTable = ({ data, columns, isLoading, currentPage, pageSize, onPageChange, totalItems }) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  // console.log("Paginated Data:", totalPages);

  // console.log("Current Page:", currentPage);
  // console.log("Page Size:", pageSize);
  // console.log("Total Items:", totalItems);

  const previousPage = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  const showPagination = pageSize <= 10;

  return (
    <div className="responsive-table-container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <table className="responsive-table">
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column?.Header}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns?.map((column, columnIndex) => (
                    <td key={columnIndex} data-labelx={column?.accessorKey}>
                      {column?.render
                        ? column?.render({ value: row, index: rowIndex + 1 + currentPage * pageSize })
                        : row[column?.accessorKey]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {/* start pagination controls */}
          {showPagination && (
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            {!isLoading && currentPage > 0 && (
              <Button
                buttonText="Previous"
                backgroundColor="#259BD8"
                borderColor="#259BD8"
                textColor="#ffffff"
                onClick={previousPage}
              />
            )}
            &nbsp;
            &nbsp;
            <span style={{ fontSize: 13 }}>
              Page <strong>{currentPage + 1}</strong> of <strong>{totalPages}</strong>
            </span>
            &nbsp;
            &nbsp;
            {!isLoading && currentPage < totalPages - 1 && (
              <Button
                buttonText="Next"
                backgroundColor="#259BD8"
                borderColor="#259BD8"
                textColor="#ffffff"
                onClick={nextPage}
              />
            )}
          </div>
          )}
          {/* end pagination controls */}
        </>
      )}
    </div>
  );
};

export default ResponsiveTable;