import { gql } from '@apollo/client';

export const CREATE_AUTHOR_MUTATION = gql`
  mutation CreateAuthor($data: AuthorCreateInput!) {
    createAuthor(data: $data) {
      id
      name
      image {
        id
        url
      }
      # Include any other fields you want to retrieve after creating an author
    }
  }
`;

export const UPDATE_AUTHOR_MUTATION = gql`
mutation updateAuthor($authorId: ID!, $input: AuthorUpdateInput!) {
  updateAuthor(where: { id: $authorId }, data: $input) {
      id
      name
      image {
        url
      }
    }
  }
`;

export const DELETE_AUTHOR_MUTATION = gql`
  mutation DeleteAuthor($where: AuthorWhereUniqueInput!) {
    deleteAuthor(where: $where) {
      id
      name
    }
  }
`;