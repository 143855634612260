import React, { useEffect, useRef, useState } from "react";
import { eventHeaderIcon } from "../../data/svg";
import "../../styles/Modal.css";
import Button from "../Button";

import TextInput from "../TextInput";

import "../../../node_modules/react-calendar-datetime-picker/dist/style.css";
import ImageUploader from "../ImageUploader";

import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_EVENT_MUTATION } from "../../graphql/mutations/events.mutation";
import { GET_EVENT_QUERY } from "../../graphql/queries/events.query";

import { DocumentEditor, defaultDocumentFeatures } from "keystone-react-editor";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { GET_SPEAKERS_QUERY } from "../../graphql/queries/speakers.query";
import LoadingButton from "../Button/LoadingButton";
import { componentBlocks } from "../ImageComponentBlock/ImageComponentBlock";
import Loader from "../Loader";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 20,
    fontSize: 12,
    color: "#333",
    border: "1px solid #E1E6EF",
    // textAlign: "left",
    // height: 40
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 11,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 14,
    borderRadius: 8,
    marginBottom: 2,
    color: "#333",
    textAlign: "left",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 15,
    padding: 10,
    position: "relative",
  }),
};

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "This is editable " }],
  },
];

const UpdateEvent = ({ onClose, eventId, onSuccess = () => {} }) => {
  const { data: allSpeakers } = useQuery(GET_SPEAKERS_QUERY, {
    variables: {
      take: 100, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  const [updateEvent, { loading }] = useMutation(UPDATE_EVENT_MUTATION);
  const {
    loading: fetchLoading,
    error,
    data,
  } = useQuery(GET_EVENT_QUERY, {
    variables: { eventId },
  });

  const [eventData, setEventData] = useState({
    title: "",
    image: null,
    description: [],
    location: "",
    date: "",
    category: "",
    payment_type: "",
    amount: "",
    speakers: [],
  });

  const val = useRef(null);

  useEffect(() => {
    if (data && data.event) {
      const { speakers, date, ...otherEventData } = data.event;

      const formattedEventDate = new Date(date).toISOString().split("T")[0];
      console.log({ otherEventData });
      val.current = otherEventData?.description?.document || initialValue;

      setEventData({
        ...otherEventData,
        date: formattedEventDate,
        speakers: speakers.map((speaker) => speaker.id),
      });
    }
  }, [data]);

  if (loading) {
    return <p>Loading speaker IDs...</p>;
  }

  if (error) {
    return <p>Error fetching speaker IDs: {error.message}</p>;
  }

  const handleInputChange = (fieldName, value) => {
    setEventData({
      ...eventData,
      [fieldName]: value,
    });
  };

  const handleImageUpload = (imageData) => {
    setEventData({
      ...eventData,
      image: imageData,
    });
  };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "category") {
      setEventData({
        ...eventData,
        [fieldName]: value,
      });
    } else if (fieldName === "payment_type") {
      const defaultAmount = value === "free" ? 0 : parseFloat(eventData.amount);
      setEventData({
        ...eventData,
        [fieldName]: value,
        amount: defaultAmount, // Set default amount to '0' for 'Free' type
      });
    } else {
      setEventData({
        ...eventData,
        [fieldName]: value,
      });
    }
  };

  const handleUpdateEvent = async () => {
    const { speakers, image } = eventData;

    try {
      const amountValue =
        eventData.payment_type === "free" ? 0 : parseFloat(eventData.amount);

      const optional = {};
      if (image instanceof File) {
        optional.image = {
          upload: image,
        };
      }

      await updateEvent({
        variables: {
          eventId: eventId,
          input: {
            title: eventData.title,
            description: val.current,
            location: eventData.location,
            date: new Date(eventData.date).toISOString(),
            category: eventData.category,
            payment_type: eventData.payment_type,
            amount: amountValue,
            speakers: { connect: speakers.map((speaker) => ({ id: speaker })) },
            ...optional,
          },
        },
      });
      onSuccess();
      onClose();
      toast.success("Event updated successfully!");
    } catch (error) {
      console.error("Error updating event:", error);
      toast.error("Failed to update event.");
    }
  };

  if (fetchLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update Event</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit event data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show a loading indicator while fetching data
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update Event</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit event data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              Error fetching data: {error.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show error message if there's an error
  }

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    // Check if the selected date is in the past
    // if (selectedDate < currentDate) {
    //   // Alert the user or handle the past date selection as needed
    //   alert("Please select a date in the present or future.");
    //   return;
    // }

    // Update the state only if it's not a past date
    setEventData({
      ...eventData,
      date: selectedDate,
    });
  };

  // const formattedDate = new Date(eventData.date).toLocaleDateString('en-US');

  // console.log('Formatted Date:', formattedDate);

  const eventCategory = [
    { label: "Webexions", value: "webexions" },
    { label: "Connexions", value: "connexions" },
    { label: "Awards", value: "awards" },
  ];
  const eventType = [
    { label: "Paid", value: "paid" },
    { label: "Free", value: "free" },
  ];
  const options =
    allSpeakers?.speakers.map((speaker) => ({
      id: speaker?.id,
      value: speaker?.id,
      label: speaker?.name,
    })) || [];

  const handleSelectSpeaker = (selectedOption) => {
    if (selectedOption) {
      setEventData({
        ...eventData,
        speakers: selectedOption.map((opt) => opt.value),
      });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {eventHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Update Event</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-and-edit event data.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "99%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Title</label>
            <TextInput
              type="text"
              placeholder="Event Title"
              value={eventData.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "99%", textAlign: "left", marginBottom: 15 }}>
            <label style={{ fontSize: 12 }}>Event Image</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "5px 0",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                width: "97.8%",
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                Event Image
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  textAlign: "center",
                  paddingx: "0px 6px 0px 0px",
                }}
              >
                {eventData.image && (
                  <img
                    src={eventData?.image?.url}
                    alt="Event"
                    style={{
                      maxWidth: "36px",
                      maxHeight: "36px",
                      borderRadius: "50%",
                      paddingTopx: 5,
                      paddingBottomx: 5,
                    }}
                  />
                )}
                <ImageUploader
                  buttonText="Upload Event Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={eventData?.image?.url ? null : eventData?.image}
                  onUpload={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Description</label>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                border: "1px solid #E1E6EF",
                margin: "6px 0px",
                width: "91%",
              }}
            >
              {!loading && !!data && (
                <DocumentEditor
                  key="edito"
                  autoFocus={false}
                  onChange={(document) => {
                    val.current = document;
                  }}
                  initialValue={
                    val.current ||
                    data?.event?.description?.document ||
                    initialValue
                  }
                  componentBlocks={componentBlocks}
                  documentFeatures={defaultDocumentFeatures}
                />
              )}
            </div>
          </div>
          <div style={{ width: "99%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>Event Location</label>
            <TextInput
              type="text"
              placeholder="Event Location"
              value={eventData.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
              style={{ width: "94.5%" }}
            />
          </div>
          <div
            style={{
              width: "98%",
              display: "flex",
              gap: 10,
              // height: 45,
              marginBottom: 10,
            }}
          >
            <div
              style={{ marginBottomxx: 10, textAlign: "left", width: "100%" }}
            >
              <label style={{ fontSize: 12, marginBottom: 10 }}>
                Event Date
              </label>
              <TextInput
                type="date"
                placeholder="Event Date"
                // value={formattedDate}
                value={eventData.date}
                // value={format(new Date(eventData.date), 'yyyy-MM-dd')}
                onChange={handleDateChange}
                style={{ width: "90%", fontSize: 10, height: 12 }}
              />
            </div>
            <div style={{ width: "98%", textAlign: "left", marginBottomx: 5 }}>
              <label style={{ fontSize: 12, marginBottom: 10 }}>
                Event Category
              </label>
              <div style={{ marginTop: 5 }}>
                <Select
                  options={eventCategory}
                  value={eventCategory.find(
                    (cat) => cat.value === eventData.category
                  )}
                  placeholder="Event Category"
                  onChange={(selectedOption) =>
                    handleFieldChange("category", selectedOption.value)
                  }
                  styles={customStyles}
                />
              </div>
            </div>
          </div>

          <div
            style={{ width: "98%", display: "flex", gap: 10, marginBottom: 10 }}
          >
            <div style={{ width: "100%", textAlign: "left", marginTopx: 10 }}>
              <label style={{ fontSize: 12 }}>Event Type</label>
              {/* <br/> */}
              <div style={{ marginTop: 5 }}>
                <Select
                  options={eventType}
                  value={eventType.find(
                    (type) => type.value === eventData.payment_type
                  )}
                  placeholder="Event Type"
                  onChange={(selectedOption) =>
                    handleFieldChange("payment_type", selectedOption.value)
                  }
                  styles={customStyles}
                />
              </div>
            </div>

            {eventData.payment_type === "paid" && (
              <div
                style={{ width: "100%", textAlign: "left", marginBottomx: 10 }}
              >
                <label style={{ fontSize: 12 }}>Amount</label>
                {/* <br/> */}
                <TextInput
                  type="text"
                  placeholder="Amount"
                  // value={eventData.amount}
                  value={eventData.amount ? eventData.amount.toString() : ""}
                  onChange={(e) => handleInputChange("amount", e.target.value)}
                  style={{ width: "91%", marginTopx: 15 }}
                />
              </div>
            )}
          </div>
          {options && (
            <div
              style={{
                width: "98%",
                display: "flex",
                gap: 10,
                marginTop: 5,
                marginBottom: 15,
              }}
            >
              <div style={{ width: "100%", textAlign: "left", marginTopxx: 5 }}>
                <label style={{ fontSize: 12 }}>Speaker</label>
                <div style={{ marginTop: 5 }}>
                  <Select
                    options={options}
                    value={options.filter((opt) =>
                      eventData?.speakers?.includes(opt?.value)
                    )}
                    // value={selectedSpeaker}
                    placeholder="Event Speaker"
                    onChange={handleSelectSpeaker}
                    styles={customStyles}
                    isMulti
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update Event"
              backgroundColor="#259BD8"
              borderColor="259BD8"
              textColor="#ffffff"
              onClick={handleUpdateEvent}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEvent;
