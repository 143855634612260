import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { UPLOAD_MUTATION } from "../../graphql/mutations/asset.mutation";

const useBase = ({
  defaultValue,

  onChange,
}) => {
  const [imageSrc, setImageSrc] = useState(defaultValue?.url ?? "");

  const [uploadImage, { loading }] = useMutation(UPLOAD_MUTATION);

  const uploadFile = useCallback(
    async (file) => {
      try {
        return await uploadImage({
          variables: { name: file.name, altText: file?.name, image: file },
        });
      } catch (err) {
        toast.error(`Failed to upload file: ${file.name}`);
        setImageSrc("");
      }

      return null;
    },
    [uploadImage]
  );

  const handleUploadChange = useCallback(
    async (e) => {
      const selectedFile = e.currentTarget.files?.[0];
      const src = selectedFile ? URL.createObjectURL(selectedFile) : "";
      setImageSrc(src);

      if (selectedFile) {
        const result = await uploadFile(selectedFile);
        const uploadedImage = result?.data?.createAsset;
        onChange?.({ id: uploadedImage?.id, url: uploadedImage?.image?.url });
      }
    },
    [onChange, uploadFile]
  );

  return {
    imageSrc,
    loading,
    isShowLabel: !loading && !imageSrc,
    isShowImage: !loading && !!imageSrc,
    handleUploadChange,
  };
};

export default useBase;
