import React from "react";
import Button from "../Button";
import "../../styles/Modal.css";
import { trashHeaderIcon } from "../../data/svg";
import { useMutation } from "@apollo/client";
import { DELETE_NEWS_MUTATION } from "../../graphql/mutations/news.mutation";
import LoadingButton from "../Button/LoadingButton";
import { toast } from 'react-hot-toast';

const TrashNews = ({ onClose, newsId, newsTitle, refetchNews }) => {
  const [deletePost, {loading}] = useMutation(DELETE_NEWS_MUTATION);

  const handleDelete = async () => {
    try {
      const { data } = await deletePost({
        variables: {
          where: { id: newsId },
        },
      });

      if (data && data.deletePost) {
        toast.success(`bizViews "${newsTitle}" has been deleted successfully!`);

        onClose();

        // Reload events table to fetch updated data
        refetchNews();

        console.log(`bizViews with ID ${data.deletePost.id} has been deleted.`);
      }
    } catch (error) {
      console.error('Error deleting bizViews:', error);
      toast.error('An error occurred while deleting the bizViews.')
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header" style={{border: 'none'}}>
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {trashHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Trash bizViews</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>Are you sure you want to delete this bizViews <b><i>{newsTitle}</i></b></span>
              <br/>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>⚠️ P.S: This action is irreversible ⚠️</span>
            </span>
          </div>
        </div>
        
        <div className="modal-footer" stylee={{border: 'none'}}>
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Delete bizViews"
              backgroundColor="#FE435A"
              borderColor="#FE435A"
              textColor="#ffffff"
              onClick={handleDelete}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrashNews;
