import React from 'react';
import DataStatsCard from './DataStatsCard';
import Loader from '../Loader';
// import {dashboard} from '../../data/data';

const StatisticsCard = ({data, isLoading}) => {

  return (
  <div style={{ display: 'flex', padding: 20 }}>
    {data.map((statistics, index) => (
      <DataStatsCard key={index} title={statistics.title} value={statistics.value} loading={statistics.loading} />
    ))}
  </div>
  );
};

export default StatisticsCard;