import React, { useState, useEffect } from "react";
import { eventHeaderIcon } from "../../data/svg";
import "../../styles/Modal.css";
import Button from "../Button";

import ImageUploader from "../ImageUploader";
import TextInput from "../TextInput";

import { useMutation, useQuery } from "@apollo/client";

import { UPDATE_USER_MUTATION } from "../../graphql/mutations/user.mutation";
import { GET_USER_QUERY } from "../../graphql/queries/users.query";
import { GET_ROLES_QUERY } from "../../graphql/queries/roles.query";

import { toast } from 'react-hot-toast';
import LoadingButton from "../Button/LoadingButton";
import Loader from "../Loader";
import Select from "react-select";
import CheckboxTable from "../CheckboxTable"



const UpdateUser = ({ onClose, userId }) => {

  const tableData = [
    { id: 1, name: 'Events'  },
    { id: 2, name: 'Magazine' },
    { id: 3, name: 'News' },
    { id: 4, name: 'Business Directory' },
    { id: 5, name: 'Users' },
    { id: 6, name: 'Use Admin UI' },
  ];
  
  const tableColumns = [
    { key: 'name', header: '' },
    { key: 'create', header: 'Create' },
    { key: 'view', header: 'View' },
    { key: 'update', header: 'Update' },
    { key: 'delete', header: 'Delete' },
    { key: 'useAdminUI', header: 'Use Admin UI' },
  ];

  const { refetch: refetchUsers, data: roleData } = useQuery( GET_ROLES_QUERY, {
    variables: { take: 10, skip: 0 }
  } );

  const [updateUser, { loading }] = useMutation(UPDATE_USER_MUTATION);
  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER_QUERY, {
    variables: {
      where: { id: userId },
      take: 10,
      skip: 0
    },
  });

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    // password: "",
    selectedRole: null
  });

  // useEffect(() => {
  //   if (!fetchLoading && userData) {
  //     setUserInfo({
  //       ...userInfo,
  //       name: userData.user.name,
  //       email: userData.user.email,
  //       role: userData.user.role.name,
  //     });
  //   }
  // }, [fetchLoading, userData]);

  useEffect(() => {
    if (
      !userLoading &&
      !userError &&
      userData &&
      userData.user
    ) {
      const existingUser = userData.user;
      setUserInfo({
        ...userData,
        ...existingUser,
        selectedRole: {
          id: existingUser.role.id,
          value: existingUser.role.name,
          label: existingUser.role.name,
        },
        name: existingUser.name,
        email: existingUser.email,
        password: existingUser.password
      });
    }
  }, [userData, userLoading, userError]);

  const handleInputChange = (fieldName, value) => {
    setUserInfo({
      ...userInfo,
      [fieldName]: value,
    });
  };

  // const handleImageUpload = (logo) => {
  //   setUserInfo({
  //     ...userInfo,
  //     logo,
  //   });
  // };

  const handleSelectRole = (selectedOption) => {
    if (selectedOption) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        selectedRole: {
          id: selectedOption.id,
          value: selectedOption.value,
          label: selectedOption.label,
        },
      }));
    } else {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        selectedRole: null,
      }));
    }
  };

  const options =
    roleData?.roles?.map((role) => ({
      id: role.id,
      value: role.name,
      label: role.name,
    })) || [];

  const handleUpdateUser = async () => {
    try {
      const optional = {};
      if (
        userInfo?.selectedRole?.id !== userData?.user?.role?.id
      ) {
        optional.role = {
          connect: {
            id: userInfo.selectedRole?.id,
          },
        };
      }

      const updatedUser = await updateUser({
        variables: {
          userId: userId,
          input: {
            name: userInfo.name,
            email: userInfo.email,
            // password: userInfo.password,
            // password: { set: userInfo.password },
            ...optional,
          },
        },
      });

      if (updatedUser?.data?.updateUser) {
        // Perform actions after successful update
        onClose();
        refetchUsers();
        toast.success("User updated successfully!");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user");
      // Handle error cases if needed
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
      // textAlign: "left !important",
      border: "1px solid #E1E6EF",
      width: '102%'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
      position: "relative",
    }),
  };

  if (userLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update User</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit user data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              <Loader showCaption={true}/>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (userError) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
              <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
                {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
                <br/>
                <span>Update User</span>
                <br/>
                <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit event data.</span>
              </span>
            </div>
          </div>
          
          <div className="modal-body" >
            <div style={{ width: "98%" }}>
              Error fetching data: {userError.message}
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>Update User</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-and-edit user data.</span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%", textAlign: 'left' }}>
            <label style={{fontSize: 12}}>Name</label>
            <TextInput
              type="text"
              name="name"
              placeholder="User Name"
              value={userInfo.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              style={{ width: "95.5%" }}
            />
          </div>

          <div style={{ width: "100%", textAlign: 'left' }}>
            <label style={{fontSize: 12}}>Email</label>
            <TextInput
              type="text"
              name="name"
              placeholder="User Email"
              value={userInfo.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              style={{ width: "95.5%" }}
            />
          </div>

          {/* <div style={{ width: "98%", textAlign: 'left' }}>
            <label style={{fontSize: 12}}>Password</label>
            <TextInput
              type="password"
              name="name"
              placeholder="User Password"
              value={userInfo.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              style={{ width: "95%" }}
            />
          </div> */}

          <div style={{ textAlign: 'left' }}>
            <label style={{fontSize: 12}}> Role </label>
            <div style={{ margin: '5px 0px 5px 0px', width: "98%" }}>
              <Select
                value={userInfo.selectedRole}
                onChange={handleSelectRole}
                options={options}
                placeholder="Select Role"
                styles={customStyles}
              />
            </div>
          </div>

          {/* start of work in progress permission ui */}
          {/* <div style={{ textAlign: 'left' }}>
            <label style={{fontSize: 12}}> Permissions </label>
            <div style={{fontSize: 12, marginTop: 5}}>
              <CheckboxTable data={tableData} columns={tableColumns}/>
            </div>
          </div> */}
          {/*end of work in progress permission ui */}

        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update User"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleUpdateUser}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;