import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { imageUpload } from "../../../../data/svg";
import {
  CREATE_ISSUE_PAGE_MUTATION,
  DELETE_ISSUE_PAGE_MUTATION,
  UPDATE_ISSUE_PAGE_MUTATION,
} from "../../../../graphql/mutations/issues.mutation";
import { FETCH_ISSUE_PAGES } from "../../../../graphql/queries/issues.query";
import IssuePageBox from "./IssuePageBox";

function IssuePages({ issueId }) {
  const [updatingPages, setUpdatingPages] = React.useState([]);
  const fileInputref = React.useRef(null);
  const { data: issuePages, refetch } = useQuery(FETCH_ISSUE_PAGES, {
    variables: { issueId },
  });

  const [addPage, { loading: addingPage }] = useMutation(
    CREATE_ISSUE_PAGE_MUTATION
  );
  const [updatePage] = useMutation(UPDATE_ISSUE_PAGE_MUTATION);
  const [deletePage, { loading: deletingPage }] = useMutation(
    DELETE_ISSUE_PAGE_MUTATION
  );

  const onUpdate = async (pageId, file) => {
    try {
      setUpdatingPages((prev) => [...prev, pageId]);
      const { data } = await updatePage({
        variables: {
          pageId,
          data: {
            image: {
              upload: file,
            },
          },
        },
      });

      refetch();
      setUpdatingPages((prev) => prev.filter((id) => id !== pageId));
    } catch (err) {
      console.error(err);
      setUpdatingPages((prev) => prev.filter((id) => id !== pageId));
    }
  };

  const onDelete = async (id) => {
    try {
      const { data } = await deletePage({
        variables: {
          pageId: id,
        },
      });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  // const onAddPage = async (file) => {
  //   const last = issuePages?.issuePages?.[issuePages?.issuePages?.length - 1];
  //   try {
  //     const { data } = await addPage({
  //       variables: {
  //         data: {
  //           issue: {
  //             connect: {
  //               id: issueId,
  //             },
  //           },
  //           page_no: last ? last.page_no + 1 : 1,
  //           image: {
  //             upload: file,
  //           },
  //           alt: file?.name,
  //         },
  //       },
  //     });
  //     refetch();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const onAddPage = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const last = issuePages?.issuePages?.[issuePages?.issuePages?.length - 1];
        const { data } = await addPage({
          variables: {
            data: {
              issue: {
                connect: {
                  id: issueId,
                },
              },
              page_no: last ? last.page_no + 1 : 1,
              image: {
                upload: file,
              },
              alt: file?.name,
            },
          },
        });
      }
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ padding: "20px 0px", position: "relative" }}>
      <h3>Issue Pages</h3>
      <div className="image-grid" style={{ display: "flex", justifyContent: "space-between" }}>
        {issuePages?.issuePages?.map((issuePage) => (
          <IssuePageBox
            key={issuePage.id}
            id={issuePage.id}
            url={issuePage?.image?.url}
            page_no={issuePage?.page_no}
            onDelete={onDelete}
            onUpdate={onUpdate}
            isLoading={updatingPages.includes(issuePage.id)}
          />
        ))}
      </div>

      <div onClick={() => fileInputref.current?.click()} className="upload-image">
        <input
          className="sr-only"
          type="file"
          accept="image/png, image/jpeg"
          // multiple
          ref={fileInputref}
          // onChange={(e) => onAddPage(e.target.files[0])}
          onChange={(e) => onAddPage(Array.from(e.target.files))}
        />
        {imageUpload({})}
        <p className="caption">
          click to select files
          <br />
          <b>supported format *png*,*jpeg*</b>
        </p>
      </div>
      {addingPage || deletingPage ? (
        <div className="loading_overlay">
          <div className="loader"></div>
        </div>
      ) : null}
    </div>
  );
}

export default IssuePages;