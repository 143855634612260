import React, {useState} from 'react';
import '../../styles/Account.css'
import Navbar from '../Navbar';
import SidebarContent from './Sidebar/SidebarContent';
import SidebarLinks from './Sidebar/SidebarLinks';

const styles = {
  container: {
    textAlign: 'center',
    margin: '0px 50px 50px 50px',
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%"
  },
  actionsSidebar: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 20,
  },
  actionsHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
  }
};

const Account = () => {
  const [activeLink, setActiveLink] = useState('');

  return (
    <>
    <Navbar/>
    <div style={styles.container}>
      <div style={{display: "flex", padding: '20px 20px'}}>
        <div style={{backgroundColor: "#F9F9F9", width: "30%", justifyContent: "center", borderRadius: 20, margin: 5, border: '1px solid #F2F2F2', padding: 20}}>
          <div style={{backgroundColor: "#FFFFFF", borderRadius: 15, padding: 20, marginBottom: 20, border: '1px solid #F2F2F2'}}>
            <div className="account-user">
              <div style={{backgroundColor: '#151515', width: 50, height: 50, borderRadius: 50, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 14 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8ZM7 18C10.866 18 14 16.2091 14 14C14 11.7909 10.866 10 7 10C3.13401 10 0 11.7909 0 14C0 16.2091 3.13401 18 7 18Z" fill="white"/>
                </svg>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>Super Admin</span>
                <span style={{fontSize: 12}}>admin@biz4Biz.org</span>
              </div>
            </div>
          </div>
          <SidebarLinks setActiveLink={setActiveLink} activeLink={activeLink} />
        </div>
        
        <div style={{backgroundColor: "#F9F9F9", width: "100%", justifyContent: "center", borderRadius: 20, margin: 5, border: '1px solid #F2F2F2', padding: 20}}>
          <div style={{backgroundColor: "#FFFFFF", borderRadius: 20, padding: 20}}>
            <SidebarContent selectedLink={activeLink}/>
          </div>
        </div>
      </div>
      <div>
        <p style={{fontSize: 13, fontWeight: 500, textAlign: 'center'}}>biz4Biz Control Panel © 2023 - 2024. <span style={{fontSize: 10}}>version 25(0.0.1)</span></p>
      </div>
    </div>
    </>
  );
};

export default Account;