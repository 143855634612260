import React, { useState } from "react";
import Button from "../Button";
import "../../styles/Modal.css";
import { eventHeaderIcon } from "../../data/svg";
import TextInput from "../TextInput";
import { useQuery } from "@apollo/client";
import { GET_MAGAZINE_QUERY } from "../../graphql/queries/magazine.query";
import Loader from "../Loader";
import { format } from 'date-fns';

const ViewMagazine = ({ onClose, magazineId }) => {
  console.log("check magazine id:", magazineId)
  
  const { loading, error, data } = useQuery(GET_MAGAZINE_QUERY, {
    // variables: { magazineId },
    variables: {
      where: { id: magazineId }, // Pass the magazine ID to the query variables
    },
  });

  if (loading) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Magazine</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only magazine data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <Loader showCaption={true}/>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>;
  }

  if (error || !data || !data.magazine) {
    return <div className="modal-overlay">
    <div className="modal-center">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Magazine</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only issue data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <span>Error fetching data</span>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>
  }

  const magazineData = data.magazine;

  return (
    <div className="modal-overlay">
      <div className="modal-center">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>View Magazine</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only issue data.</span>
            </span>
          </div>
        </div>
        
        <div className="modal-body">
          <div style={{ width: "100%", marginBottom: 10 }}>
            <div>
              <img
                src={magazineData?.logo?.url}
                alt="Magazine Image"
                style={{ maxWidth: "100px", maxHeight: "100px", borderRadius: '50%' }}
              />
            </div>
          </div>
          <div style={{ width: "98%" }}>
            <TextInput
              type="text"
              placeholder="Magazine Name"
              value={magazineData.name}
              style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>

          <div style={{ width: "98%", display: "flex", gap: 10 }}></div>
          
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMagazine;