import React from "react";
import DashboardComponent from "../components/Dashboard";

const Dashboard = () => {
  return (
    <main className="screen">
      <DashboardComponent />
    </main>
  );
};

export default Dashboard;
