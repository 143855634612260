import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { magazineHeaderIcon } from "../../../data/svg";
import {
  CREATE_ISSUE_MUTATION,
  CREATE_ISSUE_PAGES_MUTATION,
} from "../../../graphql/mutations/issues.mutation";
import {
  GET_ISSUES_QUERY,
  GET_MAGAZINES_QUERY,
} from "../../../graphql/queries/magazine.query";
import "../../../styles/Modal.css";
import Button from "../../Button";
import LoadingButton from "../../Button/LoadingButton";
import ImageUploader from "../../ImageUploader";
import ImageCard from "../../ImageUploader/ImageCard";
import TextInput from "../../TextInput";

const AddIssue = ({ onClose }) => {
  const { refetch: refetchIssues } = useQuery(GET_ISSUES_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });
  const [createIssue, { loading }] = useMutation(CREATE_ISSUE_MUTATION);
  const [createIssuePages, { loading: creatingIssuePages }] = useMutation(
    CREATE_ISSUE_PAGES_MUTATION
  );

  const { data: magazineListData } = useQuery(GET_MAGAZINES_QUERY, {
    variables: {
      take: 10, // Set the appropriate value for the number of items to fetch per page
      skip: 0, // Set the appropriate value for the offset
    },
  });

  const [issueImages, setIssueImages] = useState([]);

  const [issueData, setIssueData] = useState({
    selectedMagazine: null,
    issueNumber: "",
    // description: [],
    issueCoverImage: null,
    magazineImages: [],
    theme: "",
    pages: [],
  });

  const handleSelectMagazine = (selectedOption) => {
    if (selectedOption) {
      setIssueData({
        ...issueData,
        selectedMagazine: selectedOption,
        name: selectedOption.label,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIssueData({ ...issueData, [name]: value });
  };

  const handleIssueCoverImageUpload = (issueCoverImage) => {
    setIssueData({ ...issueData, issueCoverImage });
  };

  const handleCreateIssue = async () => {
    try {
      const {
        issueNumber,
        description,
        issueCoverImage,
        selectedMagazine,
        theme,
      } = issueData;
  
      // Step 1: Create Overall Issue
      const { data: createdIssueData } = await createIssue({
        variables: {
          input: {
            issueNumber: parseInt(issueNumber),
            description,
            coverImage: { upload: issueCoverImage },
            magazine: { connect: { id: selectedMagazine.value } },
            theme,
          },
        },
      });
  
      const issueId = createdIssueData?.createIssue?.id;
  
      // Step 2: Create Issue Pages with Issue association
      if (issueId) {
        const issuePagesToCreate = issueImages.map((file, index) => ({
          page_no: index + 1,
          image: { upload: file },
          alt: file.name,
          issue: {
            connect: { id: issueId },
          },
        }));
  
        await createIssuePages({
          variables: {
            data: issuePagesToCreate,
          },
        });
      }
  
      refetchIssues(); // Refresh issue data
      onClose();
      toast.success("Issue created successfully!");
    } catch (error) {
      console.error("Error adding issue:", error);
      toast.error("Failed to create issue.");
      // Handle error cases if needed
    }
  };   

  const options =
    magazineListData?.magazines.map((magazine) => ({
      value: magazine.id,
      label: magazine.name,
    })) || [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
    }),
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {magazineHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Add Issue</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                Enter the issue data you’ll like to create.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div>
            <div style={{ marginBottom: 5, width: "98%" }}>
              <Select
                value={issueData.selectedMagazine}
                onChange={handleSelectMagazine}
                options={options}
                placeholder="Select Magazine"
                isClearable={true}
                isDisabled={false}
                styles={customStyles}
              />
            </div>
          </div>
          <div style={{ width: "98%" }}>
            <TextInput
              type="text"
              name="issueNumber"
              placeholder="Issue Number"
              value={issueData.issueNumber}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "98%" }}>
            <TextInput
              type="text"
              name="theme"
              placeholder="Theme"
              value={issueData.theme}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>
          <div style={{ width: "98%" }}>
            <TextInput
              type="text"
              name="description"
              placeholder="Description"
              value={issueData.description}
              onChange={handleInputChange}
              style={{ width: "95%" }}
            />
          </div>

          <div style={{ width: "98%" }}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "5px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                Cover Image
              </span>
              <div>
                <ImageUploader
                  buttonText="Upload Issue Cover Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={issueData.issueCoverImage}
                  onUpload={handleIssueCoverImageUpload}
                />
              </div>
            </div>
          </div>

          <div style={{ width: "93.5%" }} className="multiimageupload">
            <span style={{ fontSize: 11, color: "#a9a9a9", userSelect: "none" }}>
              Magazine Issue Images
            </span>
            <ImageCard images={issueImages} setImages={setIssueImages} />
          </div>
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Create Issue"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleCreateIssue}
              showLoader={true}
              isLoading={loading || creatingIssuePages}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIssue;
