import React, { useMemo, useState } from "react";
import { actions } from "../../data/data";
import "../../styles/Navbar.css";
import Button from "../Button";
import Navbar from "../Navbar";
import ResponsiveTable from "../ResponsiveTable";
import DataStatsCard from "../StatisticsCard";
import AddEvent from "./AddEvent";
import ViewModal from "./ViewEvent";
import ViewSpeaker from "./speakers/ViewSpeaker";

import { useQuery } from "@apollo/client";
import { createPortal } from "react-dom";
import { collapseIcon, expandIcon } from "../../data/svg";
import { GET_EVENTS_PAGE_QUERY } from "../../graphql/queries/events.query";
import { GET_SPEAKERS_QUERY } from "../../graphql/queries/speakers.query";
import Actions from "../Actions";
import Pills from "../Pills";
import TrashModal from "./TrashEvent";
import UpdateModal from "./UpdateEvent";
import AddSpeaker from "./speakers/AddSpeaker";
import TrashSpeaker from "./speakers/TrashSpeaker";
import UpdateSpeaker from "./speakers/UpdateSpeaker";

const styles = {
  container: {
    textAlign: "center",
    margin: "0px 50px 50px 50px",
    backgroundColor: "#ffffff",
    borderRadius: 30,
    height: "100%",
  },
  connexionsHeader: {
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor: "red",
    borderRadius: 20,
    marginBottom: 20,
    alignItems: "center",
  },
};

const Events = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; // this is defined as a constant

  const [currentSpeakerPage, setCurrentSpeakerPage] = useState(0);
  const pageSpeakerSize = 10;

  const {
    data: speakerData,
    loading: speakerLoading,
    refetch: refetchSpeakers,
  } = useQuery(GET_SPEAKERS_QUERY, {
    variables: {
      take: pageSpeakerSize,
      skip: currentSpeakerPage * pageSpeakerSize,
      orderBy: [{ name: "asc" }],
    },
  });

  const {
    data,
    loading,
    refetch: refetchEvents,
  } = useQuery(GET_EVENTS_PAGE_QUERY, {
    variables: {
      take: pageSize, // Number of items to fetch per page
      // skip: 0, // Number of items to skip
      skip: currentPage * pageSize,
      orderBy: [{ created_at: "desc" }], // Your ordering preference
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSpeakerPageChange = (page) => {
    setCurrentSpeakerPage(page);
  };
  // const {data: eventData, loading: eventLoading, error: eventError, refetch: refetchEvent } = useQuery(GET_EVENT_QUERY);

  const closeModal = () => {
    setShowModal(false);
    setShowSpeakerModal(false);
  };

  const handleModalLogic = ({ type, data }) => {
    switch (type) {
      case "view":
        setSelectedRowData(data);
        setShowViewModal(true);
        break;

      case "update":
        setSelectedRowData(data);
        setShowUpdateModal(true);
        break;

      case "trash":
        setSelectedRowData(data);
        setShowTrashModal(true);
        break;

      default:
        return null;
    }
  };

  const handleSpeakerModalLogic = ({ type, speakerData }) => {
    switch (type) {
      case "view":
        setSelectedRowData(speakerData);
        setShowViewSpeaker(true);
        break;

      case "update":
        setSelectedRowData(speakerData);
        setShowUpdateSpeaker(true);
        break;

      case "trash":
        setSelectedRowData(speakerData);
        setShowTrashSpeaker(true);
        break;

      default:
        return null;
    }
  };

  const speakerColumns = useMemo(
    () => [
      {
        Header: "ID",
        // accessorKey: "index",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Speaker",
        accessorKey: "name",
        render: ({ value }) => {
          // const { name, image } = row.original;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{value.name}</span>
              &nbsp;
              <img
                src={
                  value && value.image && value.image.url
                    ? value.image.url
                    : "https://i.ibb.co/bL4xyfh/no-image-placeholder.png"
                }
                alt="Speaker avatar"
                style={{
                  width: "40px",
                  height: "auto",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Bio",
        accessorKey: "about",
        render: ({ value }) => (
          <div title={value.about}>
            {value && value.about && value.about.length > 50
              ? `${value.about.substring(0, 50)}...`
              : value.about}
          </div>
        ),
      },
      // {
      //   Header: "Image",
      //   accessorKey: "image",
      //   render: ({ value }) => {
      //     return (
      //       <img
      //         src={value && value.image && value.image.url ? value.image.url : 'https://i.ibb.co/bL4xyfh/no-image-placeholder.png'}
      //         alt="Speaker Image"
      //         style={{ width: '50px', height: 'auto' }}
      //       />
      //     );
      //   },
      // },
      {
        Header: "Action",
        render: ({ row, value }) => {
          return (
            <Actions
              actions={actions}
              openModal={handleSpeakerModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );
  // end speakers column

  // start events column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        // accessorKey: "index",
        render: ({ index }) => {
          return index;
        },
      },
      {
        Header: "Event Title",
        accessorKey: "title",
      },
      {
        Header: "Location",
        accessorKey: "location",
      },
      {
        Header: "Event Category",
        accessorKey: "category",
        render: ({ value }) => <Pills eventCategory={value?.category} />,
      },
      {
        Header: "Event Type",
        accessorKey: "payment_type",
        render: ({ value }) => <Pills eventType={value?.payment_type} />,
      },
      {
        Header: "Event Date",
        accessorKey: "date",
        render: ({ value }) => {
          if (value && value?.date) {
            const publishedDate = new Date(value?.date);
            if (!isNaN(publishedDate.getTime())) {
              // Valid date, format it
              const formattedDate = publishedDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });
              return formattedDate;
            }
          }
          return "No Date";
        },
      },
      {
        Header: "Action",
        render: ({ row, value }) => {
          return (
            <Actions
              actions={actions}
              openModal={handleModalLogic}
              rowData={value}
            />
          );
        },
      },
    ],
    []
  );
  // end events column

  const [showModal, setShowModal] = useState(false);
  const [showSpeakerModal, setShowSpeakerModal] = useState(false);

  const [showViewModal, setShowViewModal] = useState(false);
  const [showViewSpeaker, setShowViewSpeaker] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateSpeaker, setShowUpdateSpeaker] = useState(false);

  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showTrashSpeaker, setShowTrashSpeaker] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState(null);

  const [showEventsTableData, setShowEventsTableData] = useState(true);
  const [showSpeakersTableData, setShowSpeakersTableData] = useState(true);

  const toggleEventsTableData = () => {
    setShowEventsTableData(!showEventsTableData);
  };

  const toggleSpeakerTableData = () => {
    setShowSpeakersTableData(!showSpeakersTableData);
  };

  // to be used after implementing speaker tables
  // const toggleSpeakersTableData = () => {
  //   setShowSpeakersTableData(!showSpeakersTableData);
  // };

  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <DataStatsCard
          data={[
            { title: "Events", value: data?.eventsCount, loading },
            { title: "Free", value: data?.freeEventsCount, loading },
            { title: "Paid", value: data?.paidEventsCount, loading },
            { title: "Webexions", value: data?.webexionsCount, loading },
            { title: "Connexions", value: data?.connexionsCount, loading },
            { title: "Speakers", value: speakerData?.speakersCount, loading },
          ]}
        />
        {/* start of speakers section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Speakers</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add Speaker"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      // onClick={openModal}
                      onClick={() => setShowSpeakerModal(true)}
                    />
                  </div>
                  {showSpeakerModal &&
                    createPortal(
                      <AddSpeaker
                        onClose={closeModal}
                        refetchEvents={refetchEvents}
                      />,
                      document.body
                    )}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={
                        showSpeakersTableData
                          ? collapseIcon({})
                          : expandIcon({})
                      }
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleSpeakerTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
                {showSpeakersTableData && (
                  <ResponsiveTable
                    isLoading={speakerLoading}
                    data={speakerData?.speakers || []}
                    columns={speakerColumns}
                    currentPage={currentSpeakerPage}
                    pageSize={pageSize}
                    onPageChange={handleSpeakerPageChange}
                    // totalItems={totalItems} // Pass totalItems to ResponsiveTable
                    totalItems={speakerData?.speakersCount} // Pass totalItems to ResponsiveTable
                    totalPages={speakerData?.totalPages}
                  />
                )}
                {showViewSpeaker && (
                  <ViewSpeaker
                    onClose={() => setShowViewSpeaker(false)}
                    speakerData={selectedRowData}
                    speakerId={selectedRowData?.id}
                  />
                )}
                {showUpdateSpeaker && (
                  <UpdateSpeaker
                    onClose={() => setShowUpdateSpeaker(false)}
                    speakerData={selectedRowData}
                    speakerId={selectedRowData?.id}
                    refetchSpeakers={refetchSpeakers}
                  />
                )}
                {showTrashSpeaker && (
                  <TrashSpeaker
                    onClose={() => setShowTrashSpeaker(false)}
                    speakerData={selectedRowData}
                    speakerId={selectedRowData?.id}
                    speakerName={selectedRowData?.name}
                    refetchSpeakers={refetchSpeakers}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of speakers section */}

        {/* start of events section */}
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div
            style={{
              backgroundColor: "#F9F9F9",
              width: "100%",
              justifyContent: "center",
              borderRadius: 20,
              margin: 5,
              border: "1px solid #F2F2F2",
              padding: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div style={styles.connexionsHeader}>
                <div>
                  <span style={{ fontWeight: 600 }}>Events</span>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  {/* <TextInput
                    type="text"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <TextInput
                    type="text"
                    placeholder="Filter by Event Type"
                    value={inputValue}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add&nbsp;Event"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      // onClick={openModal}
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                  {showModal &&
                    createPortal(
                      <AddEvent
                        onClose={closeModal}
                        onSuccess={refetchEvents}
                      />,
                      document.body
                    )}
                  {/* <div style={{ marginTop: 5 }}>
                    <Button
                      buttonText="Add Speakers"
                      backgroundColor="#151515"
                      borderColor="#151515"
                      textColor="#ffffff"
                      // onClick={openModal}
                      onClick={() => setShowSpeakerModal(true)}
                    />
                  </div>
                  {showSpeakerModal &&
                    createPortal(
                      <AddSpeaker onClose={closeModal} refetchEvents={refetchEvents} />,
                      document.body
                  )} */}
                  <div style={{ marginTop: 5 }}>
                    <Button
                      icon={
                        showEventsTableData ? collapseIcon({}) : expandIcon({})
                      }
                      backgroundColor="#f6e096"
                      borderColor="#ddc46e"
                      textColor="#8A7119"
                      onClick={toggleEventsTableData}
                    />
                  </div>
                </div>
              </div>

              <div>
                <hr className="foo" />
              </div>

              <div>
                {showEventsTableData && (
                  <ResponsiveTable
                    isLoading={loading}
                    data={data?.events || []}
                    columns={columns}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    // totalItems={totalItems} // Pass totalItems to ResponsiveTable
                    totalItems={data?.eventsCount} // Pass totalItems to ResponsiveTable
                    totalPages={data?.totalPages}
                  />
                )}
                {showViewModal && (
                  <ViewModal
                    onClose={() => setShowViewModal(false)}
                    data={selectedRowData}
                    eventId={selectedRowData?.id}
                    descriptionData={selectedRowData.descriptionData}
                    speakerId={selectedRowData?.id}
                  />
                )}
                {showUpdateModal && (
                  <UpdateModal
                    onClose={() => setShowUpdateModal(false)}
                    data={selectedRowData}
                    eventId={selectedRowData?.id}
                    speakerId={selectedRowData?.id}
                    refetchEvents={refetchEvents}
                  />
                )}
                {showTrashModal && (
                  <TrashModal
                    onClose={() => setShowTrashModal(false)}
                    data={selectedRowData}
                    eventId={selectedRowData?.id}
                    eventName={selectedRowData?.title}
                    refetchEvents={refetchEvents}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of events section */}
        <div>
          <p style={{ fontSize: 13, fontWeight: 500, textAlign: "center" }}>
            biz4Biz Control Panel © 2023 - 2024.{" "}
            <span style={{ fontSize: 10 }}>version 25(0.0.1)</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Events;
