import { USER_STORAGE_KEY } from "../_constant";

export const SetUserInfo = (userInfo) =>
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userInfo));

export const getUserInfo = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
  } catch {
    return null;
  }
};
