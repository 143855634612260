import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import toast from "react-hot-toast";
import { clearToken, getAccessToken } from "../common/utils/token.utils";

const link = createUploadLink({
  // uri: process.env.REACT_APP_GRAPHQL_API,
  // uri: "https://biz4biz.onrender.com/api/graphql",
  // uri: "https://13.49.66.60/api/graphql",
  uri: "https://api.biz4biz.org/api/graphql",
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    let errors = [];
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "KS_EXTENSION_ERROR":
            if (err?.message?.includes("UNAUTHENTICATED")) {
              toast.error("Session Expired!!!");
              clearToken();
              window.location.reload();
              return;
            }

            return;
          default:
            errors.push(err?.message);
        }
      }
    }
    if (errors?.length) {
      toast.error(errors?.join(", "));
    }
    if (networkError) {
      toast.error(networkError?.message);
    }
  }
);

const successLink = () => {
  return {
    request: (operation) => {
      operation.setContext({
        onStart: () => {
          operation.running = true;
        },
      });
    },
    onError: (error) => {
      if (error.operation.running) {
        // Check for successful operation completion
        toast.success("Success!"); // Your success message here
      }
    },
  };
};

export const gql_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink.concat(link), successLink()]),
});
