import { useMutation, useQuery } from "@apollo/client";
import { DocumentEditor, defaultDocumentFeatures } from "keystone-react-editor";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { newsHeaderIcon } from "../../data/svg";
import { UPDATE_POST_MUTATION } from "../../graphql/mutations/news.mutation";
import { GET_AUTHORS_QUERY } from "../../graphql/queries/author.query";
import { GET_POST_QUERY } from "../../graphql/queries/posts.query";
import "../../styles/Modal.css";
import Button from "../Button";
import LoadingButton from "../Button/LoadingButton";
import { componentBlocks } from "../ImageComponentBlock/ImageComponentBlock";
import ImageUploader from "../ImageUploader";
import Loader from "../Loader";
import TextInput from "../TextInput";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "This is a content field" }],
  },
];

const UpdateNews = ({ onClose, postId }) => {
  const { data: authorData, refetch: refetchNews } =
    useQuery(GET_AUTHORS_QUERY);

  const [updatePost, { loading }] = useMutation(UPDATE_POST_MUTATION);

  const {
    data: postQueryData,
    loading: postLoading,
    error: postError,
  } = useQuery(GET_POST_QUERY, {
    variables: {
      where: { id: postId },
    },
  });

  const [postData, setPostData] = useState({
    title: "",
    coverImage: null,
    content: [],
    publishedAt: "",
    // selectedAuthor: null,
    author: [],
  });

  const val = useRef(null);

  useEffect(() => {
    if (!postLoading && !postError && postQueryData && postQueryData.post) {
      const { post: existingPost, publishedAt } = postQueryData; // Destructure directly from postQueryData

      const formattedPostDate = moment(publishedAt).format("YYYY-MM-DD");
      console.log("publishedAt:", publishedAt);
      console.log("formattedPostDate:", formattedPostDate);

      val.current = existingPost?.content?.document || initialValue;

      const selectedAuthor = existingPost?.author || {}; // Check if author exists

      setPostData({
        ...postData,
        selectedAuthor: {
          id: selectedAuthor?.id || null,
          value: selectedAuthor?.name || "",
          label: selectedAuthor?.name || "",
        },
        publishedAt: formattedPostDate,
        coverImage: null,
        title: existingPost?.title || "",
        content: existingPost?.content || [],
      });
    }
  }, [postQueryData, postLoading, postError, postData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPostData({
      ...postData,
      [name]: value,
    });
  };

  const handleNewsCoverImageUpload = (imageData) => {
    setPostData({
      ...postData,
      coverImage: imageData,
    });
  };

  const options =
    authorData?.authors.map((author) => ({
      id: author?.id,
      value: author?.id,
      label: author?.name,
    })) || [];

  const handleSelectAuthor = (selectedOption) => {
    if (selectedOption) {
      setPostData((prevPostData) => ({
        ...prevPostData,
        selectedAuthor: {
          id: selectedOption.id,
          value: selectedOption.value,
          label: selectedOption.label,
        },
      }));
    } else {
      setPostData((prevPostData) => ({
        ...prevPostData,
        selectedAuthor: null,
      }));
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    // Update the state only if it's not a past date
    setPostData((prevPostData) => ({
      ...prevPostData,
      publishedAt: selectedDate,
    }));
  };

  const handleUpdateNews = async () => {
    // const { author, coverImage } = newsData;
    try {
      const optional = {};
      if (postData.coverImage instanceof File) {
        optional.coverImage = {
          upload: postData.coverImage,
        };
      }

      // Check if postData.publishedAt is a valid date string
      if (postData.publishedAt) {
        optional.publishedAt = moment(postData.publishedAt).toISOString();
      }

      if (postData?.selectedAuthor?.id !== postQueryData?.post?.author?.id) {
        optional.author = {
          connect: {
            id: postData.selectedAuthor?.id,
          },
        };
      }

      const updatedPost = await updatePost({
        variables: {
          postId: postId,
          input: {
            title: postData.title,
            content: val.current,
            ...optional,
            publishedAt: moment(postData.publishedAt).toISOString(),
          },
        },
      });

      if (updatedPost?.data?.updatePost) {
        onClose();
        refetchNews();
        toast.success("bizViews updated successfully!");
      }
      // console.log("Created News:", createdNews.createPost);
    } catch (error) {
      // setIsLoading(false);
      console.error("Error Creating bizViews:", error);
      toast.error("Failed to update bizViews.");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 20,
      fontSize: 12,
      color: "#333",
      border: "1px solid #E1E6EF",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 11,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 2,
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 15,
      padding: 10,
      zIndex: 0,
      position: "relative",
    }),
  };

  if (postLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update bizViews</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit bizViews data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>
              <Loader showCaption={true} />
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show a loading indicator while fetching data
  }

  if (postError || !postQueryData || !postQueryData.post) {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-header">
            <div
              style={{
                display: "grid",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              <span
                style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
              >
                {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
                <br />
                <span>Update bizViews</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}
                >
                  This is a read-and-edit bizViews data.
                </span>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ width: "98%" }}>Error fetching bizViews data</div>
          </div>
          <div className="modal-footer">
            <div style={{ width: "100%" }}>
              <Button
                buttonText="Close"
                backgroundColor="#151515"
                borderColor="#151515"
                textColor="#ffffff"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    ); // Show error message if there's an error
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div
            style={{ display: "grid", textAlign: "center", marginTop: "25px" }}
          >
            <span
              style={{ fontWeight: "600", color: "black", fontSize: "14px" }}
            >
              {newsHeaderIcon({ width: 10, height: 10, color: "#28303F" })}
              <br />
              <span>Update bizViews</span>
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, color: "#707070" }}>
                This is a read-and-edit bizViews data.
              </span>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>bizViews Title</label>
            <TextInput
              type="text"
              name="title"
              placeholder="bizViews Title"
              value={postData.title}
              onChange={handleInputChange}
              // onChange={(e) => handleInputChange("title", e.target.value)}
              style={{ width: "93.5%" }}
            />
          </div>

          <div style={{ width: "99.5%", textAlign: "left", marginBottom: 15 }}>
            <label style={{ fontSize: 12 }}>bizViews Cover Image</label>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: "0px 0px 0px 10px",
                justifyContent: "space-between",
                margin: "10px 0px",
                border: "1px solid #E1E6EF",
                borderRadius: 30,
                width: "97.8%",
              }}
            >
              <span style={{ fontSize: 11, color: "#A9A9A9" }}>
                <img
                  src={postQueryData?.post?.coverImage?.url}
                  alt="bizViews Banners"
                  style={{
                    width: 40,
                    height: 40,
                    marginLeft: -5,
                    // maxWidth: "36px",
                    // maxHeight: "36px",
                    borderRadius: "50%",
                    objectFit: 'cover',
                    paddingTop: 4,
                    paddingBottom: 2,
                  }}
                />
                {/* )} */}
              </span>
              {/* <div> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  textAlign: "center",
                  padding: "5px 7px 5px 0px",
                }}
              >
                <ImageUploader
                  buttonText="Upload Image"
                  backgroundColor="#151515"
                  borderColor="#151515"
                  textColor="#ffffff"
                  file={postData?.coverImage}
                  onUpload={handleNewsCoverImageUpload}
                />
              </div>
            </div>
          </div>

          <div style={{ marginBottomxx: 10, textAlign: "left", width: "100%" }}>
            <label style={{ fontSize: 12, marginBottom: 10 }}>
              Published Date
            </label>
            <TextInput
              type="date"
              placeholder="bizViews Published Date"
              // value={formattedDate}
              value={postData.publishedAt}
              // value={format(new Date(eventData.date), 'yyyy-MM-dd')}
              onChange={handleDateChange}
              style={{ width: "90%", fontSize: 10, height: 12 }}
            />
          </div>

          <div style={{ width: "99%", textAlign: "left", marginBottom: 10 }}>
            <label style={{ fontSize: 12 }}>bizViews Content</label>
            <div
              style={{
                padding: 20,
                borderRadius: 15,
                border: "1px solid #E1E6EF",
                margin: "15px 0px",
              }}
            >
              {!postLoading && !!postQueryData && (
                <DocumentEditor
                  autoFocus={false}
                  onChange={(document) => {
                    val.current = document;
                  }}
                  initialValue={
                    val.current ||
                    postQueryData?.post?.content?.document ||
                    initialValue
                  }
                  componentBlocks={componentBlocks}
                  documentFeatures={defaultDocumentFeatures}
                />
              )}
            </div>
          </div>
          {/* {options && ( */}
          <div
            style={{
              width: "99.5%",
              display: "flex",
              gap: 10,
              marginTop: 5,
              marginBottom: 15,
            }}
          >
            <div style={{ width: "100%", textAlign: "left", marginTopxx: 5 }}>
              <label style={{ fontSize: 12 }}>Author</label>
              <div style={{ marginTop: 5 }}>
                <Select
                  value={postData.selectedAuthor}
                  onChange={handleSelectAuthor}
                  options={options}
                  placeholder="Select Author"
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <LoadingButton
              buttonText="Update bizViews"
              backgroundColor="#259BD8"
              borderColor="#259BD8"
              textColor="#ffffff"
              onClick={handleUpdateNews}
              showLoader={true}
              isLoading={loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="#151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateNews;
