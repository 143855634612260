// Pills.js
import React from 'react';
import Button from '../Button';

const Pills = ({ eventCategory, eventType }) => {
  const buttonText =
  eventCategory === 'webexions'
      ? 'webexions'
      : eventCategory === 'connexions'
      ? 'connexions'
      : eventCategory === 'awards'
      ? 'awards'
      : eventType === 'free'
      ? 'free'
      : eventType === 'paid'
      ? 'paid'
      : 'Default';

  const backgroundColor =
  eventCategory === 'webexions'
      ? '#FDDBFC'
      : eventCategory === 'connexions'
      ? '#DBEDFD'
      : eventCategory === 'awards'
      ? '#dcdcdc'
      : eventType === 'free'
      ? '#FDF5DB'
      : eventType === 'paid'
      ? '#DEFAF3'
      : '#DBEDFD';

  const borderColor =
  eventCategory === 'webexions'
      ? '#E466DF'
      : eventCategory === 'connexions'
      ? '#259BD8'
      : eventCategory === 'awards'
      ? '#8e8e8e'
      : eventType === 'free'
      ? '#F3B304'
      : eventType === 'paid'
      ? '#27AE60'
      : '#DBEDFD';

  const textColor =
  eventCategory === 'webexions'
      ? '#E466DF'
      : eventCategory === 'connexions'
      ? '#259BD8'
      : eventCategory === 'awards'
      ? '#8e8e8e'
      : eventType === 'free'
      ? '#F3B304'
      : eventType === 'paid'
      ? '#27AE60'
      : '#259BD8';

  return (
    <div>
      <Button
        buttonText={buttonText}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        textColor={textColor}
      />
    </div>
  );
};

export default Pills;