import React, { useState } from "react";
import Button from "../../Button";
import "../../../styles/Modal.css";
import { eventHeaderIcon } from "../../../data/svg";
import TextInput from "../../TextInput";
import { useQuery } from "@apollo/client";
import { GET_ISSUE_QUERY } from "../../../graphql/queries/magazine.query";
import Loader from "../../Loader";
import { format } from 'date-fns';
import Gallery from 'react-photo-gallery';

const ViewIssue = ({ onClose, issueId }) => {
  // console.log("check issue id:", issueId)
  const { loading, error, data } = useQuery(GET_ISSUE_QUERY, {
    variables: {
      where: { id: issueId },
      orderBy: { page_no: 'asc' }
    },
  });

  if (loading) {
    return <div className="modal-overlay">
    <div className="modal">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Issue</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only issue data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <Loader showCaption={true}/>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>;
  }

  if (error || !data || !data.issue) {
    return <div className="modal-overlay">
    <div className="modal">
      <div className="modal-header">
        <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
          <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
            {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
            <br/>
            <span>View Issue</span>
            <br/>
            <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only issue data.</span>
          </span>
        </div>
      </div>
      
      <div className="modal-body" >
        <div style={{ width: "98%" }}>
          <span>Error fetching data</span>
        </div>
      </div>
      <div className="modal-footer">
        <div style={{ width: "100%" }}>
          <Button
            buttonText="Close"
            backgroundColor="#151515"
            borderColor="#151515"
            textColor="#ffffff"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  </div>
  }

  const issueData = data.issue;

  // Assuming issueData.pages is an array of objects with image URLs
// const photos = issueData?.pages?.map((page) => ({
//   src: page?.image?.url,
//   width: 4, // Adjust the width and height as needed
//   height: 3,
//   alt: `Issue Page ${page.id}`,
// }));

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div style={{display: 'grid', textAlign: 'center', marginTop: '25px'}}>
            <span style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>
              {eventHeaderIcon({ width: 10, height: 10, color: '#28303F' })}
              <br/>
              <span>View Issue</span>
              <br/>
              <span style={{fontWeight: 400, fontSize: 12, color: '#707070'}}>This is a read-only issue data.</span>
            </span>
          </div>
        </div>
        
        <div className="modal-body">
          <div style={{marginBottom: 10}}>
            <img
              src={issueData?.coverImage?.url}
              alt="Issue Cover Image"
              style={{ width: 150, height: 'auto' }}
            />
          </div>
          <div style={{ width: "98%", textAlign: 'left' }}>
            <span style={{fontSize: 12}}>Issue Number</span>
            <TextInput
              type="text"
              placeholder="Issue Number"
              value={`Issue #${issueData.issueNumber}`}
              style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>
          <div style={{ width: "98%", textAlign: 'left' }}>
            <span style={{fontSize: 12}}>Magazine Type</span>
            <TextInput
              type="text"
              placeholder="Magazine Type"
              value={issueData.magazine.name}
              style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>
          
          <div style={{ width: "98%", textAlign: 'left' }}>
          <span style={{fontSize: 12}}>Description</span>
            <TextInput
              type="text"
              placeholder="Issue Description"
              value={issueData.description}
              style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
              readOnly={true}
            />
          </div>
          <div style={{ width: "100%", textAlign: 'left', display: "flex", gap: 10, height: 50, marginBottom: 20 }}>
            <div style={{marginBottom: 0, width: '98%'}}>
            <span style={{fontSize: 12}}>Published Date</span>
              <TextInput
                type="date"
                placeholder="Issue Date"
                // value={issueData.publishedAt}
                value={format(new Date(issueData.publishedAt), 'yyyy-MM-dd')}
                style={{ width: "95%", backgroundColor: '#F5F5F5', border: '1px solid #E1E6EF' }}
                readOnly={true}
              />
            </div>
          </div>
          <div style={{marginBottom: 10, textAlign: 'left'}}>
            <span style={{fontSize: 12}}>Issue Images</span>
            <div style={{border: '1px solid #E1E6EF', marginTop: 5, borderRadius: 15, width: '93.5%', padding: 10}}>
              {/* {issueData?.pages?.map((page) => (
                <img
                  key={page.id}
                  src={page?.image?.url}
                  alt={`Issue Page ${page.id}`}
                  style={{ width: 80, height: 'auto', margin: 5 }}
                />
              ))} */}
              {issueData?.pages?.slice()?.sort((a, b) => a.page_no - b.page_no)?.map((page) => (
                <img
                  key={page.id}
                  src={page?.image?.url}
                  alt={`Issue Page ${page.id}`}
                  style={{ width: 80, height: 'auto', margin: 5 }}
                />
              ))}

            </div>
          </div>

          <div style={{ width: "98%", display: "flex", gap: 10 }}></div>
          
        </div>
        <div className="modal-footer">
          <div style={{ width: "100%" }}>
            <Button
              buttonText="Close"
              backgroundColor="#151515"
              borderColor="151515"
              textColor="#ffffff"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewIssue;