import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from "react";
import { getUserInfo } from "../../common/utils/user.utils";

export const AppContext = createContext(null);

export const useStoreData = () => {
  const store = useRef({ user: getUserInfo() });
  const get = useCallback(() => store.current, []);
  const subscribers = useRef(new Set());
  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    return subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return { get, set, subscribe };
};

export const useStore = (selector) => {
  const store = useContext(AppContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider");
  }

  const state = useSyncExternalStore(store.subscribe, () =>
    selector(store.get())
  );
  return [state, store.set];
};
