import React from 'react';
import ActionsIcon from './ActionsIcon';
import '../../styles/Actions.css'

const Actions = ({ actions, rowData, openModal }) => {
// console.log(actions)
// console.log("row data:", rowData)
return (
  <div className='actions-container' style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
    {actions.map((action, actionsIndex) => (
      <div className='actions-body' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F9F9F9', borderRadius: '100%', padding: 15, height: 10, width: 10}}>
        <ActionsIcon className='action-icon' stylexx={{display: "flex"}}
            key={actionsIndex}
            icon={action.icon}
            onClick={() => openModal({type:action.title, data:rowData, allMagazinesData:rowData, issuesData:rowData, authorData:rowData, newsData:rowData, postData:rowData, rolesData:rowData, speakerData:rowData})}
        />
      </div>
    ))}
  </div>
);
};

export default Actions;