import { useQuery } from "@apollo/client";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "../common/utils/token.utils";
import { SetUserInfo } from "../common/utils/user.utils";
import { GET_AUTHENTICATED_USER_MUTATION } from "../graphql/queries/authenticatedUser.query";

function ProtectedLayout() {
  useQuery(GET_AUTHENTICATED_USER_MUTATION, {
    onCompleted: (data) => {
      SetUserInfo(data?.authenticatedItem);
    },
  });
  return !!getAccessToken() ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedLayout;
