import { gql } from "@apollo/client";

export const GET_NEWS_PAGE_QUERY = gql`
  query newsPageData($take: Int!, $skip: Int!, $orderBy: [PostOrderByInput!]) {
    postsCount: postsCount(
      where: { tags: { every: { name: { equals: "News" } } } }
    )
    authorsCount
    news:posts(where: { tags: { every: { name: { equals: "News" } } } }
      take: $take
      skip: $skip
      orderBy: $orderBy
      ) {
      id
      title
      coverImage {
        url
      }
      tags {
        name
      }
      publishedAt
      draft
      author {
        name
      }
    }
  }
`;

export const GET_TOTAL_COUNT_QUERY_WITHOUT_PAGINATION = gql`
  query newsPage {
    eventsCount: eventsCount
    freeEventsCount: eventsCount(where: { payment_type: { equals: free } })
    paidEventsCount: eventsCount(where: { payment_type: { equals: paid } })
    webexionsCount: eventsCount(where: { category: { equals: webexions } })
    connexionsCount: eventsCount(where: { category: { equals: connexions } })
    speakersCount
  }
`;

export const GET_NEWS_QUERY = gql`
  query GetPost($postId: ID!) {
    post(where: { id: $postId }) {
      id
      title
      coverImage {
        url
      }
      content {
        document
      }
      author {
        id
        name
        image {
          url
        }
      }
    }
  }
`;

export const GET_POSTS_QUERY = gql`
query Posts($where: PostWhereInput!, $orderBy: [PostOrderByInput!]!, $skip: Int!) {
  postsCount: postsCount(where: $where),
  posts(where: $where, orderBy: $orderBy, skip: $skip) {
    id
    title
    content {
      document
    }
    draft
    publishedAt
    author {
      id
      name
      image {
        url
      }
    }
    slug
    coverImage {
      url
    }
  }
}
`;

export const GET_POST_QUERY = gql`
  query Post($where: PostWhereUniqueInput!) {
    post(where: $where) {
      id
      title
      coverImage {
        url
      }
      content {
        document
      }
      publishedAt
      author {
        id
        name
        image {
          url
        }
      }
    }
  }
`;