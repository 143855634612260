// src/components/LoginSimulator.js
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { SetUserInfo } from "../common/utils/user.utils";
import { LOGIN_MUTAION } from "../graphql/mutations/auth.mutation";
import "../styles/Button.css";
import "../styles/Simulator.css";
import Button from "./Button/LoadingButton";
import { setAccessToken } from "../common/utils/token.utils";
import { showPasswordIcon, hidePasswordIcon } from "../data/svg";
import toast from "react-hot-toast";
import { useStore } from "./AppContext/AppContext";

const Simulator = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, setStore] = useStore((store) => store.user);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const [login, { loading }] = useMutation(LOGIN_MUTAION);

  const handleLogin = async () => {
    try {
      if (email && password) {
        const res = await login({
          variables: {
            email,
            password,
          },
        });

        if (res.data.authenticateUserWithPassword?.message) {
          return toast.error(res.data.authenticateUserWithPassword?.message);
        }

        if (res?.data?.authenticateUserWithPassword?.item) {
          SetUserInfo(
            JSON.stringify(res?.data?.authenticateUserWithPassword?.item)
          );
          setStore({ user: res?.data?.authenticateUserWithPassword?.item });
          setAccessToken(res?.data?.authenticateUserWithPassword?.sessionToken);
          navigate("/dashboard");
        }
      } else {
        toast.error("Please fill all fields");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="login-simulator">
      <div
        style={{
          display: "flex",
          position: "absolute",
          margin: "auto",
          top: 10,
          left: 0,
          right: 0,
          height: 30,
          width: 100,
          backgroundColor: "#151515",
          borderRadius: 30,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "absolute",
          margin: "auto",
          top: 170,
          left: 320,
          right: 0,
          height: 90,
          width: 10,
          backgroundColor: "#151515",
          borderRadius: 30,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "absolute",
          margin: "auto",
          top: 90,
          left: -340,
          right: 0,
          height: 30,
          width: 10,
          backgroundColor: "#151515",
          borderRadius: 30,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "absolute",
          margin: "auto",
          top: 150,
          left: -340,
          right: 0,
          height: 60,
          width: 10,
          backgroundColor: "#151515",
          borderRadius: 30,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "absolute",
          margin: "auto",
          top: 220,
          left: -340,
          right: 0,
          height: 60,
          width: 10,
          backgroundColor: "#151515",
          borderRadius: 30,
        }}
      />
      <img style={{ marginTop: 10 }} width="60" src={logo} alt="" />
      <div style={{ marginTop: 30 }}>
        <div style={{ textAlign: "left", padding: "30px 0px" }}>
          <span style={{ fontSize: 16, fontWeight: 600 }}>Welcome back 👋🏾</span>
          <br />
          <span style={{ fontSize: 12 }}>Login to your account.</span>
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ position: "relative", width: "80%" }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ paddingRight: "2.5rem", width: "100%" }} // To accommodate the icon inside the input field
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 245,
                cursor: "pointer",
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? hidePasswordIcon({}) : showPasswordIcon({})}
            </div>
          </div>
        </div>

        <Button
          onClick={handleLogin}
          buttonText="Login"
          backgroundColor="#259BD8"
          borderColor="259BD8"
          textColor="#ffffff"
          isFirstButton={true}
          isLastButton={true}
          isLoading={loading}
        />

        <div>
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <span style={{ fontSize: 10, color: "#151515" }}>
              Forgot your password?
            </span>
          </Link>
        </div>
      </div>
      <div
        className="version"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <span style={{ fontSize: 10, fontWeight: 500 }}>version 25(1.0.46)</span>
      </div>
    </div>
  );
};

export default Simulator;
